import {
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext } from "react";
import { TileContext } from "./Tile";

type OnClickParameters = Parameters<Exclude<MenuItemProps["onClick"], undefined>>

interface TileMenuButtonProps extends MenuItemProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  onClick?: (...p: OnClickParameters) => Promise<unknown> | void | unknown;
}

const TileMenuButton = ({
  icon,
  children,
  onClick,
  ...props
}: TileMenuButtonProps) => {
  const { closeMenu } = useContext(TileContext);

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLLIElement>) => {
      await onClick?.(e);
      closeMenu();
    },
    [onClick, closeMenu]
  );

  return (
    <MenuItem onClick={handleClick} {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography>{children}</Typography>
    </MenuItem>
  );
};

export default TileMenuButton;

import { DeleteForever } from "@mui/icons-material";
import {
  Box,
  Button,
  capitalize,
  FormControl,
  FormControlProps,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { FieldHookConfig, useField } from "formik";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

const ImageBox = styled(Box)`
  position: relative;
  max-width: min(100%, 300px);
  width: auto;

  img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
`;

const DeleteButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    width: 1.7em;
    height: 1.7em;
    background: rgba(255, 255, 255, 0.5);
    z-index: -1;
    border-radius: 50%;
    transition: background 0.3s ease;
  }

  &:hover::before {
    background: rgba(255, 255, 255, 0.8);
  }
`;

type FieldProps = {
  title?: string;
  helperText?: string;
  multiple?: boolean;
  formControlProps?: FormControlProps;
  name: string;
};

const File = ({
  title,
  helperText,
  formControlProps,
  name,
  type,
  multiple,
  ...props
}: FieldProps & FieldHookConfig<File | File[] | undefined>) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ ...props, name, multiple });
  const showError = meta.touched && !!meta.error;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoField = useMemo(() => field, Object.values(field));
  const inputRef = useRef<HTMLInputElement>(null);

  const fileArray = useMemo(
    () =>
      ((multiple ? field.value : [field.value]) as File[] | undefined)?.filter(
        (file) => file
      ),
    [field.value, multiple]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!multiple) {
        if (event.currentTarget.files)
          helpers.setValue(event.currentTarget.files[0]);
      } else {
        const newFiles = [];
        const files = event.currentTarget.files ?? ([] as File[]);
        const existingFiles = field.value as File[] | undefined;

        for (const file of files) {
          const alreadyExists = existingFiles?.find(
            (savedFile) => savedFile === file
          );
          if (alreadyExists) continue;
          newFiles.push(file);
        }

        helpers.setValue(newFiles);
      }
    },
    [field.value, helpers, multiple]
  );

  const handleDelete = useCallback(
    (file: File) => () => {
      if (multiple) {
        const files = field.value as File[];
        const newFiles = files.filter((existingFile) => existingFile !== file);
        helpers.setValue(newFiles);
      } else {
        helpers.setValue(undefined);
      }
    },
    [field.value, helpers, multiple]
  );

  useEffect(() => {
    if (!inputRef.current) return;
    const dataTransfer = new DataTransfer();

    for (const file of fileArray ?? []) {
      dataTransfer.items.add(file);
    }

    const value = dataTransfer.files;
    inputRef.current.files = value;
  }, [fileArray]);

  return (
    <FormControl margin="normal" fullWidth {...formControlProps}>
      {title && (
        <InputLabel shrink htmlFor={name} sx={{ position: "relative", mb: 1 }}>
          {title}
        </InputLabel>
      )}
      <input
        ref={inputRef}
        id={name}
        type="file"
        name={memoField.name}
        onBlur={memoField.onBlur}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple
      />
      <Stack direction="row" alignItems="center" columnGap={2}>
        <Button
          variant="contained"
          onClick={() => inputRef.current?.click()}
          sx={{ width: "min-content" }}
        >
          {capitalize(t("choose"))}
        </Button>
        {fileArray?.map((file, i) => (
          <ImageBox key={i}>
            <DeleteButton
              aria-label="delete"
              color="secondary"
              onClick={handleDelete(file)}
            >
              <DeleteForever />
            </DeleteButton>
            {file?.type.split("/")[0] === "image" ? (
              <img src={URL.createObjectURL(file)} alt={file?.name} />
            ) : (
              <Typography>{file?.name}</Typography>
            )}
          </ImageBox>
        ))}
      </Stack>
      {showError && (
        <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
          {capitalize((showError ? meta.error : helperText) || "")}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default File;

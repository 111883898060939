import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { styled, Grid, Typography, TypographyProps } from "@mui/material";
import authImage from "../assets/authImage.png";
import logo from "../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
}

const AuthImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled("div")`
  display: grid;
  width: 100%;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  background-color: ${({ theme }) => theme.palette.primary.light};

  & > img {
    display: none;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 10fr 11fr;
    & > img {
      display: flex;
    }
  }
`;

const FormContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .logo {
    width: 175px;
    margin-bottom: 33px;
  }

  padding-top: 60px;
  padding-bottom: 40px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: 60px;
  }

  .MuiFormControl-root {
    display: flex;
  }
`;

const TermsOfUse = (props: TypographyProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" {...props}>
      <Link to="/legal/terms" target="_blank">{t("auth.terms")}</Link>
      &nbsp;
      <Link to="/legal/privacy" target="_blank">{t("auth.privacy")}</Link>
    </Typography>
  );
};

const AuthLayout = ({ children }: LayoutProps) => {
  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <Content>
        <AuthImage src={authImage} alt="Coffee Machine" />
        <FormContainer justifyContent="center">
          <img className="logo" src={logo} alt="Jura" />
          <Grid container justifyContent="center" padding="32px">
            <Grid item xs={12} sm={5} md={10} lg={5}>
              {children}
            </Grid>
          </Grid>
          <TermsOfUse />
        </FormContainer>
      </Content>
    </Box>
  );
};

export default AuthLayout;

import { Cancel } from "@mui/icons-material";
import {
  Snackbar,
  Alert,
  AlertProps,
  AlertTitle,
  SnackbarCloseReason,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/string";

interface TemporaryAlertProps extends AlertProps {
  open: boolean;
  autoHideDuration?: number;
}

export const TemporaryAlert = ({
  open,
  children,
  severity,
  autoHideDuration = 3000,
  ...props
}: TemporaryAlertProps) => {
  const { t } = useTranslation();
  const [internalOpen, setInternalOpen] = useState(false);

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  const title = useMemo(
    () =>
      ({
        error: t("error"),
        warning: t("warning"),
        info: t("headsUp"),
        success: t("wellDone"),
      }[severity ?? "success"]),
    [severity, t]
  );

  const handleClose = useCallback(
    (
      event: Event | React.SyntheticEvent<any, Event>,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setInternalOpen(false);
    },
    []
  );

  return (
    <Snackbar open={internalOpen} onClose={handleClose} autoHideDuration={autoHideDuration}>
      <Alert
        sx={{ width: "100%" }}
        icon={false}
        variant="filled"
        severity={severity}
        action={
          <Cancel
            sx={{ color: severity === "error" ? "white!important" : null }}
            onClick={() => setInternalOpen(false)}
          />
        }
        {...props}
      >
        {
          <AlertTitle sx={{ display: "inline-block", mr: 1 }}>
            {capitalize(title)}!
          </AlertTitle>
        }
        {children}
      </Alert>
    </Snackbar>
  );
};

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  Metric,
  MetricData,
  ValueInfo,
  Widget,
} from "../state/widgets/widgetTypes";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import styled from "styled-components";
import { useSelector } from "../state/hooks";
import { selectCompany } from "../state/auth";
import { formatMoney } from "../utils/money";

interface WidgetBodyProps {
  widget: Widget;
  editMode?: boolean;
}

const ArrowNegative = styled(ArrowRightAltIcon)`
  font-size: 2rem;
  transform: rotate(45deg);
`;

const ArrowPositive = styled(ArrowRightAltIcon)`
  font-size: 2rem;
  transform: rotate(-45deg);
`;

const calculateRelativeValue = (metricData?: MetricData) => {
  let percent = "";

  if (!metricData) {
    return { difference: "", percent: "" };
  }

  const currentVal = Number(metricData?.currentValue.value);
  const referenceVal = Number(metricData.referenceValue.value);

  const difference = Math.round((currentVal - referenceVal) * 100) / 100;

  if (referenceVal === 0) {
    percent = '';
  } else {
    const percentGainOrLoss = (difference / referenceVal) * 100;

    let sign = Math.sign(percentGainOrLoss) === 1 ? "+" : "-";

    const percentGainOrLossRounded = Math.abs(
      Math.round(percentGainOrLoss * 100) / 100
    );

    percent = `${sign}${percentGainOrLossRounded.toString()} %`;
  }

  const differenceSign = Math.sign(difference) ? "+" : "-";
  const differenceString = `${differenceSign}${Math.abs(difference)}`;

  return { difference: differenceString, percent };
};

const WidgetBody = (props: WidgetBodyProps) => {
  const { widget } = props;
  const { metricData, valueInfo } = widget;

  const { t } = useTranslation();
  
  const company = useSelector(selectCompany);

  const currentLabel = t(
    `widget.metricLabel.${metricData?.currentValue.metricLabel}`
  );

  const referenceLabel = t(
    `widget.metricLabel.${metricData?.referenceValue.metricLabel}`
  );

  let suffix = "";

  const percentNotAvailable = t("widget.metricInfo.nopercent");

  if (widget.metric === Metric.Revenue) {
    suffix = company?.currency ?? "CHF";
  }
  
  const value = Math.round((metricData?.currentValue.value ?? 0) * 100) / 100;
  const referenceValue = Math.round((metricData?.referenceValue.value ?? 0) * 100) / 100;

  const currentMetricValue = formatMoney(value, suffix);
  const referenceMetricValue = formatMoney(referenceValue, suffix);

  const relativeValues = calculateRelativeValue(metricData);

  const differencePositive = Math.sign(Number(relativeValues.difference)) === 1;

  const relativeDifferenceValue = formatMoney(Number(relativeValues.difference), suffix);

  const absoluteDisplay = (
    <>
      <Typography variant="h3" sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
        {currentMetricValue}
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>{currentLabel}</Typography>
      <Typography paragraph>
        {metricData?.currentValue.period || <span>&nbsp;</span>}
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
        {referenceMetricValue}
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>{referenceLabel}</Typography>
      <Typography paragraph>{metricData?.referenceValue.period}</Typography>
    </>
  );

  const relativeDisplay = (
    <Box display={"flex"} flexDirection="column" alignItems={"center"}>
      <Typography sx={{ fontWeight: "bold", display: "flex" }}>
        {currentLabel} <CompareArrowsIcon sx={{ ml: 1, mr: 1 }} />{" "}
        {referenceLabel}
      </Typography>

      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          fontSize: "1.4rem",
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        {relativeDifferenceValue}
        &nbsp;
        {!!relativeValues.percent.length && relativeValues.percent}
        {differencePositive ? <ArrowPositive /> : <ArrowNegative />}
      </Typography>

      {!relativeValues.percent.length && (
        <Typography sx={{ fontWeight: "bold" }}>
          {percentNotAvailable}
        </Typography>
      )}
    </Box>
  );

  const bothDisplay = (
    <>
      <Typography variant="h3" sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
        {currentMetricValue}{relativeValues.percent ? ` (${relativeValues.percent})` : ''}
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>{currentLabel}</Typography>
      <Typography paragraph>
        {metricData?.currentValue.period || <span>&nbsp;</span>}
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
        {referenceMetricValue}
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>{referenceLabel}</Typography>
      <Typography paragraph>{metricData?.referenceValue.period}</Typography>
    </>
  );

  let body = absoluteDisplay;

  if (valueInfo === ValueInfo.Relative) {
    body = relativeDisplay;
  }

  if (valueInfo === ValueInfo.Both) {
    body = bothDisplay;
  }

  return (
    <Box display="grid" sx={{ pt: 2 }}>
      {body}
    </Box>
  );
};

export default WidgetBody;

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog, selectDialog } from "../state/dialogs";
import { RootState } from "../state/store";

export type EntitySelector<T> = (id: string) => (state: RootState) => T | undefined;

export interface EntityDialogOptions<T> {
  name: string;
  selector?: EntitySelector<T>;
}

export function useEntityDialog<T extends { id: string }>(
  options: EntityDialogOptions<T>
) {
  const dispatch = useDispatch();

  const open = useCallback(() => {
    dispatch(openDialog({ name: options.name }));
  }, [dispatch, options.name]);

  const edit = useCallback(
    (id: string) => () =>
      dispatch(
        openDialog({
          name: options.name,
          entityId: id,
          entityIds: undefined,
        })
      ),
    [dispatch, options.name]
  );

  const { entityId, open: isOpen } = useSelector(selectDialog(options.name));

  const selectEntity =
    options.selector?.(entityId ?? "") ?? ((_: RootState) => undefined);

  const entity = useSelector(selectEntity);

  const close = useCallback(() => {
    dispatch(closeDialog(options.name));
  }, [dispatch, options.name]);

  return {
    entityId,
    isOpen,
    open,
    edit,
    close,
    entity,
  };
}

export type EntitiesSelector<T> = (
  ids: string[]
) => (state: RootState) => T[] | undefined;

export interface EntitiesDialogOptions<T> {
  name: string;
  selector?: EntitiesSelector<T>;
}

export function useEntitiesDialog<T extends { id: string }>(
  options: EntitiesDialogOptions<T>
) {
  const dispatch = useDispatch();

  const open = useCallback(() => {
    dispatch(openDialog({ name: options.name }));
  }, [dispatch, options.name]);

  const edit = useCallback(
    (ids: string[]) => () =>
      dispatch(
        openDialog({
          name: options.name,
          entityIds: ids,
          entityId: undefined,
        })
      ),
    [dispatch, options.name]
  );

  const {
    entityId,
    entityIds: savedIds,
    open: isOpen,
  } = useSelector(selectDialog(options.name));

  const entityIds = savedIds ?? ((entityId && [entityId]) || []);

  const selectEntities =
    options.selector?.(entityIds) ?? ((_: RootState) => undefined);

  const entities = useSelector(selectEntities);

  const close = useCallback(() => {
    dispatch(closeDialog(options.name));
  }, [dispatch, options.name]);

  return {
    entityIds,
    isOpen,
    open,
    edit,
    close,
    entities,
  };
}

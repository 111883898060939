import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface DialogState {
  entityId?: string;
  entityIds?: string[];
  open: boolean;
}

interface DialogsState {
  [dialogName: string]: DialogState;
}

const slice = createSlice({
  name: "dialogs",
  initialState: {} as DialogsState,
  reducers: {
    reset: () => ({}),
    openDialog: (
      state: DialogsState,
      {
        payload: { name, entityId, entityIds },
      }: PayloadAction<{
        name: string;
        entityId?: string;
        entityIds?: string[];
      }>
    ) => ({
      ...state,
      [name]: {
        entityId,
        entityIds,
        open: true,
      },
    }),
    closeAllDialogs: (state: DialogsState) =>
      Object.fromEntries(
        Object.entries(state).map(([name, value]) => [
          name,
          { ...value, open: false },
        ])
      ),
    closeDialog: (
      state: DialogsState,
      { payload: name }: PayloadAction<string>
    ) => ({
      ...state,
      [name]: {
        entityId: undefined,
        entityIds: undefined,
        open: false,
      },
    }),
  },
});

export default slice.reducer;
export const selectDialog = (name: keyof DialogsState) => (state: RootState) =>
  state.dialogs[name] ?? { open: false };
export const selectDialogs = (state: RootState) => state.dialogs;
export const { reset, openDialog, closeDialog, closeAllDialogs } =
  slice.actions;

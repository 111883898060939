import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeAttrs from "rehype-attr";
import rehypeRaw from "rehype-raw";
import LinkRenderer from "../../../components/LinkRenderer";
import TextInput from "../../../components/form/TextInput";
import { StripePayment } from "../../../state/payments";

const StripeFields = ({ values }: { values: Omit<StripePayment, "id"> }) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert
        severity="info"
        sx={{
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.stripe.keysInfoTitle")}</AlertTitle>
        <ReactMarkdown linkTarget="_blank">
          {t("setup.payment.stripe.keysInfo", {
            joinArrays: "  \n",
          })}
        </ReactMarkdown>
      </Alert>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.stripe.apiKeysTitle")}
        </Typography>
        <TextInput
          name="options.clientKey"
          type="password"
          title={t("setup.payment.stripe.clientKey") + ":"}
          value={values.options.clientKey}
        />
        <TextInput
          name="options.apiKey"
          type="password"
          title={t("setup.payment.stripe.apiKey") + ":"}
          value={values.options.apiKey}
        />
      </Paper>
      <Alert
        severity="info"
        sx={{
          mt: 4,
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.stripe.webhookInfoTitle")}</AlertTitle>
        <ReactMarkdown
          components={{ a: LinkRenderer }}
          rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: "attr" }]]}
        >
          {t("setup.payment.stripe.webhookInfo", {
            joinArrays: "  \n",
            stripeSetupWebhookUrl:
              process.env.REACT_APP_STRIPE_SETUP_WEBHOOK_URL,
            stripeApiWebhookUrl: `${process.env.REACT_APP_API_BASE_URL}/api/stripe-webhook`,
          })}
        </ReactMarkdown>
      </Alert>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.stripe.webhooksTitle")}
        </Typography>
        <TextInput
          name="options.webhookSecret"
          type="password"
          title={t("setup.payment.stripe.webhookSecret") + ":"}
          value={values.options.webhookSecret}
        />
      </Paper>
    </>
  );
};

export default StripeFields;

import { Route, Switch } from "react-router-dom";
import CoffeeMenu from "./menu";
import CoffeeMenuListing from "./Listing";
import Clone from "./Clone";

const CoffeeMenus = () => {
  return (
    <Switch>
      <Route exact path="/coffee-menus">
        <CoffeeMenuListing />
      </Route>
      <Route exact path="/coffee-menus/shared/:shareKey">
        <CoffeeMenuListing />
        <Clone />
      </Route>
      <Route path="/coffee-menus/:menuId" >
        <CoffeeMenu />
      </Route>
    </Switch>
  );
}

export default CoffeeMenus;

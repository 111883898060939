import { createEntityAdapter } from "@reduxjs/toolkit";

export interface CreditTopUp {
  id: string;
  value: number;
}

export const creditTopUpsAdapter = createEntityAdapter<CreditTopUp>({
  selectId: (creditTopUp) => creditTopUp.id,
  sortComparer: (a, b) => a.value - b.value,
});

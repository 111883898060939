import { capitalize } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FormikErrors } from "formik";
import { TFunction } from "i18next";

export function formatErrors(
  rawErrors: FormikErrors<unknown> | string[] | string | undefined
) {
  switch (true) {
    case Array.isArray(rawErrors):
      return capitalize((rawErrors as string[]).join(", "));
    case typeof rawErrors === "string":
      return capitalize(rawErrors as string);
    default:
      return "";
  }
}

export const extractError = (
  err: FetchBaseQueryError | SerializedError | undefined
) => (err as { data?: { error?: string } })?.data?.error;

export const minPrice: Record<string, number> = {
  USD: 0.5,
  AED: 2.0,
  AUD: 0.5,
  BGN: 1.0,
  BRL: 0.5,
  CAD: 0.5,
  CHF: 0.5,
  CZK: 15.0,
  DKK: 2.5,
  EUR: 0.5,
  GBP: 0.3,
  HKD: 4.0,
  HRK: 0.5,
  HUF: 175.0,
  INR: 0.5,
  JPY: 50,
  MXN: 10,
  MYR: 2,
  NOK: 3.0,
  NZD: 0.5,
  PLN: 2.0,
  RON: 2.0,
  SEK: 3.0,
  SGD: 0.5,
};

export const createMinAmountValidator = (
  t: TFunction,
  currency: string | undefined,
  minOverride?: number,
) => {
  let min = minPrice[(currency ?? "CHF") as keyof typeof minPrice];
  
  if (!min) {
    min = 3;
  }
  
  if (typeof minOverride === "number") {
    min = minOverride;
  }

  return {
    name: "min-amount",
    params: { min },
    message: t("product.validation.price", { min }),
    test: (value: any, testContext: any) => value >= min || value === undefined,
  };
};

import { Save } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import { useEntitiesDialog, useEntityDialog } from "../../hooks/dialog";
import { useUserAddCreditsMutation } from "../../state/services/api";
import { User } from "../../state/users";
import { ADD_CREDIT_DIALOG_NAME } from "./modals";
import * as Yup from "yup";
import { useAlert } from "../../components/alerts/AlertContext";
import { getErrorMessage } from "../../utils/api";

const addCreditSchema = Yup.object().shape({
  amount: Yup.number().required(),
});

const AddCreditModal = () => {
  const { t } = useTranslation();
  const alert = useAlert();

  const { entityId, close } = useEntityDialog<User>({
    name: ADD_CREDIT_DIALOG_NAME,
  });

  const { entityIds } = useEntitiesDialog<User>({
    name: ADD_CREDIT_DIALOG_NAME,
  });

  const [addCredit, { isLoading: isSaving }] = useUserAddCreditsMutation();

  const initialValues = {
    amount: 0,
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      try {
        const userIds = entityId ? [entityId] : entityIds;
        const updates = userIds.map((userId) => ({
          userId,
          amount: values.amount,
        }));
        await addCredit(updates).unwrap();

        close();
        alert({
          severity: "success",
          content: t("usermanagement.addCreditModal.success"),
          autoHideDuration: 3000,
        });
      } catch (e) {
        alert({
          severity: "error",
          content:
            getErrorMessage(e) === "user.addCreditToUser.negativeCredit"
              ? t("usermanagement.addCreditModal.negativeCredit")
              : t("usermanagement.addCreditModal.failure"),
          autoHideDuration: 3000,
        });
      }
    },
    [addCredit, alert, close, entityId, entityIds, t]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addCreditSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <EntityDialog
            name={ADD_CREDIT_DIALOG_NAME}
            title={t("usermanagement.addCreditModal.title")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <TextInput
                  name="amount"
                  title={t("usermanagement.addCreditModal.amount") + ":"}
                  value={values.amount}
                />
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
    </>
  );
};

export default AddCreditModal;

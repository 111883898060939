// https://github.com/react-keycloak/react-keycloak

import * as React from "react";
import KeycloakInstance from "keycloak-js";

import { ReactKeycloakWebContext } from "./context";
import {
  AuthClient,
  AuthClientError,
  AuthClientEvent,
  AuthClientInitOptions,
  AuthClientTokens,
} from "./types";
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Props that can be passed to AuthProvider
 */
export type AuthProviderProps<T extends AuthClient> = {
  authClient: T;
  autoRefreshToken?: boolean;
  initOptions?: AuthClientInitOptions;
  isLoadingCheck?: (authClient: T) => boolean;
  LoadingComponent?: JSX.Element;
  onEvent?: (eventType: AuthClientEvent, error?: AuthClientError) => void;
  onTokens?: (tokens: AuthClientTokens) => void;
  children?: React.ReactNode;
};

type AuthProviderState = {
  initialized: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
};

const defaultInitOptions: AuthClientInitOptions = {
  onLoad: "check-sso",
};

const initialState: AuthProviderState = {
  initialized: false,
  isAuthenticated: false,
  isLoading: true,
};

function isUserAuthenticated(authClient: AuthClient) {
  return !!authClient.idToken && !!authClient.token;
}

const ReactKeycloakAuthProvider = ({
  authClient,
  onEvent,
  initOptions,
  isLoadingCheck,
  onTokens,
  autoRefreshToken,
  LoadingComponent,
  children,
}: AuthProviderProps<KeycloakInstance>) => {
  const [state, setState] = useState(initialState);

  const onError = useCallback(
    (event: AuthClientEvent) => (error?: AuthClientError) => {
      if (onEvent) onEvent(event, error);
    },
    [onEvent]
  );

  const updateState = useCallback(
    (event: AuthClientEvent) => () => {
      const {
        initialized: prevInitialized,
        isAuthenticated: prevAuthenticated,
        isLoading: prevLoading,
      } = state;

      // Notify Events listener
      if (onEvent) onEvent(event);

      // Check Loading state
      const isLoading = isLoadingCheck ? isLoadingCheck(authClient) : false;

      // Check if user is authenticated
      const isAuthenticated = isUserAuthenticated(authClient);

      // Avoid double-refresh if state hasn't changed
      if (
        !prevInitialized ||
        isAuthenticated !== prevAuthenticated ||
        isLoading !== prevLoading
      ) {
        setState({
          initialized: true,
          isAuthenticated,
          isLoading,
        });
      }

      // Notify token listener, if any
      const { idToken, refreshToken, token } = authClient;
      if (onTokens) {
        onTokens({
          idToken,
          refreshToken,
          token,
        });
      }
    },
    [authClient, isLoadingCheck, onEvent, onTokens, state]
  );

  const refreshToken = useCallback(
    (event: AuthClientEvent) => () => {
      // Notify Events listener
      if (onEvent) onEvent(event);

      if (autoRefreshToken !== false) {
        // Refresh Keycloak token
        authClient.updateToken(5);
      }
    },
    [authClient, autoRefreshToken, onEvent]
  );

  // https://github.com/react-keycloak/react-keycloak/blob/f2d15949e87c7fc6de0dbba9419999f6a6b9160e/packages/core/src/provider.tsx#L129
  const init = useCallback(() => {
    authClient.onReady = updateState("onReady");
    authClient.onAuthSuccess = updateState("onAuthSuccess");
    authClient.onAuthError = onError("onAuthError");
    authClient.onAuthRefreshSuccess = updateState("onAuthRefreshSuccess");
    authClient.onAuthRefreshError = onError("onAuthRefreshError");
    authClient.onAuthLogout = updateState("onAuthLogout");
    authClient.onTokenExpired = refreshToken("onTokenExpired");

    authClient
      .init({ ...defaultInitOptions, ...initOptions })
      .catch(onError("onInitError"));
  }, [authClient, initOptions, onError, refreshToken, updateState]);
  
  // https://github.com/reactwg/react-18/discussions/18
  const didInitRef = useRef(false);

  useEffect(() => {
    if (!didInitRef.current) {
      didInitRef.current = true;
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { initialized, isLoading } = state;

  if (!!LoadingComponent && (!initialized || isLoading)) {
    return LoadingComponent;
  }

  return (
    <ReactKeycloakWebContext.Provider value={{ initialized, authClient }}>
      {children}
    </ReactKeycloakWebContext.Provider>
  );
};

export default ReactKeycloakAuthProvider;

import { Stack } from "@mui/material";
import Statistic from "./Statistic";
import Widgets from "./Widgets";

const Dashboard = () => {
  return (
    <Stack rowGap={15}>
      <Widgets />
      <Statistic />
    </Stack>
  );
};

export default Dashboard;

import React, { useEffect } from "react";
import { useGetCoffeeMenuQuery } from "../../../state/services/api";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import muiTheme from "../../../theme";
import { ChevronLeft } from "@mui/icons-material";
import { NavButton } from "../../../components/NavButton";
import Overview from "./Overview";
import Products from "./Products";
import CoffeeMachines from "./CoffeeMachines";

interface MatchParams {
  menuId: string;
}

const BackButton = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const goToCoffeMenus = () => {
    history.push("/coffee-menus");
  };

  return (
    <Button
      sx={{
        px: 13,
        py: 1,
        my: { xs: 4, md: 0 },
        width: { sm: "auto" },
      }}
      startIcon={<ChevronLeft sx={{ fontSize: "30px!important" }} />}
      onClick={goToCoffeMenus}
    >
      {t("back")}
    </Button>
  );
};

const CoffeeMenu = () => {
  const { t } = useTranslation();

  const { menuId } = useParams<MatchParams>();

  const { data: menu, isError } = useGetCoffeeMenuQuery(menuId);

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const history = useHistory();

  useEffect(() => {
    if (isError) {
      history.push("/coffee-menus");
    }
  }, [history, isError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={4}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Stack
            direction="row"
            alignItems="center"
            columnGap={2}
            marginBottom={2}
          >
            <Box>
              <Typography variant="h2">
                {t("coffeeMenu.title")} {menu?.name}
              </Typography>
              <Typography marginBottom={0} paragraph>
                {t("coffeeMenu.subTitle")}
              </Typography>
            </Box>
          </Stack>

          <BackButton />
        </Box>

        <ButtonGroup
          orientation={smScreen ? "horizontal" : "vertical"}
          fullWidth={!smScreen}
          sx={{
            flexFlow: "row wrap",
            "> *": {
              width: "min-content",
              minWidth: "100px!important",
              whiteSpace: "nowrap",
            },
          }}
        >
          <NavButton
            to={`/coffee-menus/${menuId}/overview`}
            className="link-coffee-menu-overview"
          >
            {t("coffeeMenu.nav.overview")}
          </NavButton>
          <NavButton
            to={`/coffee-menus/${menuId}/products`}
            className="link-coffee-menu-products"
          >
            {t("coffeeMenu.nav.products")}
          </NavButton>
          <NavButton
            to={`/coffee-menus/${menuId}/machines`}
            className="link-coffee-menu-machines"
          >
            {t("coffeeMenu.nav.machines")}
          </NavButton>
        </ButtonGroup>
      </Grid>

      <Switch>
        <Route path="/coffee-menus/:menuId/overview">
          <Overview />
        </Route>
        <Route path="/coffee-menus/:menuId/products">
          <Products />
        </Route>
        <Route path="/coffee-menus/:menuId/machines">
          <CoffeeMachines />
        </Route>
        <Route path="/coffee-menus/:menuId">
          <Redirect
            from="/coffee-menus/:menuId"
            to={`/coffee-menus/${menuId}/products`}
          />
        </Route>
      </Switch>
    </Grid>
  );
};

export default CoffeeMenu;

import { TFunction } from "i18next";

export const regions = [
  "other",
  "at",
  "by",
  "be",
  "bg",
  "central_eastern_europe",
  "hr",
  "cz",
  "dk",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "hu",
  "is",
  "il",
  "it",
  "lv",
  "lt",
  "lu",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "cs",
  "sk",
  "si",
  "es",
  "se",
  "ch",
  "tr",
  "ua",
  "uk",
  "br",
  "ca",
  "cl",
  "cr",
  "mx",
  "pa",
  "pe",
  "us",
  "ve",
  "bn",
  "cn",
  "id",
  "jp",
  "kz",
  "my",
  "ph",
  "sg",
  "kr",
  "tw",
  "th",
  "vn",
  "au",
  "nz",
  "kw",
  "lb",
  "qa",
  "sa",
  "za",
  "om",
] as const;

export const regionOptions = (t: TFunction) =>
  regions
    .map((region) => ({
      value: region,
      label: t(`regions.${t(region)}`),
    }))
    .sort((a, b) => {
      if (a.value === 'other' || b.value === 'other') return 1
      return a.label.localeCompare(b.label)
    });

import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
// import { locationsReducer } from './users/locationsSlice';
import { api, resetApiCache } from "./services/api";
import authReducer from "./auth";
import statisticsReducer from "./statistics";
import widgetsReducer from "./widgets";
import dialogsReducer, { reset as resetDialogs } from "./dialogs";
import coffeeMachinesReducer, { reset as resetMachines } from "./coffe-machines";
import productsReducer from "./products";
import pageReducer from "./page";

export const store = configureStore({
  reducer: {
    // locations: locationsReducer,
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    dialogs: dialogsReducer,
    coffeeMachines: coffeeMachinesReducer,
    statistics: statisticsReducer,
    widgets: widgetsReducer,
    products: productsReducer,
    page: pageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export const reset = () => (dispatch: typeof store.dispatch) => {
  dispatch(resetMachines());
  dispatch(resetDialogs());
  dispatch(resetApiCache());
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
// export { locationsSelectors, locationsActions } from './users/locationsSlice';

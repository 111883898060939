import { Button, ButtonProps, InputAdornment, TextField } from "@mui/material";
import { Share as ShareIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import EntityDialog from "../../../components/EntityDialog";
import { useEntityDialog } from "../../../hooks/dialog";
import { useShareCoffeeMenuMutation } from "../../../state/services/api";
import { useAlert } from "../../../components/alerts/AlertContext";
import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { ContentCopy } from "@mui/icons-material";

export const SHARE_MENU_DIALOG_NAME = "share-menu";

interface MatchParams {
  menuId: string;
}

const ShareDialog = () => {
  const [share, { data }] = useShareCoffeeMenuMutation();

  const { t } = useTranslation();

  const { menuId } = useParams<MatchParams>();

  const { isOpen } = useEntityDialog({
    name: SHARE_MENU_DIALOG_NAME,
  });

  const alert = useAlert();

  const link = `${window.location.origin}/coffee-menus/shared/${data?.key}`;

  const loadShareKey = useCallback(async () => {
    try {
      await share(menuId).unwrap();
    } catch (e) {
      alert({
        severity: "error",
        autoHideDuration: 3000,
        content: t("coffeeMenus.shareMenuDialog.shareError"),
      });
    }
  }, [alert, menuId, share, t]);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(link);
    alert({
      severity: "success",
      autoHideDuration: 3000,
      content: t("coffeeMenus.shareMenuDialog.copySuccess"),
    });
  }, [alert, link, t]);

  useEffect(() => {
    if (isOpen) {
      loadShareKey();
    }
  }, [isOpen, loadShareKey]);

  return (
    <EntityDialog
      name={SHARE_MENU_DIALOG_NAME}
      title={t("coffeeMenus.shareMenuDialog.title")}
      subTitle={t("coffeeMenus.shareMenuDialog.subTitle")}
    >
      <TextField
        value={link ?? ""}
        sx={{ width: "100%" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ cursor: "pointer" }}>
              <ContentCopy onClick={copyToClipboard} />
            </InputAdornment>
          ),
        }}
        aria-readonly
      />
    </EntityDialog>
  );
};

const Share = (props: ButtonProps) => {
  const { t } = useTranslation();

  const { open: openDialog } = useEntityDialog({
    name: SHARE_MENU_DIALOG_NAME,
  });

  const shareMenu = async () => {
    openDialog();
  };

  return (
    <>
      <ShareDialog />
      <Button
        variant="contained"
        onClick={shareMenu}
        {...props}
        startIcon={<ShareIcon />}
      >
        {t("coffeeMenus.shareButtonText")}
      </Button>
    </>
  );
};

export default Share;

import { BaseSchema } from "yup";
import { AnyObject } from "yup/lib/types";
import { PaymentProvider } from "./interface";
import { TFunction } from "i18next";
import { OptionalObjectSchema, ObjectShape, TypeOfShape } from "yup/lib/object";

export default class AlipayProvider implements PaymentProvider {
  type = "none";

  options = [] as Array<never>;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction
  ): BaseSchema<any, AnyObject, any> {
    return schema;
  }

  fields(props: any) {
    return null;
  }
}

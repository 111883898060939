import { useExportStatisticsMutation } from "../state/services/api";
import { Filter } from "../state/utils/filterSlice";
import { ExportData, ExportType, useEntityExport } from "./export";

export function useStatisticsExport(
  type: ExportType,
  exportData: ExportData<{filter: Filter}, Record<string, unknown>>
) {
  const [exportStatistics] = useExportStatisticsMutation();
  
  return useEntityExport(type, exportData, exportStatistics, "Statistics");
}
import { useTheme } from "@mui/material";
import { TFunction } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { useSelector } from "react-redux";
import {
  generatePath,
  matchPath,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ADD_MACHINE_DIALOG_NAME } from "./page/coffee-machines/Listing";
import { closeAllDialogs, openDialog, selectDialogs } from "./state/dialogs";
import { useDispatch } from "./state/hooks";
import { api } from "./state/services/api";
import { AppDispatch } from "./state/store";
import { getAll } from "./utils/api";
import { routeRoles, selectCompany } from "./state/auth";
import { Role } from "./state/users";

interface PPStep extends Step {
  targetRoute?: string;
  targetDialog?: string;
  expectedRoute?: string;
  expectedDialog?: string;
  roles?: Role[];
  backIndex?: number;
  nextOnDialogClose?: boolean;
}

const createSteps = async (t: TFunction, dispatch: AppDispatch) => {
  const machinesState = await dispatch(
    api.endpoints.getCoffeeMachines.initiate({})
  ).unwrap();
  const machines = machinesState && getAll(machinesState);

  const menusState = await dispatch(
    api.endpoints.getCoffeeMenus.initiate()
  ).unwrap();
  const menus = menusState && getAll(menusState);

  const coffeeMachineSteps = machines.length
    ? [
        {
          target: ".coffee-machine-tile",
          content: t("tutorials.manageCoffeeMachineLink"),
          disableBeacon: true,
          spotlightClicks: true,
          hideCloseButton: true,
          disableOverlayClose: true,
          roles: routeRoles.coffeeMachines,
          expectedRoute: "/coffee-machines",
          targetRoute: `/coffee-machines/${machines[0].id}/overview`,
        },
        {
          target: ".link-to-configure",
          content: t("tutorials.chooseTabConfigure"),
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          roles: routeRoles.coffeeMachines,
          expectedRoute: `/coffee-machines/${machines[0].id}/overview`,
          targetRoute: `/coffee-machines/${machines[0].id}/configure`,
        },
        {
          target: ".configure-coffee-machine",
          content: t("tutorials.manageCoffeeMachine"),
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          roles: routeRoles.coffeeMachines,
          expectedRoute: `/coffee-machines/${machines[0].id}/configure`,
        },
      ]
    : [];

  const menusSteps = menus.length
    ? [
        {
          target: ".link-coffee-menus",
          content: t("tutorials.goToCoffeeMenus"),
          hideCloseButton: true,
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          roles: routeRoles.userManagement,
          expectedRoute: "/user-management",
          targetRoute: "/coffee-menus",
        },
        {
          target: ".link-coffee-menu",
          content: t("tutorials.goToCoffeeMenu"),
          hideCloseButton: true,
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          roles: routeRoles.coffeeMenus,
          expectedRoute: "/coffee-menus",
          targetRoute: `/coffee-menus/${menus[0].id}/products`,
        },
        {
          target: ".link-coffee-menu-overview",
          content: t("tutorials.goToCoffeeMenuOverview"),
          hideCloseButton: true,
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          roles: routeRoles.coffeeMenus,
          expectedRoute: `/coffee-menus/${menus[0].id}/products`,
          targetRoute: `/coffee-menus/${menus[0].id}/overview`,
        },
        {
          target: ".coffee-menu-mode",
          content: t("tutorials.chooseCoffeeMenuMode"),
          disableBeacon: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          roles: routeRoles.coffeeMenus,
          expectedRoute: `/coffee-menus/${menus[0].id}/overview`,
          targetRoute: `/coffee-menus/${menus[0].id}/overview`,
        },
      ]
    : [];

  return [
    {
      target: ".link-setup",
      content: t("tutorials.goToSetup"),
      disableBeacon: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      roles: routeRoles.setup,
      targetRoute: "/setup/locations",
    },
    {
      target: ".link-payments",
      content: t("tutorials.chooseTabPayments"),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      disableOverlayClose: true,
      roles: routeRoles.setup,
      targetRoute: "/setup/payments",
      expectedRoute: "/setup/locations",
    },
    {
      target: ".payments",
      content: t("tutorials.setupPayment"),
      disableBeacon: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      roles: routeRoles.setup,
      expectedRoute: "/setup/payments",
    },
    {
      target: ".link-operators",
      content: t("tutorials.chooseTabOperators"),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      disableOverlayClose: true,
      roles: routeRoles.setup,
      targetRoute: "/setup/operators",
      expectedRoute: "/setup/payments",
    },
    {
      target: ".new-operators",
      content: t("tutorials.addOperators"),
      roles: routeRoles.setup,
      expectedRoute: "/setup/operators",
      spotlightClicks: true,
    },
    {
      target: ".link-coffee-machines",
      content: t("tutorials.goToCoffeeMachines"),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      disableOverlayClose: true,
      roles: routeRoles.coffeeMachines,
      targetRoute: "/coffee-machines",
      expectedRoute: "/setup/operators",
    },
    {
      target: ".add-coffee-machine",
      content: t("tutorials.addCoffeeMachines"),
      disableBeacon: true,
      spotlightClicks: true,
      hideCloseButton: true,
      disableOverlayClose: true,
      roles: routeRoles.coffeeMachines,
      expectedRoute: "/coffee-machines",
      targetDialog: ADD_MACHINE_DIALOG_NAME,
    },
    {
      target: ".add-coffee-machine-dialog",
      content: t("tutorials.followInstructionsToAddCoffeeMachine"),
      disableBeacon: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      roles: routeRoles.coffeeMachines,
      expectedRoute: "/coffee-machines",
      expectedDialog: ADD_MACHINE_DIALOG_NAME,
      nextOnDialogClose: true,
    },
    ...coffeeMachineSteps,
    {
      target: ".link-user-management",
      content: t("tutorials.goToUserManagement"),
      disableBeacon: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      roles: routeRoles.coffeeMachines,
      expectedRoute: coffeeMachineSteps.length
        ? coffeeMachineSteps.at(-1)?.expectedRoute
        : "/coffee-machines",
      targetRoute: "/user-management",
    },
    {
      target: ".add-users",
      content: t("tutorials.addUsers"),
      disableBeacon: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      roles: routeRoles.userManagement,
      expectedRoute: "/user-management",
    },
    {
      target: ".users-grid",
      content: t("tutorials.userTableDescription"),
      disableBeacon: true,
      spotlightClicks: true,
      disableOverlayClose: true,
      roles: routeRoles.userManagement,
      expectedRoute: "/user-management",
    },
    ...menusSteps,
  ] as PPStep[];
};

export interface JoyrideProps {
  onCompleted: () => void;
}

const JoyrideImpl = ({ onCompleted }: JoyrideProps) => {
  const [steps, setSteps] = useState<PPStep[]>([]);
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [hadManualStepChange, setHadManualStepChange] = useState(false);
  const history = useHistory();

  const company = useSelector(selectCompany);
  const route = useLocation();
  const dialogs = useSelector(selectDialogs);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const matchRoute = useCallback(
    (path?: string) =>
      path &&
      matchPath(route.pathname, {
        path: path,
        exact: true,
        strict: false,
      }),
    [route.pathname]
  );

  const getNextStepIndex = useCallback(
    (index: number, goBack: boolean) =>
      (goBack ? steps[index]?.backIndex : undefined) ??
      index + (goBack ? -1 : 1),
    [steps]
  );

  useEffect(() => {
    const updateSteps = async () => {
      const newSteps = await createSteps(t, dispatch);
      const allowedSteps = newSteps.filter((step) =>
        step.roles?.includes(company?.role ?? Role.Consumer)
      );
      setSteps(allowedSteps);
    };

    updateSteps();
  }, [company, dispatch, t]);

  useEffect(() => {
    if (steps.length) {
      setTimeout(() => setRun(true), 400);
    }
  }, [steps]);

  useEffect(() => {
    const targetRoute = steps[stepIndex]?.targetRoute;
    if (run && matchRoute(targetRoute) && stepIndex + 1 < steps.length) {
      setRun(false);
      setHadManualStepChange(true);
      setStepIndex((i) => i + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchRoute, route]);

  useEffect(() => {
    return history.listen((_) => {
      if (run && history.action === "POP") {
        setRun(false);
        setHadManualStepChange(true);
        setStepIndex((i) => {
          if (i === 0) {
            return 0;
          }

          setTimeout(() => {
            if (stepIndex > 0) setRun(true);
          }, 400);

          return getNextStepIndex(i, true);
        });
      }
    });
  }, [getNextStepIndex, history, run, stepIndex]);

  useEffect(() => {
    const targetDialogName = steps[stepIndex]?.targetDialog;
    const expectedDialogName = steps[stepIndex]?.expectedDialog;
    const nextOnDialogClose = steps[stepIndex]?.nextOnDialogClose;

    if (run && targetDialogName && dialogs[targetDialogName]?.open) {
      setRun(false);
      setHadManualStepChange(true);
      setStepIndex((i) => i + 1);
    } else if (
      run &&
      expectedDialogName &&
      nextOnDialogClose &&
      !dialogs[expectedDialogName]?.open
    ) {
      setRun(false);
      setHadManualStepChange(true);
      setStepIndex((i) => i + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogs]);

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { action, status, type, index, step: originalStep } = data;

      const step = originalStep as PPStep;

      const isStart = type === EVENTS.TOUR_START;

      const isAfter = type === EVENTS.STEP_AFTER;
      const isNotFound = type === EVENTS.TARGET_NOT_FOUND;

      const isFinished = status === STATUS.FINISHED;
      const isSkipped = status === STATUS.SKIPPED;

      const goBack = action === ACTIONS.PREV;

      const nextStepIndex = getNextStepIndex(index, goBack);
      const nextStep = steps[nextStepIndex];

      const notFound =
        isNotFound ||
        (isStart && typeof step.target === "string"
          ? !document.querySelector(step.target)
          : !step.target);

      if (notFound) {
        setStepIndex(nextStepIndex);
        return;
      }

      if (isAfter) {
        // If we already manually navigated by clicking on navigation buttons or similar
        // just restart. If no such navigation happened yet we jump to the next step.
        if (!hadManualStepChange) {
          setStepIndex(nextStepIndex);

          // Go sure to close all dialogs before opening other dialogs
          // Or navigation
          dispatch(closeAllDialogs());
        } else {
          setRun(true);
        }

        setHadManualStepChange(false);

        if (!nextStep) {
          return;
        }

        const needsRouteChange =
          nextStep.expectedRoute && !matchRoute(nextStep.expectedRoute);
        const needsDialogOpen =
          nextStep.expectedDialog && !dialogs[nextStep.expectedDialog]?.open;

        if (needsRouteChange || needsDialogOpen) {
          setRun(false);
        }

        if (needsRouteChange) {
          let expectedRoute = nextStep.expectedRoute;
          const currentStepMatch = matchRoute(step.expectedRoute);

          if (currentStepMatch) {
            // Try to inject params from current route. If they are not available
            // Fall back to trying clicking on the element below.
            try {
              expectedRoute = generatePath(
                expectedRoute!,
                currentStepMatch.params
              );
            } catch (e) {
              let el =
                typeof step.target === "string"
                  ? (document.querySelector(step.target) as HTMLElement)
                  : step.target;
              if (el) {
                el?.click();
                setTimeout(() => setRun(true), 400);
                return;
              }
            }
          }

          // Navigate to the expected route and reactivate
          history.push(expectedRoute!);
        }

        // If a dialog is expected to be open - open it
        if (needsDialogOpen) {
          dispatch(openDialog({ name: nextStep.expectedDialog! }));
        }

        if (needsRouteChange || needsDialogOpen) {
          setTimeout(() => setRun(true), 400);
        }
      } else if (isFinished || isSkipped) {
        setRun(false);
        setStepIndex(0);
        onCompleted();
      }

      setHadManualStepChange(false);
    },
    [
      dialogs,
      dispatch,
      getNextStepIndex,
      hadManualStepChange,
      history,
      matchRoute,
      onCompleted,
      steps,
    ]
  );

  const theme = useTheme();

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      continuous={true}
      showSkipButton
      floaterProps={{
        disableAnimation: true,
      }}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          zIndex: 2000,
        },
      }}
    />
  );
};

export default JoyrideImpl;

import { Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Tabs,
  Tab,
  capitalize,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikHelpers } from "formik";
import Papa from "papaparse";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TemporaryAlert } from "../../components/Alert";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import UserImportDropzone from "../../components/UserImportDropzone";
import { useEntityDialog } from "../../hooks/dialog";
import { useInviteUsersMutation } from "../../state/services/api";
import { Role } from "../../state/users";
import { ADD_USER_DIALOG_NAME } from "./modals";
import * as Yup from "yup";
import MultiSelect from "../../components/form/MultiSelect";

const NewUserSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  role: Yup.string().required().oneOf(Object.values(Role)),
});
const NewBulkUserSchema = Yup.object().shape({});

type ParseResult = [string, Role];

export enum UserCategory {
  Consumer = "consumer",
  Operator = "operator",
}

interface NewUserModalProps {
  roles?: Role[];
  category?: UserCategory;
}

const TemplateDownloadButton = ({ category }: { category: UserCategory }) => {
  const file =
    category === UserCategory.Operator
      ? "operators_import_template.csv"
      : "consumers_import_template.csv";

  const { t } = useTranslation();

  return (
    <Button
      href={`${process.env.REACT_APP_API_BASE_URL}/files/${file}`}
      target="_blank"
      download
      sx={{ mb: 2 }}
    >
      {t("usermanagement.addNewUserModal.downloadTemplateButtonText")}
    </Button>
  );
};

const NewUserModal = ({
  roles = [],
  category = UserCategory.Consumer,
}: NewUserModalProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("single");
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(true);

  const { close } = useEntityDialog({
    name: ADD_USER_DIALOG_NAME,
  });

  const [inviteUserByEmail, { isLoading: isSaving, isSuccess, isError }] =
    useInviteUsersMutation();

  const initialValues: {
    email: string;
    role?: Role;
    bulk: File | null;
  } = {
    email: "",
    role: roles.length === 1 ? roles[0] : undefined,
    bulk: null,
  };

  const submit = useCallback(
    async (
      values: typeof initialValues,
      formikBag: FormikHelpers<typeof initialValues>
    ) => {
      if (tab === "bulk" && values.bulk) {
        await new Promise((resolve, reject) =>
          Papa.parse<ParseResult>(values.bulk!, {
            header: false,
            transformHeader: (header) => header.toLowerCase(),
            error: (e) => {
              console.error(e);
              setError(t("usermanagement.addNewUserModal.fileError"));
              reject();
            },
            complete: async (results) => {
              try {
                const hasHeader = !(await Yup.string()
                  .email()
                  .isValid(results.data[0]?.[0]));
                const data = hasHeader ? results.data.slice(1) : results.data;

                // Show an error if an imported role is not allowed to be set here.
                for (const line of data) {
                  const result = {
                    email: line[0],
                    role: line[1],
                  };
                  if (
                    result.email &&
                    !result.role &&
                    !roles.includes(Role.Consumer)
                  ) {
                    setError(
                      t("usermanagement.addNewUserModal.noRoleError", result)
                    );
                    reject();
                    return;
                  }
                  if (
                    result.email &&
                    result.role &&
                    !roles.includes(result.role)
                  ) {
                    setError(
                      t(
                        "usermanagement.addNewUserModal.forbiddenRoleError",
                        result
                      )
                    );
                    reject();
                    return;
                  }
                }

                // Filter out invalid rows and ensure a role is always set.
                // Default to "Consumer".
                const validResults = data
                  .filter(([email]) => !!email)
                  .map(([email, role]) => ({
                    email,
                    role: role ?? Role.Consumer,
                  }));

                const result = await inviteUserByEmail({
                  users: validResults,
                }).unwrap();

                if (result?.errors && Object.keys(result.errors).length) {
                  setShowSuccess(
                    Object.keys(result.errors).length !== validResults.length
                  );
                  setErrors(result.errors);
                  reject();
                  return;
                }

                setShowSuccess(true);
                setErrors({});
                resolve(result);
              } catch (e) {
                reject(e);
              }
            },
          })
        );
      } else {
        const user = {
          ...(values as typeof initialValues),
          role: values.role ?? Role.Consumer,
        };
        const result = await inviteUserByEmail({ users: [user] }).unwrap();

        if (result?.errors) {
          setShowSuccess(false);
          setErrors(result.errors);
        }
      }
      formikBag.resetForm();
      close();
    },
    [tab, close, t, inviteUserByEmail, roles]
  );

  const roleOptions = (roles ?? [])
    .filter((role) => role !== Role.CompanyOwner)
    .map((value) => ({
      value,
      label: t(`roles.${value}`),
    }));

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={tab === "bulk" ? NewBulkUserSchema : NewUserSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <EntityDialog
            name={ADD_USER_DIALOG_NAME}
            title={t("usermanagement.addNewUserModal.title")}
            subTitle={t("usermanagement.addNewUserModal.subTitle")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
                disabled={tab === "bulk" && !values.bulk}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box sx={{ mt: 4 }}>
              <Form>
                <Tabs
                  value={tab}
                  variant="fullWidth"
                  indicatorColor="primary"
                  onChange={(_, tab) => setTab(tab)}
                >
                  <Tab
                    value="single"
                    label={
                      t(
                        "usermanagement.addNewUserModal.singleInvite",
                        "Single"
                      ) as string
                    }
                  />
                  <Tab
                    value="bulk"
                    label={
                      t(
                        "usermanagement.addNewUserModal.bulkInvite",
                        "Bulk invite"
                      ) as string
                    }
                  />
                </Tabs>
                {tab === "single" && (
                  <Box sx={{ mt: 2 }}>
                    <TextInput
                      name="email"
                      type="email"
                      title={t("usermanagement.addNewUserModal.email") + ":"}
                    />
                    {(roles?.length ?? 0) > 1 && (
                      <MultiSelect name="role" title={capitalize(t("role"))}>
                        {roleOptions.map((option) => (
                          <MenuItem value={option.value} key={option.value}>
                            <Tooltip
                              arrow
                              PopperProps={{
                                sx: {
                                  lineHeight: "1.1rem",
                                  pointerEvents: "none",
                                },
                              }}
                              title={
                                <span>
                                  {t(`roles.tooltip.${option.value}`)}
                                </span>
                              }
                            >
                              <Box>{option.label}</Box>
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </MultiSelect>
                    )}
                  </Box>
                )}
                {tab === "bulk" && (
                  <Box sx={{ mt: 2 }}>
                    <TemplateDownloadButton category={category} />
                    <UserImportDropzone name="bulk" />
                  </Box>
                )}
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>

      <TemporaryAlert open={!isSaving && isError} severity="error">
        {t("usermanagement.addNewUserModal.saveFailure")}
      </TemporaryAlert>
      <TemporaryAlert open={!isSaving && isSuccess && showSuccess}>
        {t("usermanagement.addNewUserModal.saveSuccess")}
      </TemporaryAlert>
      <TemporaryAlert open={!isSaving && !!error} severity="error">
        {error}
      </TemporaryAlert>
      {Object.entries(errors).map(([email, error]) => (
        <TemporaryAlert key={email} open={!isSaving} severity="error">
          {t(
            error === "alreadyInCompany"
              ? "usermanagement.addNewUserModal.emailAlreadyInCompanyError"
              : "usermanagement.addNewUserModal.emailError",
            {
              user: email,
            }
          )}
        </TemporaryAlert>
      ))}
    </>
  );
};

export default NewUserModal;

import { Save } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import EntityDialog from "../../components/EntityDialog";
import { useEntitiesDialog, useEntityDialog } from "../../hooks/dialog";
import {
  useAssignUserRoleMutation,
  selectUser,
  selectUsers,
  useAssignUserRolesMutation,
} from "../../state/services/api";
import { User, Role } from "../../state/users";
import { EDIT_USER_ROLE_DIALOG_NAME } from "./modals";
import * as Yup from 'yup';
import { useAlert } from "../../components/alerts/AlertContext";

const UserRoleSchema = Yup.object().shape({
  role: Yup.string().required(),
});

const EditRoleModal = () => {
  const { t } = useTranslation();

  const [assignUserRoleById, { isLoading: isSingleSaving }] =
    useAssignUserRoleMutation();

  const [assignUserRoles, { isLoading: isMultSaving }] =
    useAssignUserRolesMutation();
  
  const alert = useAlert();
    
  const isSaving = isSingleSaving || isMultSaving;
  
  const {
    entity: user,
    entityId: userId,
    close,
  } = useEntityDialog<User>({
    name: EDIT_USER_ROLE_DIALOG_NAME,
    selector: selectUser,
  });

  const {
    entities: users,
  } = useEntitiesDialog<User>({
    name: EDIT_USER_ROLE_DIALOG_NAME,
    selector: selectUsers,
  });

  const initialValues = {
    role: user?.role ?? users?.[0]?.role ?? "" as Role,
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      try {
        if (userId) {
          const args = {
            id: userId,
            body: { role: values.role as Role },
          };
          await assignUserRoleById(args).unwrap();
        } else if (users?.length) {
          const args = users.map(({ id }) => ({ id, role: values.role }))
          await assignUserRoles(args).unwrap();
        }

        close();
        
        alert({
          severity: "success",
          autoHideDuration: 3000,
          content: t("usermanagement.editRoleModal.editRoleSuccess"),
        });
      } catch(e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("usermanagement.editRoleModal.editRoleFailure"),
        });
      }
    },
    [alert, assignUserRoleById, assignUserRoles, close, t, userId, users]
  );

  const roles = Object.values(Role).filter(
    (role) => role !== Role.CompanyOwner
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={UserRoleSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values, handleChange }) => (
          <EntityDialog
            name={EDIT_USER_ROLE_DIALOG_NAME}
            title={t("usermanagement.editRoleModal.title")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box sx={{ mt: 4 }}>
              <Form>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {t("usermanagement.editRoleModal.label")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="role"
                    id="role"
                    name="role"
                    onChange={handleChange}
                    value={values.role}
                  >
                    {roles.map((role, index) => {
                      return (
                        <Tooltip
                          key={index}
                          arrow
                          PopperProps={{
                            sx: { lineHeight: "1.1rem", pointerEvents: "none" },
                          }}
                          title={<span>{t(`roles.tooltip.${role}`)}</span>}
                        >
                          <FormControlLabel
                            value={role}
                            control={<Radio />}
                            label={t(`roles.${role}`).toString()}
                          />
                        </Tooltip>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
    </>
  );
};

export default EditRoleModal;

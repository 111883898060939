import { useMemo } from "react";
import { useGetLanguagesQuery } from "../state/services/api";

export const useLanguageOptions = (preferredLanguage: string) => {
  const { data: supportedLanguages, isLoading } = useGetLanguagesQuery();

  return useMemo(() => {
    const defaultLanguage = preferredLanguage ?? "en";
    const defaultOption = {
      [defaultLanguage]: defaultLanguage,
    };

    return {
      options: Object.entries(supportedLanguages ?? defaultOption).map(
        ([key, lang]) => ({
          value: key,
          label: new Intl.DisplayNames([lang], { type: "language" }).of(lang) ?? lang,
        })
      ),
      isLoading,
    };
  }, [isLoading, preferredLanguage, supportedLanguages]);
};

import { useKeycloak } from "../../components/auth";
import { parse } from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLogin } from "../../hooks/auth";
import { Role } from "../../state/users";
import Loading from "../Loading";
import { logout } from "../../state/auth";
import { useDispatch } from "../../state/hooks";

const Register = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const location = useLocation();
  const router = useHistory();
  const dispatch = useDispatch();
  const [login] = useLogin();
  const [loading, setLoading] = useState(false);

  const queryParams = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const signup = useMemo(
    () => queryParams?.signup?.toString(),
    [queryParams?.signup]
  );

  useEffect(() => {
    const handleSignup = async () => {
      if (!signup) {
        const email = parse(window.location.search, {
          ignoreQueryPrefix: true,
        })?.email?.toString();

        keycloak?.login({
          redirectUri: `${window.location.origin}/${location.pathname}?signup=true`,
          loginHint: email,
        });
      } else {
        try {
          setLoading(true);
          const loginResponse = await login();
          if (!loginResponse) return;
          if (
            loginResponse.companies.some(
              (company) => company.role !== Role.Consumer
            )
          ) {
            router.push("/");
          } else {
            // No better way was found to keep ourselves logged out at this place because
            // The only information we have to distinguish consumers that just signed up
            // is that this place here is called.
            dispatch(logout());
          }
        } finally {
          setLoading(false);
        }
      }
    };

    handleSignup();
  }, [dispatch, keycloak, location.pathname, login, router, signup]);

  if (loading) {
    return <Loading />;
  }

  if (signup) {
    return (
      <Typography variant="h3">
        {t("auth.signup.consumerSignupSuccess")}
      </Typography>
    );
  }

  return <Typography variant="h3">Redirecting...</Typography>;
};

export default Register;

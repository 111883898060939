import { Box, BoxProps, Typography } from "@mui/material";

export interface AmountProps extends BoxProps {
  amount: number;
  text: string;
}

const Amount = ({ amount, text, ...props }: AmountProps) => (
  <Box {...props}>
    <Typography variant="h1">
      {amount}
    </Typography>
    <Typography variant="subtitle1" fontWeight="bold">
      {text}
    </Typography>
  </Box>
);

export default Amount;
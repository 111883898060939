import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "../../components/Dialog";
import {
  logout,
  selectLogoutDialogOpen,
  setLogoutDialogOpen,
} from "../../state/auth";
import { useDispatch, useSelector } from "../../state/hooks";
import { reset } from "../../state/store";

interface LogoutDialogProps {
  open: boolean;
  exiting?: boolean;
  onClose: () => void;
  onExit: () => void;
}

const LogoutDialog = ({
  open,
  onClose,
  onExit,
  exiting,
}: LogoutDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("auth.logout.title")}
      okButton={
        <Button variant="contained" onClick={onExit}>
          {exiting ? <CircularProgress size={20} color="white" /> : t("exit")}
        </Button>
      }
    >
      <Box></Box>
    </Dialog>
  );
};

const Logout = () => {
  const dispatch = useDispatch();
  const open = useSelector(selectLogoutDialogOpen);

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(reset());
  };

  return (
    <LogoutDialog
      open={open}
      onClose={() => dispatch(setLogoutDialogOpen(false))}
      onExit={handleLogout}
    />
  );
};

export default Logout;

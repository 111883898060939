import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const existingTranslations = ["de"];
const defaultLanguage = "de";

const TermsOfUse = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const lang = existingTranslations.includes(i18n.language)
      ? i18n.language
      : defaultLanguage;

    window.open(`/legal/${lang}/terms.pdf`, "_self");
  }, [history, i18n.language]);

  return <></>;
};

export default TermsOfUse;

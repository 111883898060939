import { EntityState } from "@reduxjs/toolkit";

/* 
  This extra file fixes circular dependency in api.ts 
  Declare all widget types here
*/

export interface Widget {
  id: string;
  title: string;
  subTitle?: string;
  metricData?: MetricData;
  metric: Metric;
  position?: number;
  metricPeriod: MetricPeriod;
  metricOption?: string;
  filter: {
    locations?: string[];
    machines?: string[];
  };
  valueInfo?: ValueInfo;
  widgetLocations?: WidgetLocation[],
  widgetMachines?: WidgetMachine[]
}

export interface WidgetLocation {
  id: string;
  widgetId?: string;
  locationId?: string;
}

export interface SortedWidgetIds {
  widgetIds: string[];
}

export interface WidgetMachine {
  id: string;
  widgetId?: string;
  machineId?: string;
}

export interface SortableWidgets {
  widgets: Widget[];
  sortedIds: string[];
}

export interface SortableWidgetsState {
  entityState: EntityState<Widget>;
  sortedIds: string[];
}

export type NewWidget = Omit<Widget, "id" | "metricData">;

export interface MetricData {
  currentValue: {
    value: number;
    metricLabel: MetricLabel;
    period: string;
  };
  referenceValue: {
    value: number;
    metricLabel: MetricLabel;
    period: string;
  };
}

export enum MetricLabel {
  ThisWeek = "thisweek",
  LastWeek = "lastweek",
  Today = "today",
  Yesterday = "yesterday",
  ThisMonth = "thismonth",
  LastMonth = "lastmonth",
}

export enum ValueInfo {
  Absolute = "absolute",
  Relative = "relative",
  Both = "both",
}

export enum MetricPeriod {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly"
}

export enum Section {
  Dashboard = "dashboard",
  Statistics = "statistics",
}

export enum Metric {
  ProductConsumption = "product_consumption",
  ProductConsumptionProductCategory = "product_consumption_product_category",
  ProductConsumptionProduct = "product_consumption_product",
  Revenue = "revenue",
}
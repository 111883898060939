import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const existingTranslations = ["de"];
const defaultLanguage = "de";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const lang = existingTranslations.includes(i18n.language)
    ? i18n.language
    : defaultLanguage;

  useEffect(() => {
    window.open(`/legal/${lang}/privacy.pdf`, "_self");
  }, [history, i18n.language, lang]);

  return <></>;
};

export default PrivacyPolicy;

import { Save } from "@mui/icons-material";
import {
  Button,
  capitalize,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, Form, FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TemporaryAlert } from "../../components/Alert";
import EntityDialog from "../../components/EntityDialog";
import { useEntitiesDialog } from "../../hooks/dialog";
import {
  selectUsers,
  useAssignUserGroupsMutation,
  useGetUserGroupsQuery,
} from "../../state/services/api";
import { User } from "../../state/users";
import { getAll } from "../../utils/api";
import { EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME } from "./modals";
import * as Yup from "yup";

const UserMachineAssignmentSchema = Yup.object().shape({
  userGroup: Yup.mixed(),
});

const EditUserGroupAssignment = () => {
  const { t } = useTranslation();

  const [assignUserGroups, { isLoading: isSaving, isSuccess, isError }] =
    useAssignUserGroupsMutation();

  const {
    entities: users,
    entityIds: userIds,
    close,
  } = useEntitiesDialog<User>({
    name: EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME,
    selector: selectUsers,
  });

  const { data: allUserGroupsState } = useGetUserGroupsQuery();
  const allUserGroups = getAll(allUserGroupsState);

  const initialValues: { userGroup?: string } = {
    userGroup: users?.[0]?.userGroups?.[0]?.id,
  };

  const submit = useCallback(
    async (
      values: typeof initialValues,
      { resetForm }: FormikHelpers<typeof initialValues>
    ) => {
      if (userIds?.length) {
        const assignments = Object.fromEntries(
          userIds.map((userId) => [
            userId,
            values.userGroup && values.userGroup !== "none"
              ? [values.userGroup]
              : [],
          ])
        );
        await assignUserGroups(assignments);
        resetForm();
        close();
      }
    },
    [assignUserGroups, close, userIds]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={UserMachineAssignmentSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values, handleChange }) => (
          <EntityDialog
            name={EDIT_USER_GROUP_ASSIGNMENT_DIALOG_NAME}
            title={t("usermanagement.editUserGroupAssignment.title")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box sx={{ mt: 4 }}>
              <Form onSubmit={handleSubmit}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="userGroup"
                    id="userGroup"
                    name="userGroup"
                    onChange={handleChange}
                    value={values.userGroup ?? "none"}
                  >
                    <FormControlLabel
                      value={"none"}
                      control={<Radio />}
                      label={capitalize(t("none"))}
                    />
                    {allUserGroups.map((userGroup, index) => {
                      return (
                        <FormControlLabel
                          value={userGroup.id}
                          control={<Radio />}
                          label={userGroup.name}
                          key={index}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
      <TemporaryAlert open={isSuccess}>
        {t("usermanagement.editUserGroupAssignment.success")}
      </TemporaryAlert>
      <TemporaryAlert open={isError} severity="error">
        {t("usermanagement.editUserGroupAssignment.error")}
      </TemporaryAlert>
    </>
  );
};

export default EditUserGroupAssignment;

const languages = {
  cs: "cs",
  da: "da",
  de: "de",
  en: "en",
  "en-us": "en-us",
  es: "es",
  "es-mx": "es-mx",
  et: "et",
  fi: "fi",
  fr: "fr",
  hu: "hu",
  it: "it",
  ja: "ja",
  ko: "ko",
  lt: "lt",
  lv: "lv",
  nl: "nl",
  no: "no",
  pl: "pl",
  pt: "pt",
  ru: "ru",
  sk: "sk",
  sv: "sv",
  tr: "tr",
  zh: "zh",
  "zh-tw": "zh-tw",
};

export type LanguageCode = keyof typeof languages;

export default languages;

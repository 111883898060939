import { useField } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import { Typography, Box, styled } from "@mui/material";
import { Article } from "@mui/icons-material";

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled("div", {
  shouldForwardProp: (prop) =>
    !["isDragAccept", "isDragReject", "isFocused"].includes(prop.toString()),
})<DropzoneRootProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  .file-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const UserImportDropzone = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const [field, , utils] = useField({ type: "hidden", name });

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      if (acceptedFiles.length) {
        utils.setValue(acceptedFiles[0]);
      }
    },
    [utils]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: "text/csv",
    });

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      {field.value ? (
        <Box className="file-icon">
          <Article fontSize="large" />
          <Typography variant="h3">{field.value.name}</Typography>
        </Box>
      ) : (
        <p>{t("usermanagement.addNewUserModal.bulkDropzoneText")}</p>
      )}
    </Container>
  );
};

export default UserImportDropzone;

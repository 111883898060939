import { useAuthorized } from "../hooks/auth";
import { Role } from "../state/users";
import { Button, ButtonProps } from "@mui/material";

type AuthorizedButtonProps = ButtonProps & {
  onlyRoles?: Role[] | Role;
}

const AuthorizedButton = ({ onlyRoles, ...props }: AuthorizedButtonProps) => {
  const authorized = useAuthorized(onlyRoles);
  if (!authorized) return null;
  // @ts-ignore
  return <Button {...props} />;
};

export default AuthorizedButton;
import {
  GridActionsCellItem as MuiGridActionsCellItem,
  GridActionsCellItemProps as MuiGridActionsCellItemProps,
} from "@mui/x-data-grid";
import { useAuthorized } from "../hooks/auth";
import { Role } from "../state/users";

type GridActionsCellItemProps = MuiGridActionsCellItemProps & {
  onlyRoles?: Role[] | Role;
}

const GridActionsCellItem = ({ onlyRoles, ...props }: GridActionsCellItemProps) => {
  const authorized = useAuthorized(onlyRoles);
  if (!authorized) return null;
  // @ts-ignore
  return <MuiGridActionsCellItem {...props} />;
};

export default GridActionsCellItem;
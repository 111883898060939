import { Route, Switch } from "react-router-dom";
import Listing from "./Listing";
import CoffeeMaschine from "./machine"

const CoffeeMachines = () => {
  return (
    <Switch>
      <Route exact path="/coffee-machines">
        <Listing />
      </Route>
      <Route path="/coffee-machines/:machineId" >
        <CoffeeMaschine />
      </Route>
    </Switch>
  );
};

export default CoffeeMachines;

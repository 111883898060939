import { ButtonProps, createTheme } from "@mui/material";
import { createBreakpoints } from "@mui/system";

const beige = "#9A7348";
const darkBlue = "#525F6B";
const lightBlue = "#E0E2E7";
const red = "#E30613";
const breakpointValues = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};
const spacing = 8;

const breakpoints = createBreakpoints({ ...breakpointValues });

export default createTheme({
  palette: {
    primary: {
      main: beige,
      light: "#F9F7F3",
      contrastText: "#525F6B",
    },
    secondary: {
      main: darkBlue,
      light: lightBlue,
      contrastText: "#fff",
    },
    white: {
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Arial", "sans-serif"].join(","),
    body1: {
      fontSize: 14,
      lineHeight: "1.5714em",
    },
    body2: {
      fontSize: 12,
      lineHeight: "1.5625rem",
    },
    subtitle1: {
      fontSize: 10,
      lineHeight: "1rem",
    },
    h1: {
      fontSize: 24,
      fontWeight: "bold",
      lineHeight: "1.29em",
      marginBottom: 8,
    },
    h2: {
      position: "relative",
      fontSize: 18,
      fontWeight: "bold",
      color: beige,
      lineHeight: "1.277em",
      marginBottom: "16px",
      "&::after": {
        content: '""',
        height: "4px",
        width: "20px",
        position: "absolute",
        top: "calc(100% + 4px)",
        left: 0,
        backgroundColor: beige,
      },
    },
    h3: {
      fontSize: 16,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 15,
      fontWeight: "bold",
    },
  },
  breakpoints: breakpointValues,
  spacing,
  components: {
    MuiButton: {
      defaultProps: { variant: "white", color: "secondary" },
      styleOverrides: {
        root: {
          paddingTop: spacing,
          paddingBottom: spacing,
          width: "100%",
          textTransform: "none",
          [breakpoints.up("sm")]: {
            width: ({ ownerState }: { ownerState: ButtonProps }) =>
              ownerState.fullWidth ? "100%" : "auto",
          },
        },
        contained: {
          borderRadius: 0,
          backgroundColor: darkBlue,
          boxShadow: "none",
          padding: "12px 1rem",
          "&:hover, &.active": {
            zIndex: 1,
            backgroundColor: beige,
            color: "white",
            boxShadow: "none",
          },
          "&.MuiButton-containedError": {
            backgroundColor: red,
          }
        },
      },
      variants: [
        {
          props: { variant: "white" },
          style: {
            borderRadius: 0,
            border: `1px solid #E0E2E7`,
            backgroundColor: "white",
            textTransform: "none",
            "&:hover, &.active": {
              zIndex: 1,
              backgroundColor: beige,
              color: "white",
              border: `1px solid ${beige}`,
            },
          },
        },
      ],
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        autoHideDuration: 3000,
      },
      styleOverrides: {
        root: {
          position: "fixed",
          minWidth: "30%",
          left: spacing * 4,
          right: spacing * 4,
          top: `${spacing * 10}px !important`,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        columnSpacing: 2,
        rowSpacing: 2,
      },
    },
    MuiButtonGroup: {
      defaultProps: { variant: "white", color: "secondary" },
      styleOverrides: {
        root: {
          "& .MuiButton-root": {
            fontWeight: "bold",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(198, 178, 138, 0.69)", // Beige but with alpha
        },
        invisible: {
          backgroundColor: "rgba(198, 178, 138, 0)", // Beige but with alpha
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: "bold",
          transform: "none",
          color: darkBlue,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: "white",
          fontWeight: 700,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: "13px 16px",
          borderRadius: "0 !important",
          marginTop: "20px",
          backgroundColor: "white",
          width: "100%",
          [breakpoints.up("sm")]: {
            width: "auto",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 0,
          fontSize: 14,
          fontWeight: "bold",
        },
        notchedOutline: {
          border: `1px solid ${lightBlue}`,
          top: 0,
          "> legend": {
            display: 'none',
          }
        },
      },
    },
    MuiSelect: {
     styleOverrides: {
      select: {
        margin: "-13px -16px",
        padding: "13px 16px"
      }
     }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: "8px",
          fontSize: 10,
          lineHeight: "14px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: darkBlue,
          textDecoration: "none",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
          padding: "16px 24px",
        },
        filledSuccess: {
          color: "#116837",
          background: "#D9EF8B",
          border: "1px solid #A6D96B",
        },
        filledError: {
          color: "white",
          background: "#D83027",
          border: "1px solid #A50D26",
        },
        filledInfo: {
          color: "white",
          background: "#2196F3",
          border: "1px solid #1E88E5",
        },
        filledWarning: {
          color: "#D83027",
          background: "#FEFEBF",
          border: "1px solid #FEE190",
        },
        message: {
          fontSize: "14px",
          fontWeight: "normal",
          padding: "0",
          lineHeight: "22px",
        },
        action: {
          margin: 0,
          padding: 0,
          marginLeft: "auto",
          ".MuiSvgIcon-root": {
            height: "24px",
            width: "24px",
            color: "#383D46",
            cursor: "pointer",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          background: beige,
          lineHeight: "14px",
          fontWeight: "bold",
          fontSize: "14px",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          margin: 0,
          fontWeight: "bold",
        },
      },
    },
  },
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { getAll } from "../../utils/api";
import { api } from "../services/api";
import { RootState } from "../store";
import { Product } from "./adapter";

export * from "./adapter";

export const productName = (mt: TFunction, product?: Product) =>
  product ? product.customName || mt(product.name) : "";

interface ProductState {
  productIds: string[];
}

const slice = createSlice({
  name: "product",
  initialState: {
    productIds: [],
  } as ProductState,
  reducers: {
    setProductIds: (state, action: PayloadAction<string[]>) => {
      return {
        productIds: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getCoffeeMenuProducts.matchFulfilled,
      (state, { payload }) => {
        let products: Product[] = [];

        if (payload) {
          products = getAll(payload);
        }

        products.sort((a: Product, b: Product) => {
          return a.position! - b.position!;
        });

        const productIds = products.map((w) => w.id);

        return {
          productIds,
        };
      }
    );
  },
});

export default slice.reducer;

export const selectProductIds = (state: RootState) => state.products.productIds;

export const { setProductIds } = slice.actions;

import { Form, Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Option } from "../../../../utils/types";
import { useEntityDialog } from "../../../../hooks/dialog";
import {
  useCreateCoffeeMenuProductMutation,
  useGetCoffeeMenuAvailableProductsQuery,
  useGetCoffeeMenuQuery,
} from "../../../../state/services/api";

import * as Yup from "yup";
import EntityDialog from "../../../../components/EntityDialog";
import { Box, Button, CircularProgress, InputAdornment } from "@mui/material";
import { Save } from "@mui/icons-material";
import TextInput from "../../../../components/form/TextInput";
import { createMinAmountValidator } from "../../../../utils/formik";
import { Mode } from "../../../../state/coffee-menus";
import MultiSelect from "../../../../components/form/MultiSelect";
import { useAlert } from "../../../../components/alerts/AlertContext";

interface MatchParams {
  menuId: string;
}

interface InitialValues {
  name: string;
  price: number;
  code: string;
}

export const CREATE_PRODUCT_DIALOG_NAME = "coffeeMachine.create.product";

const CreateProductDialog = () => {
  const { t } = useTranslation();
  const { t: mt } = useTranslation("machine");

  const { menuId } = useParams<MatchParams>();

  const { data: menu } = useGetCoffeeMenuQuery(menuId);

  const alert = useAlert();

  const [createCoffeeMenuProduct, { isLoading: isSaving }] =
    useCreateCoffeeMenuProductMutation();
    
  const { data: availableProducts } = useGetCoffeeMenuAvailableProductsQuery(menuId);

  const { close } = useEntityDialog({
    name: CREATE_PRODUCT_DIALOG_NAME,
  });

  const submit = useCallback(
    async (
      values: InitialValues,
      { resetForm }: FormikHelpers<InitialValues>
    ) => {
      try {
        await createCoffeeMenuProduct({
          product: {
            customName: values.name,
            price: values.price,
            code: values.code,
          },
          coffeeMenuId: menuId,
        }).unwrap();
        close();
        resetForm();
        alert({
          severity: "success",
          content: t("product.createSuccess"),
          autoHideDuration: 3000,
        });
      } catch (e) {
        alert({
          severity: "error",
          content: t("product.createFailure"),
          autoHideDuration: 3000,
        });
      }
    },
    [createCoffeeMenuProduct, menuId, close, alert, t]
  );

  const getProductCodes = (): Option[] | undefined => {
    return availableProducts?.map(
      (product) => ({ value: product.code, label: mt(product.name) } as Option)
    );
  };

  const codeOptions = getProductCodes() || [];

  const initialValues: InitialValues = {
    name: "",
    code: codeOptions[0]?.value?.toString() ?? "03", // Coffee
    price: 1,
  };

  const validateMinAmount = createMinAmountValidator(
    t,
    menu?.company?.currency,
    menu?.mode === Mode.Payment ? undefined : 0
  );

  const ProductSchema = Yup.object().shape({
    name: Yup.string().max(255, t("validation.maxLength")).required(),
    code: Yup.string().required(),
    price: Yup.number().max(99_999_999).required().test(validateMinAmount),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ProductSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit }) => (
          <EntityDialog
            name={CREATE_PRODUCT_DIALOG_NAME}
            title={t("product.addProductDialog.title")}
            subTitle={t("product.addProductDialog.subTitle")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <TextInput name="name" title={t("product.name") + ":"} />

                {menu?.mode !== Mode.Open && (
                  <TextInput
                    name="price"
                    type="number"
                    title={t("product.price") + ":"}
                    InputProps={{
                      startAdornment: menu && (
                        <InputAdornment position="start">
                          {menu.company.currency}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <MultiSelect
                  name="code"
                  title={t("product.type")}
                  options={codeOptions}
                  defaultValue={codeOptions[0]?.value}
                  helperText={t("required", { field: "Code" })}
                />
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
    </>
  );
};

export default CreateProductDialog;

import { EntityState } from "@reduxjs/toolkit";
import { getAll } from "./api";
import { KeysOfUnion } from "./types";

export const removeProps = <
  T extends Record<string, unknown>,
  Keys extends string
>(
  ob: T,
  ...avoid: Keys[]
) => {
  const copy = { ...ob };
  for (const key of avoid) {
    if (key in copy) {
      delete copy[key];
    }
  }
  return copy;
};

export const select = <
  T extends { [K in keyof T]: T[K] },
  Keys extends Array<KeysOfUnion<T>>,
>(
  ob: T,
  ...use: Keys
) =>
  Object.fromEntries(use.map((key) => [key, ob[key]])) as Pick<
    T,
    typeof use[number]
  >;

interface KVObject<V> {
  [k: symbol | number | string]: V;
}

export function mapKV<V>(
  ob: KVObject<V>,
  fn: (
    key: string,
    value: V,
    index: number,
    arr: Record<string, V>
  ) => [string, V]
) {
  if (!ob) return ob;
  return Object.fromEntries(
    Object.entries(ob).map(([k, v], index, o) => fn(k, v, index, ob))
  );
}

export function mapToOptions<T extends { id: string }>(
  entities: EntityState<T> | T[] | undefined,
  display: (entity: T) => string
) {
  if (!entities) return [];
  const rawEntities = Array.isArray(entities) ? entities : getAll(entities);
  return rawEntities.map((entity) => ({
    value: entity.id,
    label: display(entity),
  }));
}

import { EntityState, SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export const getAll = <T>(state: EntityState<T> | undefined) => {
  if (!state) return [];
  return state.ids.map((id) => state.entities[id]!);
};

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

export function isFetchBaseQueryErrorWithData<T extends object>(
  error: unknown
): error is { status: number; data: T } {
  return isFetchBaseQueryError(error) && typeof error.data === "object";
}

export function getErrorMessage(
  error: FetchBaseQueryError | SerializedError | undefined | unknown
) {
  return isFetchBaseQueryErrorWithData<{ error: string }>(error)
    ? error.data.error
    : undefined;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

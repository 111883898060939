import { Stack, Switch, SwitchProps, Typography } from "@mui/material";

interface SwitchFilterProps extends SwitchProps {
  label?: string;
}

function SwitchFilter({ label, ...props }: SwitchFilterProps) {
  return (
    <Stack>
      <Typography variant="h4" marginBottom={2}>
        {label}
      </Typography>
      <Switch {...props} />
    </Stack>
  );
}

export default SwitchFilter;

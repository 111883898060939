import { Save } from "@mui/icons-material";
import { Box, Button, CircularProgress, capitalize } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCompany } from "../state/auth";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useEditCompanyMutation } from "../state/services/api";
import { useAlert } from "./alerts/AlertContext";
import Dialog from "./Dialog";
import { regionOptions } from "../state/company/company";
import MultiSelect from "./form/MultiSelect";
import { TFunction } from "i18next";
import * as Yup from "yup";
import { Role } from "../state/users/adapter";

export const COMPANY_REGION_DIALOG_NAME = "company.region.select";

const CompanySchema = (t: TFunction) =>
  Yup.object().shape({
    region: Yup.string().oneOf(regionOptions(t).map((option) => option.value)),
  });

const RegionDialog = () => {
  const { t } = useTranslation();
  const company = useSelector(selectCompany);

  const alert = useAlert();

  const initialValues = {
    region: company?.region ?? "other",
  };

  const [editCompany, { isLoading: isSaving }] = useEditCompanyMutation();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      try {
        await editCompany({
          region: values.region || null,
        }).unwrap();
        alert({
          autoHideDuration: 3000,
          content: t("setup.company.saveSuccess"),
        });
      } catch (e) {
        alert({
          autoHideDuration: 3000,
          content: t("setup.company.saveFailure"),
        });
      }
    },
    [alert, editCompany, t]
  );

  const show = useMemo(
    () => !company?.region && company?.role === Role.Admin,
    [company?.region, company?.role]
  );

  if (!show) return <></>;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={CompanySchema(t)}
      onSubmit={submit}
    >
      {({ submitForm }) => (
        <Dialog
          title={t("setup.company.regionModal.title")}
          subTitle={t("setup.company.regionModal.subTitle")}
          open={show}
          container={() => document.body}
          preventBack
          okButton={
            <Button
              type="submit"
              onClick={submitForm}
              variant="contained"
              startIcon={<Save />}
            >
              {isSaving ? (
                <CircularProgress size={20} color="white" />
              ) : (
                t("save")
              )}
            </Button>
          }
        >
          <Box>
            <MultiSelect
              name="region"
              sx={{ width: "100%!important" }}
              title={capitalize(t("region")) + ":"}
              options={regionOptions(t)}
              required
            />
          </Box>
        </Dialog>
      )}
    </Formik>
  );
};

export default RegionDialog;

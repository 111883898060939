import { createEntityAdapter } from "@reduxjs/toolkit";
import { CoffeeMachine } from "../coffe-machines";
import { User } from "../users";

export interface Location {
  id: string;
  name: string;
  address: string;
  coffeeMachines: CoffeeMachine[];
  users: User[];
}

export const locationsAdapter = createEntityAdapter<Location>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

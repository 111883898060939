import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/form/TextInput";
import { GlobalpayPayment } from "../../../state/payments";

const GlobalpayFields = ({
  values,
}: {
  values: Omit<GlobalpayPayment, "id">;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.globalpay.generalTitle")}
        </Typography>
        <TextInput
          key="options.merchantNumber"
          name="options.merchantNumber"
          title={t("setup.payment.globalpay.merchantNumber") + ":"}
        />
        <TextInput
          key="options.gatewayUrl"
          name="options.gatewayUrl"
          title={t("setup.payment.globalpay.gatewayUrl") + ":"}
        />
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.globalpay.keysTitle")}
        </Typography>
        <TextInput
          key="options.privateKey"
          name="options.privateKey"
          type="password"
          multiline
          title={t("setup.payment.globalpay.privateKey") + ":"}
        />
        <TextInput
          key="options.privateKeyPassword"
          name="options.privateKeyPassword"
          type="password"
          title={t("setup.payment.globalpay.privateKeyPassword") + ":"}
        />
        <TextInput
          key="options.publicKey"
          name="options.publicKey"
          type="password"
          multiline
          title={t("setup.payment.globalpay.publicKey") + ":"}
        />
      </Paper>
    </>
  );
};

export default GlobalpayFields;

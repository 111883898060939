import type { RootState } from "../store";
import { getAll } from "../../utils/api";
import { api } from "../services/api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Widget } from "./adapter";

interface WidgetState {
  widgetIds: string[];
}

const slice = createSlice({
  name: "widget",
  initialState: {
    widgetIds: [],
  } as WidgetState,
  reducers: {
    setWidgetIds: (state, action: PayloadAction<string[]>) => {
      return {
        widgetIds: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getWidgets.matchFulfilled,
      (state, { payload }) => {
        let widgets: Widget[] = [];

        if (payload) {
          widgets = getAll(payload);
        }

        widgets.sort((a: Widget, b: Widget) => {
          return a.position! - b.position!;
        });

        const widgetIds = widgets.map((w) => w.id);

        return {
          widgetIds,
        };
      }
    );
  },
});

export default slice.reducer;

export const selectWidgetIds = (state: RootState) => state.widgets.widgetIds;

export const { setWidgetIds } = slice.actions;

export * from "./adapter";

import { useCallback, useState } from "react";

const PREFIX = "pocket-pilot-";

const getItem = <T>(key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  return JSON.parse(data) as T;
}

const setItem = <T>(key: string, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
}

export function useLocalStorage<T>(key: string, initialState?: T) {
  const actualKey = `${PREFIX}${key}`;
  const [state, setState] = useState(getItem(actualKey) || initialState);
  
  const setter = useCallback((s: T) => {
    setItem(actualKey, s);
    setState(s);
  }, [setState, actualKey]);

  return [state, setter] as [typeof state, typeof setState];
}
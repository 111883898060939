import { Box, MenuItem, Tooltip } from "@mui/material";
import { GridExportMenuItemProps } from "@mui/x-data-grid";

interface MenuItemProps extends GridExportMenuItemProps<{}> {
  tooltip?: string;
  onClick: () => Promise<unknown>;
  children: React.ReactNode;
}

function ExportMenuItem({
  hideMenu,
  onClick: exportData,
  children,
  tooltip,
  ...props
}: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      onClick={async () => {
        await exportData();
        hideMenu?.();
      }}
    >
      {tooltip && children ? (
        <Tooltip
          arrow
          title={tooltip}
          PopperProps={{
            sx: {
              lineHeight: "1.1rem",
              pointerEvents: "none",
            },
          }}
        >
          <Box>{children}</Box>
        </Tooltip>
      ) : (
        children
      )}
    </MenuItem>
  );
}

export default ExportMenuItem;

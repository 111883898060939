import { Save } from "@mui/icons-material";
import { Box, Grid, Button, CircularProgress, capitalize } from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  useCreatePaymentMutation,
  useEditCompanyMutation,
  useGetPaymentsQuery,
} from "../../state/services/api";

import { TemporaryAlert } from "../../components/Alert";
import { Payment, PaymentType } from "../../state/payments";
import { select } from "../../utils/object";
import { selectCompany } from "../../state/auth";
import { useSelector } from "../../state/hooks";
import { getAll, getErrorMessage } from "../../utils/api";
import MultiSelect from "../../components/form/MultiSelect";
import { usePageInfo } from "../../hooks/page";
import { TFunction } from "i18next";
import { providerForType } from "../../state/payments/providers/interface";

// Globalpay is deactivated on prod ATM. Should not be deactivated on dev.
const DISABLED_PAYMENT_TYPES: string[] = [
  /* PaymentType.Globalpay */
];

const sortedPayments = (payments: Payment[]) =>
  payments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

const latestPaymentOfType = (
  payments: Payment[],
  paymentType: PaymentType | "none"
) => {
  if (!paymentType) return null;
  return sortedPayments(payments).find(
    (payment) => payment.type === paymentType
  );
};

const paymentById = (payments: Payment[], id: string) =>
  payments.find((payment) => payment.id === id);

const KeySchema = (t: TFunction) =>
  Yup.object().shape({
    type: Yup.string()
      .oneOf(
        (Object.values(PaymentType) as Array<PaymentType | "none">).concat(
          "none"
        )
      )
      .required(),
    name: Yup.string().required(),
    options: Yup.object().when("type", (type, schema) => {
      const provider = providerForType(type);
      return provider.getOptionSchema(schema, t);
    }),
  });

const PaymentTab = () => {
  const { t } = useTranslation();

  usePageInfo({
    title: t("setup.title"),
    subTitle: t("setup.payment.subTitle"),
  });

  const activePaymentOptions = useMemo(
    () =>
      Object.values(PaymentType).filter(
        (paymentType) => !DISABLED_PAYMENT_TYPES.includes(paymentType)
      ),
    []
  );

  const paymentTypeOptions = useMemo(
    () => [
      { label: capitalize(t("none")), value: "none" },
      ...activePaymentOptions.map((type) => ({
        label: capitalize(t(type)),
        value: type,
      })),
    ],
    [activePaymentOptions, t]
  );

  const company = useSelector(selectCompany);

  const { data: paymentsState } = useGetPaymentsQuery();
  const payments = useMemo(() => getAll(paymentsState), [paymentsState]);

  const [
    createPayment,
    {
      isLoading: isSavingPayment,
      isError: isErrorPayment,
      isSuccess: isSuccessPayment,
    },
  ] = useCreatePaymentMutation();

  const [
    editCompany,
    {
      isLoading: isSavingCompany,
      isError: isErrorCompany,
      isSuccess: isSuccessCompany,
      error: editCompanyError,
    },
  ] = useEditCompanyMutation();

  const [isSaving, isError, isSuccess] = [
    isSavingPayment || isSavingCompany,
    isErrorPayment || isErrorCompany,
    (isSuccessPayment || isSuccessCompany) &&
      !(isErrorPayment || isErrorCompany),
  ];

  const errorMessage = useMemo(() => {
    if (!editCompanyError) return null;
    return getErrorMessage(editCompanyError) ===
      "editCompany.paymentProviderGuard"
      ? t("setup.company.saveFailurePaymentGuard")
      : null;
  }, [editCompanyError, t]);

  const submit = useCallback(
    async (
      values: Omit<Payment, "id" | "type" | "createdAt"> & {
        type: PaymentType | "none";
      }
    ) => {
      if (values.type === "none") {
        await editCompany({
          defaultPaymentId: null,
        }).unwrap();
        return;
      }

      const actualPayment = values as Payment;

      const provider = providerForType(actualPayment.type);
      let options = select(
        actualPayment.options,
        ...provider.options
      );

      const result = {
        ...actualPayment,
        type: actualPayment.type,
        options,
      } as Payment;

      const newPayment = await createPayment(result).unwrap();
      await editCompany({
        defaultPaymentId: newPayment.id,
      }).unwrap();
    },
    [createPayment, editCompany]
  );

  const type: PaymentType | "none" = useMemo(() => {
    if (!company) return "none";
    return paymentById(payments, company?.defaultPaymentId)?.type ?? "none";
  }, [company, payments]);

  const getInitialPayment = useCallback(
    (type: PaymentType | "none") => {
      const payment =
        company && paymentById(payments, company?.defaultPaymentId);
      const initialPayment =
        type === payment?.type ? payment : latestPaymentOfType(payments, type);

      let initialOptions = initialPayment?.options ?? {};
      initialOptions =
        type === "none"
          ? {}
          : Object.fromEntries(
              providerForType(type).options.map((option) => [
                option,
                option in initialOptions
                  ? (initialOptions as any)[option] ?? ""
                  : "",
              ])
            );

      return {
        ...initialPayment,
        id: initialPayment?.id,
        name:
          initialPayment?.name ?? type,
        type,
        options: initialOptions,
      };
    },
    [company, payments]
  );

  const [initialValues, setInitialValues] = useState<
    ReturnType<typeof getInitialPayment>
  >(getInitialPayment(type));

  useEffect(() => {
    if (initialValues.type === "none") {
      setInitialValues(getInitialPayment(type));
    }
  }, [getInitialPayment, initialValues.type, payments, type]);

  useEffect(() => {
    if (initialValues.type !== "none") {
      setInitialValues(getInitialPayment(type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const getFieldsComponent = useCallback((values: typeof initialValues) => {
    const provider = providerForType(values.type);
    return provider.fields;
  }, []);

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        validationSchema={KeySchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values, resetForm }) => {
          const FieldsComponent = getFieldsComponent(values);
          return (
            <Grid item xs={12} className="payments">
              <Box>
                <MultiSelect
                  name="type"
                  formControlProps={{ fullWidth: false, sx: { width: "40%" } }}
                  options={paymentTypeOptions}
                  helperText={t("required", { field: "paymentMethod" })}
                  title={t("paymentMethod")}
                  onChange={(value: unknown) =>
                    resetForm({
                      values: getInitialPayment(value as PaymentType),
                    })
                  }
                />
                <Form>
                  <FieldsComponent values={values} />
                  <Button
                    sx={{ mt: 2, width: "auto" }}
                    variant="contained"
                    startIcon={!isSaving && <Save />}
                    onClick={() => handleSubmit()}
                  >
                    {isSaving ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Form>
              </Box>
            </Grid>
          );
        }}
      </Formik>
      <TemporaryAlert open={!isSaving && isError} severity="error">
        {errorMessage ?? t("setup.addPaymentModal.saveFailure")}
      </TemporaryAlert>
      <TemporaryAlert open={!isSaving && isSuccess}>
        {t("setup.addPaymentModal.saveSuccess")}
      </TemporaryAlert>
    </Grid>
  );
};

export default PaymentTab;

import {
  capitalize,
  FormControl,
  FormControlProps,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FieldHookConfig, useField } from "formik";
import { useMemo } from "react";

type TextInputProps = TextFieldProps & {
  title?: string;
  helperText?: string;
  formControlProps?: FormControlProps;
  name: string;
  type?: string;
};

const TextInput = <T extends {}>({
  title,
  helperText,
  formControlProps,
  name,
  type,
  ...props
}: TextInputProps & FieldHookConfig<T>) => {
  const [field, meta] = useField({ ...props, name });
  const showError = meta.touched && !!meta.error;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoField = useMemo(() => field, Object.values(field));
  return (
    <FormControl margin="normal" fullWidth {...formControlProps}>
      {title && (
        <InputLabel shrink htmlFor={name}>
          {title}
        </InputLabel>
      )}
      <TextField
        id={name}
        error={showError}
        helperText={capitalize((showError ? meta.error : helperText) || "")}
        type={type}
        {...memoField}
        {...props}
      />
    </FormControl>
  );
};

export default TextInput;

import { createEntityAdapter } from "@reduxjs/toolkit";
import { CoffeeMenu } from "../coffee-menus";

export interface Product {
  id: string;
  name: string;
  customName: string | null;
  active: boolean;
  code: string;
  price: number;
  priceUpdate?: number;
  priceUpdateTime?: number;
  picture: string;
  coffeeMenu?: CoffeeMenu;
  coffeeMenuId?: string;
  settings?: ProductSetting[];
  position?: number;
}

export interface Consumption {
  amount: number;
  price: number;
  currency: string;
  product: Product;
}

export enum ProductCategory {
  Cappucino = "cappucino",
  Tea = "tea",
  Coffee = "coffee",
  Milk = "milk",
  Espresso = "espresso",
  LatteMacciato = "lattemacciato",
  MilkCoffee = "milkcoffee",
  Water = "water",
  Macciato = "macciato",
  Unknown = "unknown"
}

export enum ProductSettingType {
  Choice = "choice",
  Progress = "progress",
  Checkbox = "checkbox",
}

export interface SortedProductIds {
  productIds: string[];
}

export type ProductConfiguration =
  | {
      type: ProductSettingType.Progress;
      min: number;
      max: number;
      step: number;
    }
  | {
      type: ProductSettingType.Choice;
      choices: Array<{ name: string; text: string; value: number }>;
    }
  | {
      type: ProductSettingType.Checkbox;
    };

export interface NewProduct
  extends Omit<Product, "id" | "active" | "picture" | "name"> {}

export interface ProductSetting {
  id: string;
  name: string;
  value: unknown;
  argument: string;
  unit?: string;
  configuration: ProductConfiguration;
}

export const productsAdapter = createEntityAdapter<Product>({
  selectId: (product) => product.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

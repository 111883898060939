import KeycloakInstance from "keycloak-js";
import { createContext } from "react";

import { AuthClient } from "./types";

export type IAuthContextProps<T extends AuthClient> = {
  authClient?: T;
  initialized: boolean;
};

export const ReactKeycloakWebContext = createContext<IAuthContextProps<KeycloakInstance>>({
  initialized: false,
});

export const ReactKeycloakWebContextConsumer = ReactKeycloakWebContext.Consumer

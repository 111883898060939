import { Link } from "@mui/material";
import { Typography } from "@mui/material";
import { StackProps, Stack } from "@mui/system";
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useRef,
  ReactNode,
} from "react";
import { v4 as uuidv4 } from "uuid";

interface Heading {
  id: string;
  ref: string;
  title: string;
  level: number;
  location: number[];
  parentId?: string;
}

export type HeadingTree = Array<Heading & { children: HeadingTree }>;

interface HeadingContextValue {
  ids: string[];
  headings: Record<string, Heading>;
  headingsTree: HeadingTree;
  updateHeading: (
    id: string,
    heading: Heading | ((heading: Heading) => Heading)
  ) => void;
  registerHeading: (heading: Heading) => void;
  deregisterHeading: (heading: Heading) => void;
}
interface HeadingLevelContextValue {
  parent: Heading;
}

export const HeadingContext = createContext<HeadingContextValue | null>(null);
const HeadingLevelContext = createContext<HeadingLevelContextValue | null>(
  null
);

export const useHeadingProvider = (): HeadingContextValue => {
  const [headings, setHeadings] = useState<Record<string, Heading>>({});
  const [ids, setIds] = useState<string[]>([]);

  const registerHeading = useCallback((heading: Heading) => {
    setHeadings((prevHeadings) => ({
      ...prevHeadings,
      [heading.id]: heading,
    }));
    setIds((prevIds) =>
      !prevIds.includes(heading.id) ? prevIds.concat(heading.id) : prevIds
    );
  }, []);

  const deregisterHeading = useCallback((heading: Heading) => {
    setHeadings((prevHeadings) =>
      Object.fromEntries(
        Object.entries(prevHeadings).filter(([id]) => id !== heading.id)
      )
    );
    setIds((prevIds) => prevIds.filter((id) => id !== heading.id));
  }, []);

  useEffect(() => {
    setIds([]);
    setHeadings({});
  }, []);

  const buildTree = useCallback(
    (root: Heading): HeadingTree[number] => {
      const sortedHeadings = ids.map((id) => headings[id]);
      const children = sortedHeadings
        .filter(({ parentId }) => parentId === root.id)
        .map(buildTree);
      return {
        ...root,
        children,
      };
    },
    [headings, ids]
  );

  const headingsTree = useMemo(() => {
    const sortedHeadings = ids.map((id) => headings[id]);
    const roots = sortedHeadings.filter(({ parentId }) => !parentId);
    return roots.map(buildTree);
  }, [buildTree, headings, ids]);

  const updateHeading = useCallback(
    (id: string, heading: Heading | ((heading: Heading) => Heading)) => {
      setHeadings((prevHeadings) => {
        const newHeading =
          typeof heading === "function" ? heading(prevHeadings[id]) : heading;
        return {
          ...prevHeadings,
          [newHeading.id]: newHeading,
        };
      });
    },
    []
  );

  return {
    ids,
    headings,
    headingsTree,
    registerHeading,
    deregisterHeading,
    updateHeading,
  };
};

const useHeading = (title: string) => {
  const ctx = useContext(HeadingLevelContext);
  const headingCtx = useContext(HeadingContext);
  const id = useRef(uuidv4());
  const initialHeading = useRef<Heading>({
    id: id.current,
    title,
    ref: title.replaceAll(/[^\w]/g, "_").toLowerCase(),
    level: 1,
    location: [],
    parentId: ctx?.parent.id,
  });

  const heading = useMemo(() => {
    return headingCtx?.headings[id.current] ?? initialHeading.current;
  }, [headingCtx?.headings]);

  const getAncestors = useCallback(() => {
    let currentHeading = heading;
    if (!headingCtx) return [];
    const ancestors = [];
    ancestors.push(currentHeading);
    while (currentHeading?.parentId) {
      currentHeading = headingCtx?.headings[currentHeading.parentId];
      if (currentHeading) {
        ancestors.push(currentHeading);
      }
    }
    return ancestors.reverse();
  }, [heading, headingCtx]);

  const getLevel = useCallback(() => {
    return getAncestors().length;
  }, [getAncestors]);

  const getLocation = useCallback(() => {
    const ancestors = getAncestors();
    const sortedHeadings = headingCtx?.ids.map((id) => headingCtx.headings[id]);
    const location =
      ancestors.map(({ id: ancestorId, parentId: ancestorParentId }) => {
        const offset =
          sortedHeadings
            ?.filter(
              (filterHeading) => filterHeading.parentId === ancestorParentId
            )
            .findIndex((searchHeading) => searchHeading.id === ancestorId) ?? 1;
        if (offset === -1) return 1;
        return offset + 1;
      }) ?? [];

    return location;
  }, [getAncestors, headingCtx?.headings, headingCtx?.ids]);

  useEffect(() => {
    const newLevel = getLevel();
    const newLocation = getLocation();
    if (!headingCtx?.headings[heading.id]) {
      headingCtx?.registerHeading(heading);
    }
    if (
      heading.level !== newLevel ||
      heading.location.length !== newLocation.length ||
      heading.location.some((el, i) => newLocation[i] !== el)
    ) {
      headingCtx?.updateHeading(heading.id, (prevHeading) => ({
        ...prevHeading,
        level: newLevel,
        location: newLocation,
      }));
    }
  }, [
    getLevel,
    getLocation,
    heading,
    heading.level,
    heading.location,
    headingCtx,
  ]);

  return heading;
};

const Header = ({
  title,
  children,
  ...stackProps
}: {
  title: string;
  children?: ReactNode;
} & StackProps) => {
  const heading = useHeading(title);
  
  const headingUrl = (heading: Heading) => `${window.location.origin}${window.location.pathname}#${heading.ref}`;

  const handleHeadingClick = (heading: Heading) => {
    navigator.clipboard.writeText(headingUrl(heading));
  };

  return (
    <>
      <Link href={headingUrl(heading)}>
        <Typography
          id={heading?.ref}
          variant={`h${heading?.location.length}` as "h1" | "h2" | "h3" | "h4"}
          sx={{
            mt: 5,
            scrollMarginTop: 100,
            "&:hover": {
              color: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            },
          }}
          onClick={() => handleHeadingClick(heading)}
        >
          {heading?.location.join(".")}. {title}
        </Typography>
      </Link>
      <HeadingLevelContext.Provider value={{ parent: heading }}>
        <Stack
          direction="column"
          rowGap={2}
          alignItems="flex-start"
          {...stackProps}
        >
          {children}
        </Stack>
      </HeadingLevelContext.Provider>
    </>
  );
};

export default Header;

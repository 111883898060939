import { MachineConsumptionProps } from "../page/coffee-machines/machine/MachineConsumption";
import { useExportCoffeeMachineConsumptionMutation } from "../state/services/api";
import { ConsumptionFilter } from "../state/users";
import { ExportData, ExportType, useEntityExport } from "./export";

export function useCoffeeMachineConsumptionExport(
  type: ExportType,
  exportData: ExportData<
    { machineId?: string; filter?: ConsumptionFilter },
    MachineConsumptionProps
  >
) {
  const [exportConsumption] = useExportCoffeeMachineConsumptionMutation();

  return useEntityExport(
    type,
    exportData,
    exportConsumption,
    "CoffeeMachineConsumption"
  );
}

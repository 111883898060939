import { FilterAlt } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useGetCoffeeMachinesQuery, useGetLocationsQuery } from "../state/services/api";
import { capitalize } from "../utils/string";
import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../utils/api";
import TreeSelect, { TreeSelectItem } from "./TreeSelect";
import { selectFilterFunc, setFilterFunc } from "../state/utils/filterSlice";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const FilterInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== "hasTitle",
})<{ hasTitle: boolean }>`
  ${({ hasTitle }) => !hasTitle && `margin-top: 0;`}
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  input::placeholder {
    opacity: 0.8;
  }
  input:hover {
    cursor: pointer;
  }
  input {
    caretcolor: transparent;
  }
`;

type dummyFunc = () => { locations: undefined; machines: undefined };
type handleChange = (filter: {
  locations: string[] | undefined;
  machines: string[] | undefined;
}) => void;

interface CMTreeSelectProps {
  setFilter?: setFilterFunc;
  selectFilter: selectFilterFunc | dummyFunc;
  filterFromProps?: {
    locations?: string[];
    machines?: string[];
  };
  formControlProps?: FormControlProps;
  receiveFromProps?: boolean;
  handleOnChange?: handleChange;
  inputProps?: OutlinedInputProps;
  fullWidth?: boolean;
  title?: string;
}

const emptyFilter = { locations: [], machines: [] };

const CoffeeMachineTreeSelect = (props: CMTreeSelectProps) => {
  const { t } = useTranslation();

  const {
    setFilter,
    selectFilter,
    filterFromProps,
    receiveFromProps,
    inputProps,
    fullWidth,
    title,
    formControlProps,
  } = props;

  let filter;

  filter = useSelector(selectFilter);

  if (receiveFromProps && filterFromProps) {
    filter = filterFromProps;
  }

  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery<typeof theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const { data: allMachinesState } = useGetCoffeeMachinesQuery(emptyFilter);
  const { data: allLocationsState } = useGetLocationsQuery();
  const allMachines = allMachinesState && getAll(allMachinesState);

  const allLocations = allLocationsState && getAll(allLocationsState);

  const filterValues = filter?.locations?.concat(filter.machines ?? []) ?? [];

  const setFilterValue = useCallback(
    (values: string[]) => {
      if (setFilter) {
        const newFilter = {
          locations: allLocations
            ?.filter((location) => values.includes(location.id))
            .map((loc) => loc.id),
          machines: allMachines
            ?.filter((machine) => values.includes(machine.id))
            .map((machine) => machine.id),
        };

        dispatch(setFilter(newFilter));
      }
    },
    [dispatch, setFilter, allLocations, allMachines]
  );

  const onChange = (values: string[]) => {
    if (props.handleOnChange) {
      props.handleOnChange({
        locations: allLocations
          ?.filter((location) => values.includes(location.id))
          .map((loc) => loc.id),
        machines: allMachines
          ?.filter((machine) => values.includes(machine.id))
          .map((machine) => machine.id),
      });
    }
  };

  return (
    <TreeSelect
      value={filterValues}
      onChange={receiveFromProps ? onChange : setFilterValue}
      fullWidth={fullWidth === undefined ? isMobile : fullWidth}
      renderInput={(nodes) => (
        <FormControl margin="normal" fullWidth {...formControlProps}>
          {title && <InputLabel shrink>{title}</InputLabel>}
          <FilterInput
            hasTitle={!!title}
            placeholder={capitalize(t("filter"))}
            value={nodes.map((n) => n.label)}
            endAdornment={<FilterAlt />}
            {...inputProps}
          />
        </FormControl>
      )}
    >
      {allLocations?.map((loc) => (
        <TreeSelectItem key={loc.id} nodeId={loc.id} label={loc.name}>
          {allMachines
            ?.filter((m) => m.location.id === loc.id)
            .map((machine) => (
              <TreeSelectItem
                key={machine.id}
                nodeId={machine.id}
                label={`${machine.nameHuman ?? machine.name} (${machine.model})`}
              />
            ))}
        </TreeSelectItem>
      ))}
    </TreeSelect>
  );
};

export default CoffeeMachineTreeSelect;

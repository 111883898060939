import {
  Box,
  capitalize,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface Option {
  value: string;
  label: string;
}

interface SelectFilterProps {
  label: string;
  name: string;
  options: Option[];
  value?: string;
  setValue: (status?: string) => void;
}

function SelectFilter({
  name,
  options,
  label,
  value,
  setValue,
}: SelectFilterProps) {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant="h3">{label}</Typography>
      <FormControl margin="normal" fullWidth>
        <Select
          id={name}
          name={name}
          value={value ?? "__default"}
          sx={{
            margin: 0,
            maxWidth: "250px",
            "& .MuiSelect-select": { display: "flex" },
          }}
          onChange={(e) =>
            setValue(
              e.target.value === "__default" ? undefined : e.target.value
            )
          }
        >
          <MenuItem value="__default" key="__default">
            <Box>{capitalize(t("all"))}</Box>
          </MenuItem>
          {options.map(({ label, value }) => (
            <MenuItem value={value} key={value}>
              <Box>{label}</Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default SelectFilter;

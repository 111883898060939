import { ReactElement, JSXElementConstructor } from "react";
import { BaseSchema } from "yup";
import { AnyObject } from "yup/lib/types";
import { PaymentProvider } from "./interface";
import { requiredName } from "../../../utils/i18n";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { OptionalObjectSchema, ObjectShape, TypeOfShape } from "yup/lib/object";
import SaferpayFields from "../../../page/setup/PaymentFields/SaferpayFields";
import { SaferpayPaymentOptions } from "..";

export default class SaferpayProvider implements PaymentProvider {
  type = "saferpay";

  options = [
    "terminalId",
    "customerId",
    "username",
    "password",
    "gatewayUrl",
  ] as Array<keyof SaferpayPaymentOptions>;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction
  ): BaseSchema<any, AnyObject, any> {
    return schema.shape({
      terminalId: Yup.string().required(
        requiredName(t, "setup.payment.saferpay.terminalId")
      ),
      customerId: Yup.string().required(
        requiredName(t, "setup.payment.saferpay.customerId")
      ),
      username: Yup.string().required(
        requiredName(t, "setup.payment.saferpay.username")
      ),
      password: Yup.string().required(
        requiredName(t, "setup.payment.saferpay.password")
      ),
    });
  }

  fields(props: any): ReactElement<any, string | JSXElementConstructor<any>> {
    return SaferpayFields(props);
  }
}

import { Company } from "../auth";
import { Role } from "./adapter";

export * from "./adapter";

export const hasCompanyNecessaryRole = (
  allowedRoles: Role[] | Role,
  company: Company
): boolean =>
  !!company.role &&
  ((Array.isArray(allowedRoles) && allowedRoles.includes(company.role)) ||
    (typeof allowedRoles === "string" && company.role === allowedRoles));

export const hasNecessaryRole = (
  allowedRoles: Role[] | Role,
  company: Company,
  companies: Company[]
): boolean => {
  const companyReference = Object.fromEntries(
    companies.map((company) => [company.id, company])
  );

  const ancestors: Company[] = [company];
  let currentCompany = company;
  while (currentCompany?.parentCompanyId) {
    currentCompany = companyReference[currentCompany.parentCompanyId];
    if (currentCompany) {
      ancestors.push(currentCompany);
    }
  }

  const res = ancestors
    .reverse()
    .find((ancestor) => hasCompanyNecessaryRole(allowedRoles, ancestor));
  return res !== undefined;
};

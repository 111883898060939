import { FormControlLabelProps } from "@mui/material";

export const reactNodeToLabel = (
  node: React.ReactNode
): FormControlLabelProps["label"] => {
  if (typeof node === "boolean") return "";
  if (
    node &&
    typeof node === "object" &&
    "key" in node &&
    "type" in node &&
    "props" in node
  )
    return node;
  if (typeof node === "string" || typeof node === "number")
    return node.toString();
  else return "";
};

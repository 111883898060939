import React, { Suspense } from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { createGlobalStyle } from "styled-components";
import Loading from "./page/Loading";
import theme from "./theme";
import ReactDOM from "react-dom";

import './utils/redirect'

const GlobalStyles = createGlobalStyle`
:root {
  --drawer-open: 240px;  
  --drawer-closed: 64px;
  ${theme.breakpoints.down("sm")} {
    --drawer-open: 100%;
    --drawer-closed: 100%;
  }
  --drawer-width: var(--drawer-open);
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
`;

// TODO: Tracking https://github.com/reduxjs/react-redux/issues/1977
// When resolved we can move to React-18 createRoot()
const container = document.getElementById("root")
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <GlobalStyles />
      <App />
    </Suspense>
  </React.StrictMode>,
  container
);

reportWebVitals();

import { useKeycloak } from "../../components/auth";
import { parse } from "qs";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import { useSelfInviteMutation } from "../../state/services/api";
import Loading from "../Loading";
import { isFetchBaseQueryErrorWithData } from "../../utils/api";
import { useIsAuthenticated, useLogin } from "../../hooks/auth";

const ReverseInvite = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const router = useHistory();
  const { t, i18n } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const queryParams = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );
  const email = useMemo(
    () => queryParams?.email?.toString(),
    [queryParams?.email]
  );
  const companyId = useMemo(
    () => queryParams?.companyId?.toString(),
    [queryParams?.companyId]
  );
  const invited = useMemo(
    () => queryParams?.invited?.toString(),
    [queryParams?.invited]
  );

  const [login, errorAlert] = useLogin();

  const [selfInvite, { isLoading, isError, error: selfInviteError }] =
    useSelfInviteMutation();

  const isAlreadyAssigned = (e: unknown) =>
    e &&
    isFetchBaseQueryErrorWithData<{ error: string }>(e) &&
    e.data.error === "user.registerForCompanyVerification.alreadyAssigned";

  useEffect(() => {
    if (!companyId) {
      history.replace("/");
    }

    const handleInvite = async () => {
      if (!invited) {
        keycloak?.login({
          redirectUri: `${window.location.origin}/${location.pathname}?invited=true&companyId=${companyId}`,
          loginHint: email,
        });
      } else {
        if (keycloak.token && companyId) {
          try {
            await selfInvite({
              token: keycloak.token,
              companyId,
              language: i18n.language,
            }).unwrap();
          } catch (e: unknown) {
            if (isAlreadyAssigned(e) && (isAuthenticated || (await login()))) {
              router.push("/");
              return;
            }
          }
          await keycloak.logout();
        }
      }
    };

    handleInvite();
  }, [
    i18n.language,
    companyId,
    email,
    history,
    invited,
    keycloak,
    location.pathname,
    login,
    router,
    selfInvite,
    isAuthenticated,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError && !isAlreadyAssigned(selfInviteError)) {
    return (
      <Typography variant="h3" sx={{ mb: 4, color: theme.palette.error.main }}>
        {t("auth.reverseInvite.failure")}
      </Typography>
    );
  }

  return companyId ? (
    <>
      {errorAlert}
      <Typography variant="h3">
        {invited
          ? t("auth.reverseInvite.invited", {
              name: keycloak.profile
                ? `${keycloak.profile?.firstName} ${keycloak.profile?.lastName}`
                : t("auth.reverseInvite.noNameFiller"),
            })
          : "Redirecting..."}
      </Typography>
    </>
  ) : null;
};

export default ReverseInvite;

import { createEntityAdapter } from "@reduxjs/toolkit";
import { Product } from "../products";
import { Company } from "../auth";

export interface CoffeeMenu {
  id: string;
  articleNumber: string;
  name: string;
  model: string;
  xml: string;
  isDefault: boolean;
  mode: Mode;
  company: Company;
  products: Product[];
}

export enum Mode {
  Open = "open",
  Payment = "payment",
  Prepaid = "prepaid",
};

export const coffeeMenusAdapter = createEntityAdapter<CoffeeMenu>({
  selectId: (menu) => menu.id,
  sortComparer: (a, b) => a.articleNumber.localeCompare(b.articleNumber),
});

export interface EditMenuArgs {
  name: string;
  mode: Mode;
}

import { Save } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { object, string, ref as yupRef } from "yup";
import { TemporaryAlert } from "../../components/Alert";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import { useEntityDialog } from "../../hooks/dialog";
import { useTransferCompanyMutation } from "../../state/services/api";

const TransferMachineSchema =  (t: TFunction) =>
 object().shape({
  email: string().email().required(),
  emailRepeat: string()
    .required(t("setup.company.transferModal.emailRepeatFailure"))
    .oneOf([yupRef("email"), null], t("setup.company.transferModal.emailRepeatFailure")),
});

export const TRANSFER_COMPANY_MODAL = "setup.company.transferOwnership";
export const TRANSFER_COMPANY_MODAL_PROMPT = "setup.company.transferOwnership.prompt";

const TransferCompanyModal = () => {
  const { t } = useTranslation();

  const { close } = useEntityDialog({
    name: TRANSFER_COMPANY_MODAL,
  });
  const { close: closePrompt, open: showPrompt } = useEntityDialog({
    name: TRANSFER_COMPANY_MODAL_PROMPT,
  });

  const [transferCompany, { isLoading: isSaving, isSuccess, isError }] =
    useTransferCompanyMutation();
    
  const initialValues: {
    email: string;
    emailRepeat: string;
  } = {
    email: "",
    emailRepeat: "",
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      await transferCompany({
        email: values.email,
      }).unwrap();
      closePrompt();
      close();
    },
    [transferCompany, close, closePrompt]
  );

  return (
    <>

      <Formik
        initialValues={initialValues}
        validationSchema={TransferMachineSchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, validateForm, values, isValid }) => (
          <>
          <EntityDialog
            name={TRANSFER_COMPANY_MODAL_PROMPT}
            title={t("setup.company.transferModal.promptTitle")}
            subTitle={t("setup.company.transferModal.promptSubTitle", { user: values.email })}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box></Box>
          </EntityDialog>
          <EntityDialog
            name={TRANSFER_COMPANY_MODAL}
            title={t("setup.company.transferModal.title")}
            subTitle={t("setup.company.transferModal.subTitle")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => {
                  validateForm(values);
                  if (isValid) {
                    showPrompt()
                  }
                }}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box sx={{ mt: 4 }}>
              <Form>
                <Box sx={{ mt: 2 }}>
                  <TextInput
                    name="email"
                    type="email"
                    title={t("setup.company.transferModal.email") + ":"}
                  />
                  <TextInput
                    name="emailRepeat"
                    type="emailRepeat"
                    title={t("setup.company.transferModal.emailRepeat") + ":"}
                  />
                </Box>
              </Form>
            </Box>
          </EntityDialog></>
        )}
      </Formik>

      <TemporaryAlert open={isError} severity="error">
        {t("setup.company.transferModal.saveFailure")}
      </TemporaryAlert>
      <TemporaryAlert open={isSuccess}>
        {t("setup.company.transferModal.saveSuccess")}
      </TemporaryAlert>
    </>
  );
};

export default TransferCompanyModal;

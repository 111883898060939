import { useEffect, useState } from "react";

import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Drawer from "./Navigation";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Container,
  IconButton,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import muiTheme from "../theme";
import logo from "../assets/logo.svg";
import { selectCurrentUser } from "../state/auth";
import { useSelector } from "../state/hooks";
import CompanySelect from "./CompanySelect";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Person,
} from "@mui/icons-material";
import { ellipsisOverflow } from "../utils/string";
import UserNotifications from "./Notifications";
import RegionDialog from "../components/RegionDialog";

interface LayoutProps {
  isUserRegistrationComplete: boolean;
  children: React.ReactNode;
}

const ContentBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  z-index: 0;
`;

const LayoutBox = styled(Box)<{ open: boolean }>`
  --drawer-width: ${({ open }) =>
    open ? "var(--drawer-open)" : "var(--drawer-closed)"};

  display: flex;
  min-height: 100vh;
`;

const LogoContainer = styled(Box)`
  transition: ${({ theme }) =>
    theme.transitions.create("width", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    })};
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: var(--drawer-width);
  }
`;

const Logo = styled("img")`
  display: block;
`;

const InnerBox = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
`;

const ToolbarContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledAppBar = styled(AppBar)`
  @media print {
    display: none;
  }
`;

const Layout = ({ isUserRegistrationComplete, children }: LayoutProps) => {
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen((open) => !open);
  };

  const xlScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("xl")
  );
  const isMobile = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.down("sm")
  );

  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [location, isMobile]);

  useEffect(() => {
    setOpen(xlScreen);
  }, [xlScreen]);

  const user = useSelector(selectCurrentUser);

  return (
    <Box>
      <RegionDialog />
      <LayoutBox open={open}>
        <CssBaseline />
        <StyledAppBar color="inherit" variant="outlined" elevation={0}>
          <Toolbar>
            <LogoContainer>
              <Link to="/">
                <Logo src={logo} alt="Jura" />
              </Link>
            </LogoContainer>
            <ToolbarContainer>
              <UserNotifications sx={{ mr: 2 }} />
              {isUserRegistrationComplete && <CompanySelect sx={{ mr: 2 }} />}
              {!isMobile ? (
                <Link to="/profile">
                  <Button endIcon={<Person />}>
                    <Typography fontWeight="bold">
                      {ellipsisOverflow(
                        `${user?.firstName} ${user?.lastName}`,
                        30
                      )}
                    </Typography>
                  </Button>
                </Link>
              ) : (
                <IconButton
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                >
                  {open ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              )}
            </ToolbarContainer>
          </Toolbar>
        </StyledAppBar>
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <ContentBox>
          <Toolbar />
          <InnerBox id="content-container">{children}</InnerBox>
        </ContentBox>
      </LayoutBox>
    </Box>
  );
};

export default Layout;

// timezones we want to redirect.

import { TFunction } from "i18next";

// timezones for countries: https://en.wikipedia.org/wiki/Asia%E2%80%93Pacific

const asiaTimezones = [
  // East asia
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Harbin",
  "PRC",

  "Asia/Shanghai",
  "Antarctica/Vostok",
  "Asia/Kashgar",
  "Asia/Urumqi",
  "Asia/Hong_Kong",
  "Hongkong",
  "Asia/Macau",
  "Asia/Macao",

  // Japan
  "Japan",
  "Asia/Tokyo",

  // Mongolia
  "Asia/Hovd",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",

  "Asia/Pyongyang", // North Korea
  "Asia/Seoul", // South Korea
  "ROK",
  "Asia/Taipei", // Taiwan
  "ROC",
  "Asia/Kabul", // Afghanistan
  "Asia/Dhaka", // Bangladesh
  "Asia/Dacca",
  "Asia/Thimphu", // Bhutan
  "Asia/Thimbu",
  "Indian/Chagos", // British Indian Ocean Territory
  "Asia/Kolkata", // India
  "Asia/Calcutta",
  "Indian/Maldives",
  "Indian/Kerguelen",
  "Asia/Kathmandu", // Nepal
  "Asia/Katmandu",
  "Asia/Karachi", // Pakistan
  "Asia/Colombo", // Sri Lanka
  "Asia/Brunei",
  "Asia/Phnom_Penh", // Cambodia

  // Indonesia
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Ujung_Pandang",
  "Asia/Jayapura",

  "Asia/Vientiane", // Laos

  // Malaysia
  "Asia/Kuching",
  "Asia/Kuala_Lumpur",

  // Myanmar
  "Asia/Rangoon",
  "Asia/Yangon",

  "Asia/Manila", // Philippines
  "Asia/Singapore", // Singapore
  "Singapore",
  "Asia/Bangkok", // Thailand
  "Asia/Dili", // Timor-Leste
  "Asia/Ho_Chi_Minh", // Vietnam
  "Asia/Saigon",

  // Kazakstan
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Oral",

  "Asia/Bishkek", // Kyrgyzstan
  "Asia/Dushanbe", // Tajikistan
  "Asia/Ashgabat", // Turkmenistan
  "Asia/Ashkhabad",

  // Uzbekistan
  "Asia/Samarkand",
  "Asia/Tashkent",

  "Asia/Bahrain", // Bahrain
  "Asia/Tehran", // Iran
  "Iran",
  "Asia/Baghdad", // Iraq
  "Asia/Jerusalem", // Israel
  "Asia/Tel_Aviv",
  "Israel",
  "Asia/Amman", // Jordan
  "Asia/Kuwait", // Kuwait
  "Asia/Beirut", // Lebanon
  "Asia/Muscat", // Oman
  "Asia/Qatar", // Qatar
  "Asia/Riyadh", // Saudi Arabia
  "Antarctica/Syowa",
  "Asia/Damascus", // Syria
  "Asia/Dubai", // United Arab Emirates
  "Asia/Muscat",
  "Indian/Mahe",
  "Indian/Reunion",
  "Asia/Aden", // Yemen

  "US/Hawaii",
];

const oceaniaTimezones: string[] = [
  "Pacific/Enderbury",
  "US/Samoa",
  "Pacific/Kosrae",
  "Pacific/Auckland",
  "Antarctica/McMurdo",
  "Antarctica/South_Pole",
  "NZ",
  "Pacific/Chatham",
  "NZ-CHAT",
  "Pacific/Fiji", // Fiji
  "Pacific/Noumea", // New Caledonia
  "Pacific/Port_Moresby", // Papua New Guinea
  "Pacific/Truk",
  "Pacific/Yap",
  "Pacific/Bougainville", // Bougainville
  "Pacific/Guadalcanal", // Solomon Islands
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Efate", // Vanuatu
  "Pacific/Guam", // Guam
  "Pacific/Tarawa", // Kiribati
  "Pacific/Majuro",
  "Pacific/Kwajalein", // Marshall Islands
  "Kwajalein",
  "Pacific/Kiritimati", // Federated States of Micronesia
  "Pacific/Nauru", // Nauru
  "Pacific/Saipan", // Northern Mariana Islands
  "Pacific/Palau", // Palau
  "Pacific/Wake", // Wake Island
  "Pacific/Pago_Pago", // American Samoa
  "Pacific/Samoa",
  "Pacific/Rarotonga", // Cook Islands
  "Pacific/Easter", // Easter Island
  "Chile/EasterIsland",
  "Pacific/Tahiti", // French Polynesia
  "Pacific/Honolulu", // Hawaii
  "Pacific/Johnston",
  "Pacific/Niue", // Niue
  "Pacific/Pitcairn", // Pitcairn Islands
  "Pacific/Midway", // Samoa
  "Pacific/Fakaofo", // Tokelau
  "Pacific/Tongatapu", // Tonga
  "Pacific/Funafuti", // Tuvalu
  "Pacific/Wallis", // Wallis and Futuna
  "Australia/Lord_Howe",
  "Australia/LHI",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Tasmania",
  "Australia/Melbourne",
  "Australia/Victoria",
  "Australia/Sydney",
  "Australia/ACT",
  "Australia/Canberra",
  "Australia/NSW",
  "Australia/Broken_Hill",
  "Australia/Yancowinna",
  "Australia/Brisbane",
  "Australia/Queensland",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/South",
  "Australia/Darwin",
  "Australia/North",
  "Australia/Perth",
  "Australia/West",
  "Australia/Eucla",
  "Indian/Christmas", // Christmas Island
  "Indian/Cocos", // Cocos Island
  "Pacific/Norfolk", // Norfolk Island
];

export enum Version {
  Global = "global",
  Asia = "asia",
  Oceania = "oceania",
}

interface Domains {
  timezones: string[] | null;
  name: (t: TFunction) => string;
  url: string;
}

const domains: Record<Version, Domains> = {
  [Version.Asia]: {
    timezones: asiaTimezones,
    name: (t: TFunction) => t("servers.china"),
    url: process.env.REACT_APP_ASIA_URL ?? "https://pocketpilot-cn.jura.com",
  },
  [Version.Oceania]: {
    timezones: oceaniaTimezones,
    name: (t: TFunction) => t("servers.oceania"),
    url: process.env.REACT_APP_OCEANIA_URL ?? "https://pocketpilot-oc.jura.com",
  },
  [Version.Global]: {
    timezones: null,
    name: (t: TFunction) => t("servers.global"),
    url: process.env.REACT_APP_OCEANIA_URL ?? "https://pocketpilot.jura.com",
  },
};

// get the timezone and the hostname.

const domain = (url: string) => {
  return url.replace(/^https?:\/\//, "");
};

export const getCurrentVersion = () => {
  const host = window.location.host;
  return (
    (Object.entries(domains).find(([, { url }]) =>
      host.includes(domain(url))
    )?.[0] as Version | undefined) ?? Version.Global
  );
};

export const getRecommendedVersion = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    (Object.entries(domains).find(([, { timezones }]) =>
      timezones?.includes(timezone)
    )?.[0] as Version | undefined) ?? Version.Global
  );
};

export const needsRedirect = () => {
  return getCurrentVersion() !== getRecommendedVersion();
};

export const redirectToVersion = (version: Version) => {
  const currentVersion = getCurrentVersion();

  if (currentVersion === version) return;

  console.debug(`redirecting to ${version} version`);
  const targetUrl = domains[version].url;
  window.location.replace(`${targetUrl}${window.location.pathname}`);
};

export const redirectByTimezone = () => {
  if (!needsRedirect()) return;
  redirectToVersion(getRecommendedVersion());
};

import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TextInput from "../../../components/form/TextInput";
import { KlarnaPayment } from "../../../state/payments";

const KlarnaFields = ({ values }: { values: Omit<KlarnaPayment, "id"> }) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert
        severity="info"
        sx={{
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.klarna.infoBoxTitle")}</AlertTitle>
        <ReactMarkdown linkTarget="_blank">
          {t("setup.payment.klarna.infoBox", {
            joinArrays: "  \n",
          })}
        </ReactMarkdown>
      </Alert>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.klarna.generalTitle")}
        </Typography>
        <TextInput
          key="options.gatewayUrl"
          name="options.gatewayUrl"
          type="text"
          title={t("setup.payment.klarna.gatewayUrl") + ":"}
        />
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.klarna.keysTitle")}
        </Typography>
        <TextInput
          key="options.username"
          name="options.username"
          type="text"
          title={t("setup.payment.klarna.username") + ":"}
        />
        <TextInput
          key="options.password"
          name="options.password"
          type="password"
          title={t("setup.payment.klarna.password") + ":"}
        />
      </Paper>
    </>
  );
};

export default KlarnaFields;

import { ReactElement, JSXElementConstructor } from "react";
import { BaseSchema } from "yup";
import { AnyObject } from "yup/lib/types";
import { PaymentProvider } from "./interface";
import { requiredName } from "../../../utils/i18n";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { OptionalObjectSchema, ObjectShape, TypeOfShape } from "yup/lib/object";
import StripeFields from "../../../page/setup/PaymentFields/StripeFields";
import { StripePaymentOptions } from "..";

export default class StripeProvider implements PaymentProvider {
  type = "stripe";

  options = ["apiKey", "clientKey", "webhookSecret"] as Array<
    keyof StripePaymentOptions
  >;

  getOptionSchema(
    schema: OptionalObjectSchema<
      ObjectShape,
      AnyObject,
      TypeOfShape<ObjectShape>
    >,
    t: TFunction
  ): BaseSchema<any, AnyObject, any> {
    return schema.shape({
      apiKey: Yup.string().required(
        requiredName(t, "setup.payment.stripe.apiKey")
      ),
      clientKey: Yup.string().required(
        requiredName(t, "setup.payment.stripe.clientKey")
      ),
      webhookSecret: Yup.string().required(
        requiredName(t, "setup.payment.stripe.webhookSecret")
      ),
    });
  }

  fields(props: any): ReactElement<any, string | JSXElementConstructor<any>> {
    return StripeFields(props);
  }
}

import { useTranslation } from "react-i18next";
import { useExportUserConsumptionMutation } from "../state/services/api";
import { ConsumptionFilter, UserFilter } from "../state/users";
import { ExportType, useBlobExport } from "./export";

export function useUsersConsumptionExport(
  type: ExportType,
  filter: UserFilter & ConsumptionFilter
) {
  const [exportUsers] = useExportUserConsumptionMutation();
  const { i18n } = useTranslation();

  return useBlobExport(
    () =>
      exportUsers({
        type: type,
        filter,
        language: i18n.language,
      }).unwrap(),
    type,
    "UserConsumption"
  );
}

import {
  CircularProgress,
  Button,
  Box,
  capitalize,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { memo, useCallback, useMemo } from "react";
import {
  Product,
  productName,
  ProductSetting,
  ProductSettingType,
} from "../../../../state/products";
import {
  createSelectProductSelector,
  useDeleteCoffeeMenuProductMutation,
  useEditCoffeeMenuProductMutation,
  useGetCoffeeMenuProductsQuery,
  useGetCoffeeMenuQuery,
} from "../../../../state/services/api";

import { FieldArray, Form, Formik } from "formik";
import { DeleteForever, Save } from "@mui/icons-material";
import TextInput from "../../../../components/form/TextInput";

import * as Yup from "yup";
import { useEntityDialog } from "../../../../hooks/dialog";
import EntityDialog from "../../../../components/EntityDialog";
import Slider from "../../../../components/form/Slider";
import Checkbox from "../../../../components/form/Checkbox";
import DateTimePicker from "../../../../components/form/DateTimePicker";
import { createMinAmountValidator } from "../../../../utils/formik";
import { CoffeeMenu, Mode } from "../../../../state/coffee-menus";
import MultiSelect from "../../../../components/form/MultiSelect";
import DeleteDialog from "../../../../components/DeleteDialog";
import { formatMoney } from "../../../../utils/money";
import { ellipsisOverflow } from "../../../../utils/string";
import { useAlert } from "../../../../components/alerts/AlertContext";

const DELETE_PRODUCT_NAME = "coffeeMenu.delete.product";

interface MatchParams {
  menuId: string;
  productId: string;
}

export const EDIT_PRODUCT_DIALOG_NAME = "coffeeMenu.edit.product";

const Field = ({
  setting,
  index,
}: {
  setting: ProductSetting;
  index: number;
}) => {
  const { t } = useTranslation();
  const { t: mt } = useTranslation("machine");

  switch (setting.configuration.type) {
    case ProductSettingType.Choice:
      const choices = setting.configuration.choices;

      let optionLabel = (choice: (typeof choices)[number], _: number) =>
        `${/^\d+$/.test(choice.name) ? choice.name + ": " : ""}${capitalize(
          mt(choice.text)
        )}`;

      // F3 = Coffee strength
      // Special case due to special translations of names
      if (setting.name === "93") {
        optionLabel = (_, i: number) => {
          const offset = 11 - choices.length;
          return t(`product.strength.strength${i + offset}`);
        };
      }

      return (
        <MultiSelect
          title={mt(setting.name)}
          name={`settings.${index}.value`}
          options={choices.map((choice, i) => ({
            label: optionLabel(choice, i),
            value: choice.value,
          }))}
        />
      );
    case ProductSettingType.Progress:
      return (
        <Slider
          title={mt(setting.name)}
          name={`settings.${index}.value`}
          min={setting.configuration.min}
          max={setting.configuration.max}
          step={setting.configuration.step}
          unit={setting.unit ? mt(setting.unit) : ""}
          marks
          valueLabelDisplay="on"
        />
      );
    case ProductSettingType.Checkbox:
      return (
        <Checkbox name={`settings.${index}.value`} value={setting.id}>
          {mt(setting.name)}
        </Checkbox>
      );
    default:
      return <></>;
  }
};

const ProductDialog = memo(
  ({
    product,
    menu,
    handleSubmit,
    isSaving,
    values,
  }: {
    product?: Product;
    menu?: CoffeeMenu;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    isSaving: boolean;
    values: { free: boolean; price?: number; settings: ProductSetting[] };
  }) => {
    const { t } = useTranslation();
    const { t: mt } = useTranslation("machine");

    const { close } = useEntityDialog({
      name: EDIT_PRODUCT_DIALOG_NAME,
    });

    const { edit: deleteProduct } = useEntityDialog({
      name: DELETE_PRODUCT_NAME,
    });

    return (
      <EntityDialog
        name={EDIT_PRODUCT_DIALOG_NAME}
        title={product ? mt(product.name) : ""}
        subTitle={t("product.subTitle")}
        removeButton={
          product && (
            <Button
              color="error"
              variant="contained"
              startIcon={<DeleteForever />}
              onClick={() => {
                close();
                deleteProduct(product?.id)();
              }}
            >
              {isSaving ? (
                <CircularProgress size={20} color="white" />
              ) : (
                capitalize(t("delete"))
              )}
            </Button>
          )
        }
        okButton={
          <Button
            variant="contained"
            startIcon={<Save />}
            onClick={() => handleSubmit()}
          >
            {isSaving ? (
              <CircularProgress size={20} color="white" />
            ) : (
              capitalize(t("save"))
            )}
          </Button>
        }
      >
        <Box sx={{ mb: 4 }} maxWidth="40rem">
          <Form>
            {menu?.mode !== Mode.Open && (
              <Checkbox name="free">{t("product.free")}</Checkbox>
            )}
            {menu?.mode !== Mode.Open && (
              <>
                {!values.free && (
                  <Box>
                    <Typography fontWeight="bold" fontSize="12px" mb={1}>
                      {t("product.price")}:
                    </Typography>
                    {formatMoney(
                      values.price ?? 0,
                      menu?.company.currency ?? "CHF"
                    )}
                  </Box>
                )}
                <Stack direction="row" rowGap={1}>
                  {!values.free && (
                    <TextInput
                      name="priceUpdate"
                      type="number"
                      title={t("product.priceUpdate") + ":"}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: menu && (
                          <InputAdornment position="start">
                            {menu.company.currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <DateTimePicker
                    name="priceUpdateTime"
                    title={t("product.priceUpdateTime") + ":"}
                  />
                </Stack>
              </>
            )}
            <TextInput name="name" title={t("product.name") + ":"} />
            <FieldArray
              name="settings"
              render={() =>
                values?.settings?.map((setting, index) => (
                  <Field key={setting.id} setting={setting} index={index} />
                ))
              }
            />
          </Form>
        </Box>
      </EntityDialog>
    );
  }
);

const settingToInternal = (setting: ProductSetting) =>
  setting.configuration.type === "checkbox"
    ? { ...setting, value: setting.value ? [setting.id] : [] }
    : setting;

const settingToExternal = (setting: ProductSetting) =>
  // TODO: Boolean instead of number!
  setting.configuration.type === "checkbox"
    ? {
        ...setting,
        value: Number((setting.value as string[])?.length ?? null),
      }
    : setting;

const EditProductDialog = () => {
  const { t } = useTranslation();
  const { t: mt } = useTranslation("machine");

  const { menuId } = useParams<MatchParams>();
  const alert = useAlert();

  const [editCoffeeMachineProductById, { isLoading: isSaving }] =
    useEditCoffeeMenuProductMutation();

  useGetCoffeeMenuProductsQuery(menuId);

  const selectProduct = createSelectProductSelector(menuId);

  const { data: menu } = useGetCoffeeMenuQuery(menuId);

  const { close, entity: product } = useEntityDialog({
    name: EDIT_PRODUCT_DIALOG_NAME,
    selector: selectProduct,
  });

  const settings = useMemo(
    () =>
      [...(product?.settings?.map(settingToInternal) ?? [])]?.sort((a, b) =>
        a.configuration.type.localeCompare(b.configuration.type)
      ),
    [product?.settings]
  );

  const initialValues = {
    ...product,
    name: productName(mt, product),
    free:
      typeof product?.priceUpdate === "number"
        ? product?.priceUpdate === 0
        : product?.price === 0,
    settings,
    priceUpdate: product?.priceUpdate ?? "",
    priceUpdateTime: product?.priceUpdateTime ?? undefined,
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (product) {
        let priceUpdate =
          typeof values.priceUpdate !== "string"
            ? values.priceUpdate
            : undefined;

        if (values.free) {
          priceUpdate = 0;
        }

        try {
          await editCoffeeMachineProductById({
            ...product,
            settings: values?.settings?.map(settingToExternal),
            priceUpdate,
            priceUpdateTime: values.priceUpdateTime,
            customName:
              mt(product.name) === values.name ? null : values.name ?? null,
          }).unwrap();

          alert({
            severity: "success",
            content: t("product.saveSuccess"),
            autoHideDuration: 3000,
          });
        } catch (e) {
          alert({
            severity: "error",
            content: t("product.savefailure"),
            autoHideDuration: 3000,
          });
        }

        close();
      }
    },
    [product, close, editCoffeeMachineProductById, mt, alert, t]
  );

  const priceUpdateValidator = Yup.number()
    .max(99_999_999)
    .optional()
    .test(
      createMinAmountValidator(
        t,
        menu?.company?.currency,
        menu?.mode === Mode.Payment ? undefined : 0
      )
    );

  let EditProductSchema = Yup.object().shape({
    name: Yup.string().max(255, t("validation.maxLength")).required(),
    free: Yup.boolean(),
    priceUpdateTime: Yup.date().optional(),
    priceUpdate: priceUpdateValidator,
  });

  const [deleteProductById, { isLoading: isDeleting }] =
    useDeleteCoffeeMenuProductMutation();

  return (
    <>
      <DeleteDialog
        name={DELETE_PRODUCT_NAME}
        entityName={"product.self"}
        displayName={(entity: Product) =>
          ellipsisOverflow(productName(mt, entity), 35) ?? ""
        }
        onDelete={(id: string) =>
          deleteProductById({ menuId: menuId, productId: id }).unwrap()
        }
        busy={isDeleting}
        selector={selectProduct}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={EditProductSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <ProductDialog
            product={product}
            menu={menu}
            handleSubmit={handleSubmit}
            isSaving={isSaving}
            values={values}
          />
        )}
      </Formik>
    </>
  );
};

export default EditProductDialog;

import {
  Box,
  capitalize,
  Checkbox,
  CircularProgress,
  MenuItem,
  MenuProps,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps as MuiSelectProps,
  styled,
} from "@mui/material";
import { Option } from "../utils/types";
import { ExpandMore } from "@mui/icons-material";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export const ALL_OPTION = "__all";

type SingleValue<O extends Option> = O["value"];
type ValueArray<O extends Option> = SingleValue<O>[];
export type Value<O extends Option> = SingleValue<O> | SingleValue<O>[];

export type SelectProps<T extends Option, M extends boolean = false> = Omit<
  MuiSelectProps,
  "renderValue" | "onChange"
> & {
  id?: string;
  name: string;
  menuProps?: MenuProps;
  multiple?: M;
  options?: T[];
  loading?: boolean;
  includeAllOption?: boolean;
  exposeAllOption?: boolean;
  value?: Value<T>;
  onChange?: (value: Value<T>) => void;
  renderValue?: (selectedOptions: M extends true ? T[] : T | "") => ReactNode;
};

const Select = styled(MuiSelect)`
  .MuiMenuItem-root {
    padding: 0;

    > * {
      padding: ${({ theme }) => theme.spacing(0.75)}
        ${({ theme }) => theme.spacing(2)};
    }
  }
`;

const LoadingSpinner = () => <CircularProgress color="inherit" size={20} />;

const MultiSelect = <T extends Option, M extends boolean>({
  id,
  name,
  menuProps,
  children,
  options,
  loading,
  onChange,
  multiple,
  includeAllOption,
  renderValue,
  value,
  exposeAllOption,
  ...props
}: SelectProps<T, M>) => {
  const selectableValues = options?.map(({ value }) => value as string) ?? [];
  const originalValue = value;

  const isEverythingSelected = selectableValues.every((value) =>
    Array.isArray(originalValue)
      ? originalValue.includes(value)
      : originalValue === value
  );

  const prepareNewValue = () => {
    if (multiple && includeAllOption) {
      const allOptions = [ALL_OPTION];
      const selectedValues = isEverythingSelected
        ? allOptions
        : value ?? ([] as ValueArray<T>);
      return selectedValues;
    }
    return originalValue ?? "";
  };

  const [internalValue, setInternalValue] = useState<Value<T>>(prepareNewValue());

  const { t } = useTranslation();
  const initSkipped = useRef(false);

  useEffect(() => {
    if (initSkipped.current) {
      setInternalValue(prepareNewValue());
    }
    initSkipped.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (includeAllOption) {
    const allOption = {
      value: ALL_OPTION,
      label: capitalize(t("all")),
    } as unknown as T;
    options = [allOption].concat(options ?? []);
  }

  const getSelectedValues = (internal: typeof internalValue) => {
    if (!includeAllOption || !multiple || !Array.isArray(internal)) {
      return internal;
    }

    if (exposeAllOption) {
      return internal;
    } else {
      return internal.includes(ALL_OPTION)
        ? options
            ?.map(({ value }) => value)
            .filter((value) => value !== ALL_OPTION)
        : internal;
    }
  };

  const actualOnChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
    const newValues = e.target.value as ValueArray<T>;
    if (multiple && includeAllOption) {
      const values = internalValue as ValueArray<T>;
      const hadAll = values.includes(ALL_OPTION);
      const nowHasAll = newValues.includes(ALL_OPTION);
      const nowHasAllAndMore = nowHasAll && newValues.length > 1;

      let nextValues = newValues;
      if (nowHasAll) {
        nextValues = [ALL_OPTION];
      }
      if (nowHasAllAndMore && hadAll) {
        nextValues = selectableValues.filter((v) => !newValues.includes(v));
      }

      onChange?.(getSelectedValues(nextValues));
    } else {
      onChange?.(getSelectedValues(newValues));
    }
  };

  const renderValueInternal = () => {
    if (multiple && Array.isArray(internalValue)) {
      const render = renderValue as (options: T[]) => ReactNode;
      const selectedOptions =
        options?.filter((option) =>
          (internalValue as ValueArray<T>).includes(option.value)
        ) ?? [];
      return render?.(selectedOptions);
    } else {
      const render = renderValue as (option: T | "") => ReactNode;
      const selectedOption =
        options?.find((option) => option.value === internalValue) ?? "";
      return render?.(selectedOption);
    }
  };

  /*
  const isChecked = useCallback((option: Option) => {
    if (Array.isArray(internalValue)) {
      return (
        internalValue.includes("__all") ||
        internalValue.includes(option.value as string)
      );
    }
    return internalValue === option.value;
  }, [internalValue]);
  */

  return (
    <Select
      id={id ?? name}
      name={name}
      labelId={`${name}-label`}
      multiple={multiple}
      value={internalValue}
      onChange={actualOnChange}
      renderValue={renderValue && renderValueInternal}
      displayEmpty
      MenuProps={{
        MenuListProps: {
          sx: {
            "> .MuiMenuItem-root": {
              padding: 0,
              "> *": {
                width: "100%",
                height: "100%",
                px: 2,
                py: 0.75,
              },
            },
          },
        },
        BackdropProps: {
          invisible: true,
        },
        ...menuProps,
      }}
      IconComponent={loading ? LoadingSpinner : ExpandMore}
      {...props}
    >
      {options
        ? options.map((option) => (
            <MenuItem
              value={option.value}
              key={option.value ?? "__default"}
              disabled={!!option.disabled}
            >
              {multiple && (
                <Checkbox
                  sx={{ flex: 0 }}
                  indeterminate={
                    option.value === ALL_OPTION && Array.isArray(internalValue)
                      ? !internalValue.includes(ALL_OPTION) && !!internalValue.length
                      : false
                  }
                  checked={
                    Array.isArray(internalValue)
                      ? internalValue.includes(ALL_OPTION) ||
                        internalValue.includes(option.value as string)
                      : internalValue === option.value
                  }
                />
              )}
              <Box>{option.label}</Box>
            </MenuItem>
          ))
        : children}
    </Select>
  );
};

export default MultiSelect;

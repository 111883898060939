import { Box, CircularProgress, styled } from "@mui/material";

const LoadingPage = styled(Box)`
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
`;

const Loading = () => (
  <LoadingPage>
    <CircularProgress size={80} thickness={5} />
  </LoadingPage>
)

export default Loading;
import { DeleteForever, Save } from "@mui/icons-material";
import { string, object } from "yup";
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Typography,
  ButtonGroup,
  Stack,
} from "@mui/material";
import { NavButton } from "../../components/NavButton";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ref as yupRef } from "yup";
import { TemporaryAlert } from "../../components/Alert";
import TextInput from "../../components/form/TextInput";
import { useExtendedUserEdit } from "../../hooks/user";
import { logout, selectCurrentUser } from "../../state/auth";
import { useDispatch, useSelector } from "../../state/hooks";
import {
  useDeleteUserMutation,
  useSetPasswordMutation,
} from "../../state/services/api";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
import { User } from "../../state/users";
import { useEntityDialog } from "../../hooks/dialog";
import { reset } from "../../state/store";
import keycloak from "../../keycloak";
import MultiSelect from "../../components/form/MultiSelect";
import { useLanguageOptions } from "../../hooks/language";
import { TFunction } from "i18next";

const EditProfileSchema = Yup.object().shape({
  preferredLanguage: Yup.string().required(),
});

const GeneralTab = () => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);

  const initialValues = {
    id: user?.id,
    preferredLanguage: user?.preferredLanguage ?? "en",
  };

  const { options: languages, isLoading: isLoadingLanguages } =
    useLanguageOptions(initialValues.preferredLanguage);

  const [editUser, { isLoading: isSaving, isSuccess, isError }] =
    useExtendedUserEdit();

  const [editUserTrackingless] = useExtendedUserEdit();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (user?.id) {
        await editUser({
          id: user.id,
          body: values,
        });
      }
    },
    [user?.id, editUser]
  );

  const repeatTutorial = useCallback(async () => {
    if (user) {
      await editUserTrackingless({
        id: user.id,
        body: { tutorialCompleted: false },
      });
    }
  }, [editUserTrackingless, user]);

  let formElements;
  if (!user?.isKeycloakUser) {
    formElements = (
      <>
        <TextInput name="firstName" title={t("auth.signup.firstName") + ":"} />
        <TextInput name="lastName" title={t("auth.signup.lastName") + ":"} />
        <TextInput name="email" title={t("auth.signup.email") + ":"} />
        <TextInput
          name="address"
          title={t("auth.signup.address") + ":"}
          rows={4}
          multiline
        />
      </>
    );
  } else {
    formElements = (
      <Button
        variant="contained"
        sx={{ mt: 4, width: "auto" }}
        onClick={() => keycloak.accountManagement()}
      >
        {t("auth.login.manageKeycloak")}
      </Button>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditProfileSchema}
      enableReinitialize={true}
      onSubmit={submit}
    >
      <Form>
        <Button
          variant="contained"
          sx={{ mt: 4, width: "auto", display: "block" }}
          onClick={repeatTutorial}
        >
          {t("profile.repeatTutorial")}
        </Button>

        <Stack direction="row" columnGap={2} justifyContent="flex-start" mt={4}>
          <Link to="/legal/terms" target="_blank">
            <Button variant="contained" sx={{ width: "auto" }}>
              {capitalize(t("profile.showTerms"))}
            </Button>
          </Link>

          <Link to="/legal/privacy" target="_blank">
            <Button variant="contained" sx={{ width: "auto" }}>
              {capitalize(t("profile.showPrivacyPolicy"))}
            </Button>
          </Link>
        </Stack>
        {formElements}
        <MultiSelect
          name="preferredLanguage"
          options={languages}
          title={capitalize(t("auth.signup.preferredLanguage")) + ":"}
          loading={isLoadingLanguages}
        />
        <Button
          type="submit"
          variant="contained"
          startIcon={<Save />}
          sx={{ mt: 2, width: "auto" }}
        >
          {isSaving ? <CircularProgress size={20} color="white" /> : t("save")}
        </Button>

        <TemporaryAlert open={isError} severity="error">
          {t("profile.saveFailure")}
        </TemporaryAlert>
        <TemporaryAlert open={isSuccess}>
          {t("profile.saveSuccess")}
        </TemporaryAlert>
      </Form>
    </Formik>
  );
};

const changePasswordSchema = (t: TFunction) =>
  object().shape({
    // The password requirements should not be too high, but must at least follow the requirements
    // of Windows 10 (6 characters, upper and lower case, special characters).
    password: string()
      .required(t("required", { field: "password" }))
      .min(6, t("auth.signup.passwordLength"))
      .matches(/[a-z]/, t("auth.signup.lowercaseMismatch"))
      .matches(/[A-Z]/, t("auth.signup.uppercaseMismatch"))
      .matches(/[^A-Za-z0-9\s]/, t("auth.signup.specialCharacterMismatch")),
    confirmPassword: string()
      .required(t("auth.signup.passwordConfirmationRequired"))
      .oneOf([yupRef("password"), null], t("auth.signup.passwordMismatch")),
  });

const PasswordTab = () => {
  const { t } = useTranslation();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const [setPassword, { isLoading: isSaving, isError, isSuccess }] =
    useSetPasswordMutation();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      await setPassword({
        password: values.password,
      }).unwrap();
    },
    [setPassword]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePasswordSchema(t)}
      onSubmit={submit}
    >
      <Form>
        <TextInput
          name="password"
          type="password"
          helperText={t("auth.signup.passwordHelpText")}
          title={t("auth.signup.newPassword") + ":"}
        />
        <TextInput
          name="confirmPassword"
          type="password"
          title={t("auth.signup.confirmNewPassword") + ":"}
        />
        <Button
          type="submit"
          variant="contained"
          startIcon={<Save />}
          sx={{ mt: 2, width: "auto" }}
        >
          {isSaving ? <CircularProgress size={20} color="white" /> : t("save")}
        </Button>
        <TemporaryAlert open={isError} severity="error">
          {t("profile.savePasswordFailure")}
        </TemporaryAlert>
        <TemporaryAlert open={isSuccess}>
          {t("profile.savePasswordSuccess")}
        </TemporaryAlert>
      </Form>
    </Formik>
  );
};

const UserProfile = ({ showBackButton = false }) => {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);

  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const DELETE_USER_NAME = "user.delete";

  const { edit: openDeleteUserDialog, entity } = useEntityDialog({
    name: DELETE_USER_NAME,
    selector: (_) => selectCurrentUser,
  });

  const dispatch = useDispatch();

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Typography variant="h1">{t("profile.title")}</Typography>
          <Typography paragraph>{t("profile.subTitle")}</Typography>
        </Box>

        <DeleteDialog
          errorMessage={(e) => {
            if (e.data.error === "user.deleteUser.notFound")
              return t("profile.deleteErrorNotFound");
            if (e.data.error === "user.deleteUser.isCMOwner")
              return t("profile.deleteErrorCMOwner");
            if (e.data.error === "user.deleteUser.isCompanyOwner")
              return t("profile.deleteErrorCompanyOwner");
            return t("profile.deleteError");
          }}
          name={DELETE_USER_NAME}
          key={`del-2`}
          displayName={(entity: User) => entity.email ?? ""}
          entityName={"user"}
          onDelete={async (_) => {
            await deleteUser().unwrap();
            dispatch(logout());
            dispatch(reset());
          }}
          busy={isDeleting}
          selector={() => selectCurrentUser}
        />

        <Button
          variant="contained"
          startIcon={<DeleteForever />}
          sx={{ width: "auto", px: 4, fontWeight: "bold" }}
          color="error"
          onClick={entity && openDeleteUserDialog(entity.id)}
        >
          {isDeleting ? (
            <CircularProgress size={20} color="white" />
          ) : (
            capitalize(t("delete"))
          )}
        </Button>
      </Stack>
      <ButtonGroup>
        {showBackButton && (
          <NavButton to="/" sx={{ marginRight: "5px" }}>
            {t("back")}
          </NavButton>
        )}
        <NavButton to="/profile/general">{t("profile.tabs.general")}</NavButton>
        {!user?.isKeycloakUser && (
          <NavButton to="/profile/password">
            {t("profile.tabs.password")}
          </NavButton>
        )}
      </ButtonGroup>
      <Switch>
        <Route path="/profile/general">
          <GeneralTab />
        </Route>
        {!user?.isKeycloakUser && (
          <Route path="/profile/password">
            <PasswordTab />
          </Route>
        )}
        <Route path="/profile">
          <Redirect from="/profile" to="/profile/general" />
        </Route>
      </Switch>
    </Box>
  );
};

export default UserProfile;

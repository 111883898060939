import { Button, ButtonProps } from "@mui/material";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useAuthorized } from "../hooks/auth";
import { Role } from "../state/users";

type NavButtonProps = ButtonProps & NavLinkProps & {
  onlyRoles?: Role[] | Role
}

export const NavButton = ({ onlyRoles, ...props }: NavButtonProps) => {
  const authorized = useAuthorized(onlyRoles);
  if (!authorized) return null;
  return <Button component={NavLink} {...props} />
};

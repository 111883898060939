import { Cancel } from "@mui/icons-material";
import { Snackbar, Alert, AlertTitle, capitalize } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert as AlertType,
  AlertContext,
  AlertContextContent,
} from "./AlertContext";

interface ContextState {
  alerts: AlertType[];
}

function AlertProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation();

  const [contextState, setContextState] = useState<ContextState>({
    alerts: [],
  });

  const alertContextContent: AlertContextContent = useMemo(
    () => ({
      alert: (alert) => {
        setContextState((prev) => ({ alerts: prev.alerts.concat(alert) }));
      },
    }),
    []
  );

  const handleClose = useCallback((alert: AlertType, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setContextState((prev) => ({
      alerts: prev.alerts.filter((existingAlert) => existingAlert !== alert),
    }));
  }, []);

  const title = useCallback(
    (alert: AlertType) =>
      ({
        error: t("error"),
        warning: t("warning"),
        info: t("headsUp"),
        success: t("wellDone"),
      }[alert.severity ?? "success"]),
    [t]
  );

  return (
    <AlertContext.Provider value={alertContextContent}>
      {contextState.alerts.map((alert, i) => (
        <Snackbar
          key={i}
          open={true}
          onClose={(_, reason) => handleClose(alert, reason)}
          autoHideDuration={alert.autoHideDuration}
        >
          <Alert
            sx={{ width: "100%" }}
            icon={false}
            variant="filled"
            severity={alert.severity}
            action={
              <Cancel
                sx={{
                  color: alert.severity === "error" ? "white!important" : null,
                }}
                onClick={() => handleClose(alert, "close")}
              />
            }
          >
            {
              <AlertTitle sx={{ display: "inline-block", mr: 1 }}>
                {capitalize(title(alert))}!
              </AlertTitle>
            }
            {alert.content}
          </Alert>
        </Snackbar>
      ))}
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;

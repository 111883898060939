import { ButtonGroup, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../components/AuthorizedRoute";
import { NavButton } from "../../components/NavButton";
import { Role } from "../../state/users";
import UserConsumption from "./UserConsumption";
import UserGroups from "./UserGroups";
import UserManagement from "./UserManagement";
import UserVerification from "./UserVerification";

const Setup = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">{t("usermanagement.title")}</Typography>
        <Typography paragraph>{t("usermanagement.subTitle")}</Typography>

        <ButtonGroup
          sx={{
            flexFlow: "row wrap",
            "> *": {
              width: "min-content",
              minWidth: "100px!important",
              whiteSpace: "nowrap",
            },
          }}
        >
          <NavButton
            to="/user-management"
            exact={true}
            onlyRoles={[Role.Admin, Role.UserManager]}
          >
            {t("usermanagement.tabs.users")}
          </NavButton>
          <NavButton
            to="/user-management/consumption"
            onlyRoles={[Role.Admin, Role.UserManager]}
          >
            {t("usermanagement.tabs.consumption")}
          </NavButton>
          <NavButton to="/user-management/user-groups" onlyRoles={[Role.Admin]}>
            {t("usermanagement.tabs.userGroups")}
          </NavButton>
          <NavButton
            to="/user-management/verification"
            onlyRoles={[Role.Admin, Role.UserManager]}
          >
            {t("usermanagement.tabs.verification")}
          </NavButton>
        </ButtonGroup>

      </Grid>

      <Switch>
        <AuthorizedRoute exact={true} path="/user-management" only={[Role.Admin, Role.UserManager]}>
          <UserManagement />
        </AuthorizedRoute>
        <AuthorizedRoute path="/user-management/consumption" only={[Role.Admin, Role.UserManager]}>
          <UserConsumption />
        </AuthorizedRoute>
        <AuthorizedRoute path="/user-management/user-groups" only={[Role.Admin]}>
          <UserGroups />
        </AuthorizedRoute>
        <AuthorizedRoute path="/user-management/verification" only={[Role.Admin, Role.UserManager]}>
          <UserVerification />
        </AuthorizedRoute>
      </Switch>
    </Grid>
  );
};

export default Setup;

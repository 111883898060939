import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TextInput from "../../../components/form/TextInput";
import { SaferpayPayment } from "../../../state/payments";

const SaferpayFields = ({
  values,
}: {
  values: Omit<SaferpayPayment, "id">;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert
        severity="info"
        sx={{
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.saferpay.infoBoxTitle")}</AlertTitle>
        <ReactMarkdown linkTarget="_blank">
          {t("setup.payment.saferpay.infoBox", {
            joinArrays: "  \n",
          })}
        </ReactMarkdown>
      </Alert>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.saferpay.generalTitle")}
        </Typography>
        <TextInput
          key="options.terminalId"
          name="options.terminalId"
          type="text"
          title={t("setup.payment.saferpay.terminalId") + ":"}
        />
        <TextInput
          key="options.customerId"
          name="options.customerId"
          type="text"
          title={t("setup.payment.saferpay.customerId") + ":"}
        />
        <TextInput
          key="options.gatewayUrl"
          name="options.gatewayUrl"
          type="text"
          title={t("setup.payment.saferpay.gatewayUrl") + ":"}
        />
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.saferpay.keysTitle")}
        </Typography>
        <TextInput
          key="options.username"
          name="options.username"
          type="text"
          title={t("setup.payment.saferpay.username") + ":"}
        />
        <TextInput
          key="options.password"
          name="options.password"
          type="password"
          title={t("setup.payment.saferpay.password") + ":"}
        />
      </Paper>
    </>
  );
};

export default SaferpayFields;

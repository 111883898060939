import { AlertColor } from "@mui/material";
import { createContext, ReactNode, useCallback, useContext } from "react";

export interface Alert {
  severity?: AlertColor;
  title?: string;
  content?: ReactNode;
  autoHideDuration?: number;
}

export interface AlertContextContent {
  alert: (alert: Alert) => void;
}

export const AlertContext = createContext<AlertContextContent>({
  alert() {
    throw new Error("Not implemented");
  }
});

export const useAlert = () => {
  const context = useContext(AlertContext);
  
  return useCallback((alert: Alert) => {
    context.alert(alert);
  }, [context]);
}
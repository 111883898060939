import createFilterSlice from "../utils/filterSlice";
import { RootState } from "../store";
import { Widget } from "../widgets/widgetTypes";

export interface OrderStatisticRow {
  code: string;
  name: string;
  amountOrdered: number;
  percentOfTotal: number;
}

export interface Statistic {
  orderStatistics?: OrderStatisticRow[];
  widgets?: Widget[];
}

const slice = createFilterSlice({
  name: "statistics",
  initialState: { filter: {locations: [], machines: []} },
  reducers: {
  },
})

export default slice.reducer;
export const selectFilter = (state: RootState) =>
  state.statistics.filter ?? {locations: [], machines: []};
export const { reset, setFilter } = slice.actions;

import { AddCircle, ChevronLeft, Save } from "@mui/icons-material";
import { TemporaryAlert } from "../../components/Alert";
import EditIcon from "@mui/icons-material/Edit";

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import { useEntityDialog } from "../../hooks/dialog";
import { EDIT_WIDGET_DIALOG_NAME } from "../../components/widget/WidgetDialog";

import WidgetGrid from "../../components/WidgetGrid";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useSaveWidgetOrderMutation } from "../../state/services/api";
import { selectWidgetIds } from "../../state/widgets";

const Widgets = () => {
  const { t } = useTranslation();

  const { open: openModal } = useEntityDialog({
    name: EDIT_WIDGET_DIALOG_NAME,
  });

  const [isEditDashboard, setEditDashboard] = useState(false);

  const [saveWidgetOrder, { isSuccess, isError, isLoading: isSaving }] =
    useSaveWidgetOrderMutation();

  const handleEditDashboard = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setEditDashboard(true);
    },
    [setEditDashboard]
  );

  const closeEditMode = () => {
    setEditDashboard(false);
  };

  const widgetIds = useSelector(selectWidgetIds);

  const handleSubmit = async () => {
    await saveWidgetOrder({
      widgetIds,
    }).unwrap();

    setEditDashboard(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={8} lg={5}>
              <Box sx={{ display: "flex", height: 40 }}>
                <Typography variant="h1" sx={{ display: "inline-block" }}>
                  {t("widgets.title")}
                </Typography>

                {!isEditDashboard && (
                  <Link
                    href={""}
                    sx={{
                      marginLeft: { xs: "auto", sm: 4 },
                      display: "inline-flex",
                      height: "100%",
                      alignItems: "center",
                      paddingBottom: "10px",
                    }}
                    onClick={handleEditDashboard}
                  >
                    <EditIcon sx={{ marginRight: 1 }} />
                    <u>{t("widgets.edit.link")}</u>
                  </Link>
                )}
              </Box>

              <Typography paragraph>
                {isEditDashboard
                  ? t("widgets.edit.subTitle")
                  : t("widgets.subTitle")}
              </Typography>
            </Grid>
            <Grid item xs sx={{ textAlign: { md: "right" } }}>
              {!isEditDashboard && (
                <Button
                  sx={{ px: 4, ml: "auto", width: { sm: "auto" } }}
                  startIcon={<AddCircle />}
                  onClick={openModal}
                >
                  {t("widget.addNewWidgetButton")}
                </Button>
              )}
              {isEditDashboard && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Button
                    sx={{ px: 13, mb: { xs: 4, sm: 0 }, width: { sm: "auto" } }}
                    fullWidth
                    startIcon={<ChevronLeft />}
                    onClick={closeEditMode}
                  >
                    {t("back")}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{ px: 13, ml: { xs: 0, sm: 4 } }}
                    startIcon={!isSaving && <Save />}
                    onClick={() => handleSubmit()}
                  >
                    {isSaving ? (
                      <CircularProgress size={20} color="white" />
                    ) : (
                      t("save")
                    )}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>

          <WidgetGrid editMode={isEditDashboard} />

          <TemporaryAlert open={isSuccess}>
            {t("widgets.edit.saveSuccess")}
          </TemporaryAlert>

          <TemporaryAlert open={isError} severity={"error"}>
            {t("widgets.edit.saveFailure")}
          </TemporaryAlert>
        </Grid>
      </Grid>
    </>
  );
};

export default Widgets;

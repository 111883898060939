import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Stack,
  capitalize,
  Divider,
  Alert,
  AlertTitle,
  Tooltip,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useHistory, useParams } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import {
  useDeleteCoffeeMachineMutation,
  useGetCoffeeMachineQuery,
  useGetLocationsQuery,
  useEditCoffeeMachineMutation,
  useGetCoffeeMenusQuery,
  useGetUserGroupsQuery,
  useSwitchCoffeeMachineModeMutation,
  selectCoffeeMachine,
} from "../../../state/services/api";
import { Form, Formik } from "formik";
import { Location } from "../../../state/locations";

import * as Yup from "yup";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "../../../utils/types";
import {
  Save,
  DeleteForever,
  CompareArrows,
  AddCircle,
  WarningAmber,
  InfoOutlined,
} from "@mui/icons-material";
import {
  getAll,
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../utils/api";
import DeleteDialog from "../../../components/DeleteDialog";
import { useEntityDialog } from "../../../hooks/dialog";
import { TemporaryAlert } from "../../../components/Alert";

import { useIsCoffeeMachineOwner } from "../../../hooks/user";
import TransferMachineModal, {
  TRANSFER_MACHINE_MODAL,
} from "./TransferMachine";
import { CoffeeMenu, Mode } from "../../../state/coffee-menus";
import AddCoffeeMenuDialog, {
  ADD_MENU_DIALOG_NAME,
} from "../../coffee-menus/AddCoffeeMenuDialog";
import { UserGroup } from "../../../state/user-groups";
import ReactMarkdown from "react-markdown";
import { PreselectMode } from "../../../state/coffe-machines";
import MultiSelect from "../../../components/form/MultiSelect";
import { mapKV } from "../../../utils/object";
import { amber, blue } from "@mui/material/colors";

interface MatchParams {
  machineId: string;
}

const EditMachineSchema = Yup.object().shape({
  location: Yup.string().required(),
  coffeeMenu: Yup.string(),
  coffeeMenus: Yup.object(),
});

const DELETE_COFFEE_MACHINE_DIALOG_NAME = "coffeeMachine.delete";

const createLocationOptions = (locations: Location[]) =>
  locations.map((loc) => ({ label: loc.name, value: loc.id }));

const createCoffeeMenuOptions = (coffeeMenus: CoffeeMenu[]) =>
  coffeeMenus.map((menu) => ({ label: menu.name, value: menu.id }));

interface FormFields {
  location?: string;
  coffeeMenu?: string | null;
  coffeeMenus: Record<string, string | null>;
  preselectMode?: PreselectMode;
  currency?: string;
  name?: string;
}

const Configure = () => {
  const { t } = useTranslation();

  const { machineId } = useParams<MatchParams>();

  const { data: machine, error } = useGetCoffeeMachineQuery(machineId);

  const { data: locationEntity } = useGetLocationsQuery();

  const { data: userGroupEntities } = useGetUserGroupsQuery();

  const { data: coffeeMenuEntities } = useGetCoffeeMenusQuery();

  let locations: Location[];
  let locationOptions: Option[] = [];

  let coffeeMenus: CoffeeMenu[] | undefined;
  let coffeeMenuOptions: Option[] = [];

  const userGroups = userGroupEntities
    ? getAll<UserGroup>(userGroupEntities)
    : [];

  const history = useHistory();

  const [deleteDisabled, setDeleteDisabled] = useState(true);

  useEffect(() => {
    if (isFetchBaseQueryError(error) && error.status === 404) {
      history.replace("/coffee-machines");
    }
  }, [history, error]);

  if (locationEntity) {
    locations = getAll<Location>(locationEntity);
    locationOptions = createLocationOptions(locations);
  }

  if (coffeeMenuEntities) {
    coffeeMenus = getAll<CoffeeMenu>(coffeeMenuEntities);
    coffeeMenuOptions = createCoffeeMenuOptions(
      coffeeMenus.filter(
        (menu) => menu.xml === machine?.xml
      )
    );
    coffeeMenuOptions = (
      [
        {
          label: capitalize(t("none")),
          value: "",
        },
      ] as Option[]
    ).concat(coffeeMenuOptions);
  }

  const preselectOptions = Object.values(PreselectMode).map(
    (preselectMode) => ({
      label: capitalize(
        t(`coffeeMachine.preselectModeOptions.${preselectMode}`)
      ),
      value: preselectMode,
    })
  );

  const initialValues: FormFields = {
    location: machine?.location.id ?? "",
    coffeeMenu: machine?.coffeeMenu?.id,
    preselectMode: machine?.preselectMode,
    coffeeMenus:
      machine?.userGroups?.reduce(
        (menus, group) => ({ ...menus, [group.id]: group.coffeeMenuId }),
        {}
      ) ?? {},
  };

  const [deleteMachineById, { isLoading: isDeleting }] =
    useDeleteCoffeeMachineMutation();

  const { edit: deleteMachine } = useEntityDialog({
    name: DELETE_COFFEE_MACHINE_DIALOG_NAME,
  });

  const [
    editMachineById,
    {
      isLoading: isSavingEdit,
      isSuccess: isSuccessEdit,
      isError: isErrorEdit,
      error: errorEdit,
    },
  ] = useEditCoffeeMachineMutation();

  const [
    switchCoffeeMachineMode,
    {
      isLoading: isSavingSwitchMode,
      isSuccess: isSuccessSwitchMode,
      isError: isErrorSwitchMode,
      error: errorSwitch,
    },
  ] = useSwitchCoffeeMachineModeMutation();

  const [isSaving, isSuccess, isError] = [
    isSavingEdit || isSavingSwitchMode,
    isSuccessEdit || isSuccessSwitchMode,
    isErrorEdit || isErrorSwitchMode,
  ];

  const saveError = useMemo(() => {
    const err = getErrorMessage(errorSwitch ?? errorEdit);
    return err === "coffeeMachine.switchMachineMode.noPaymentProvider" ||
      err === "coffeeMachine.edit.noPaymentProvider"
      ? t("coffeeMachine.saveFailureNoPaymentProvider")
      : null;
  }, [errorEdit, errorSwitch, t]);

  const { edit: transferOwnership } = useEntityDialog({
    name: TRANSFER_MACHINE_MODAL,
  });

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (machineId) {
        const updateMode =
          values.preselectMode &&
          machine?.preselectMode !== values.preselectMode;
        if (updateMode) {
          await switchCoffeeMachineMode({
            id: machineId,
            mode: values.preselectMode!,
          }).unwrap();

          if (values.preselectMode === "custom") {
            await editMachineById({
              id: machineId,
              locationId: values.location ?? "",
              coffeeMenuId: values.coffeeMenu || null,
              coffeeMenus:
                mapKV(values.coffeeMenus, (k, v) => [k, v || null]) ?? {},
            }).unwrap();
          } else {
            await editMachineById({
              id: machineId,
              locationId: values.location ?? "",
            }).unwrap();
          }
        } else {
          await editMachineById({
            id: machineId,
            locationId: values.location ?? "",
            coffeeMenuId: values.coffeeMenu || null,
            coffeeMenus:
              mapKV(values.coffeeMenus, (k, v) => [k, v || null]) ?? {},
          }).unwrap();
        }
      }
    },
    [
      editMachineById,
      machine?.preselectMode,
      machineId,
      switchCoffeeMachineMode,
    ]
  );

  const { open: openAddMenuDialog } = useEntityDialog({
    name: ADD_MENU_DIALOG_NAME,
  });

  const isOwner = useIsCoffeeMachineOwner(machine);

  const menuById = useCallback(
    (menuId: string) => coffeeMenus?.find((menu) => menuId === menu.id),
    [coffeeMenus]
  );

  const hasWarning = useCallback(
    (values: typeof initialValues, userGroup: UserGroup) =>
      values.coffeeMenu &&
      values.coffeeMenus[userGroup.id] &&
      values.preselectMode === PreselectMode.Custom &&
      menuById(values.coffeeMenu)?.mode === Mode.Open &&
      menuById(values.coffeeMenus[userGroup.id]!)?.mode !== Mode.Open,
    [menuById]
  );

  if (!machine || !locationEntity) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <DeleteDialog
            name={DELETE_COFFEE_MACHINE_DIALOG_NAME}
            entityName={t("coffeeMachine.title")}
            displayName={machine?.serial}
            onDelete={(id: string) => {
              deleteMachineById(id).unwrap();
              history.push("/coffee-machines");
            }}
            onClose={() => {
              setDeleteDisabled(true);
            }}
            busy={isDeleting}
            disabled={deleteDisabled}
            selector={selectCoffeeMachine}
          >
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={!deleteDisabled}
                  onChange={(e) => {
                    setDeleteDisabled(!e.target.checked);
                  }}
                />
              }
              label={t("coffeeMachine.deletePrompt")}
            />
          </DeleteDialog>

          <TransferMachineModal />

          <AddCoffeeMenuDialog
            initialValues={{ articleNumber: machine.articleNumber, name: "" }}
          />

          <TemporaryAlert open={!isSaving && isSuccess}>
            {t("coffeeMachine.saveSuccess")}
          </TemporaryAlert>

          <TemporaryAlert
            open={!isSaving && isError}
            severity="error"
            autoHideDuration={saveError ? 6000 : 3000}
          >
            {saveError ?? t("coffeeMachine.saveFailure")}
          </TemporaryAlert>

          <Paper sx={{ p: 2 }} className="configure-coffee-machine">
            <Grid item xs={12} sm={7} md={6}>
              <Formik
                initialValues={initialValues}
                validationSchema={EditMachineSchema}
                enableReinitialize={true}
                onSubmit={submit}
              >
                {({ handleSubmit, values }) => (
                  <Box>
                    <Form>
                      <Box
                        display="grid"
                        sx={{
                          gridTemplateColumns: "1fr 2fr",
                          gridGap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {values.preselectMode === PreselectMode.Custom && (
                          <Button
                            startIcon={<AddCircle />}
                            onClick={openAddMenuDialog}
                            sx={{
                              width: "auto",
                              mb: 1,
                              ml: "auto",
                              gridColumn: 2,
                            }}
                          >
                            {t("coffeeMachines.createMenu")}
                          </Button>
                        )}

                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap={1}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {capitalize(t("coffeeMachine.preselectMode"))}
                          </Typography>
                          <Tooltip
                            arrow
                            PopperProps={{
                              sx: {
                                lineHeight: "1.1rem",
                                pointerEvents: "none",
                              },
                            }}
                            title={
                              <>
                                <Trans
                                  i18nKey={
                                    "coffeeMachine.tooltip.preselectMode.guide"
                                  }
                                />
                                <br />
                                <Trans
                                  i18nKey={`coffeeMachine.tooltip.preselectMode.${values.preselectMode}`}
                                />
                              </>
                            }
                          >
                            <InfoOutlined sx={{ color: blue[500] }} />
                          </Tooltip>
                        </Stack>

                        <Stack direction="row" alignItems="center" spacing={2}>
                          <MultiSelect
                            name="preselectMode"
                            sx={{ mt: 0 }}
                            formControlProps={{
                              sx: { mt: 0 },
                            }}
                            helperText={t("required", {
                              field: capitalize(
                                t("coffeeMachine.preselectMode")
                              ),
                            })}
                          >
                            {preselectOptions.map((option) => (
                              <MenuItem value={option.value} key={option.value}>
                                <Tooltip
                                  arrow
                                  PopperProps={{
                                    sx: {
                                      lineHeight: "1.1rem",
                                      pointerEvents: "none",
                                    },
                                  }}
                                  title={
                                    <Trans
                                      i18nKey={`coffeeMachine.tooltip.preselectMode.${option.value}`}
                                    />
                                  }
                                >
                                  <Box>{option.label}</Box>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </MultiSelect>
                        </Stack>

                        {values.preselectMode === PreselectMode.Custom && (
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              columnGap={1}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                {capitalize(
                                  t("coffeeMachine.defaultCoffeeMenu")
                                )}
                              </Typography>
                              <Tooltip
                                arrow
                                title={t("coffeeMachine.defaultCoffeeMenuInfo")}
                              >
                                <InfoOutlined sx={{ color: blue[500] }} />
                              </Tooltip>
                            </Stack>

                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <MultiSelect
                                name="coffeeMenu"
                                options={coffeeMenuOptions}
                                helperText={t("required", {
                                  field: capitalize(
                                    t("coffeeMachine.defaultCoffeeMenu")
                                  ),
                                })}
                                formControlProps={{
                                  sx: { margin: 0 },
                                }}
                                sx={{ margin: 0 }}
                              />
                            </Stack>

                            {!userGroups.length ? (
                              <Alert
                                severity="warning"
                                sx={{
                                  my: 4,
                                  gridColumn: "1 / -1",
                                  "& a": {
                                    color: (theme) =>
                                      theme.palette.primary.main,
                                  },
                                }}
                              >
                                <AlertTitle>
                                  {t("coffeeMachine.userGroupsMissingTitle")}
                                </AlertTitle>
                                {t("coffeeMachine.userGroupsMissing")}
                              </Alert>
                            ) : (
                              <>
                                <Alert
                                  severity="info"
                                  sx={{
                                    my: 4,
                                    gridColumn: "1 / -1",
                                    "& a": {
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    },
                                  }}
                                >
                                  <AlertTitle>
                                    {t(
                                      "coffeeMachines.userGroupAssignmentInstructionsTitle"
                                    )}
                                  </AlertTitle>
                                  <ReactMarkdown>
                                    {t(
                                      "coffeeMachines.userGroupAssignmentInstructions",
                                      {
                                        joinArrays: "  \n",
                                      }
                                    )}
                                  </ReactMarkdown>
                                </Alert>

                                <Divider
                                  sx={{ gridColumn: "1  /  -1", mt: 2, mb: 1 }}
                                />

                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  columnGap={1}
                                  gridColumn="1 / -1"
                                  mt={2}
                                  mb={1}
                                >
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {capitalize(
                                      t("coffeeMachine.userGroupMenus")
                                    )}
                                  </Typography>
                                  <Tooltip
                                    arrow
                                    title={t(
                                      "coffeeMachine.userGroupMenusInfo"
                                    )}
                                  >
                                    <InfoOutlined sx={{ color: blue[500] }} />
                                  </Tooltip>
                                </Stack>

                                <Box
                                  display="grid"
                                  sx={{
                                    gridColumn: "1 / -1",
                                    gridTemplateColumns: "1fr 2fr",
                                    gridGap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  {userGroups.map((userGroup) => (
                                    <Fragment key={userGroup.id}>
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          flexBasis: "50%",
                                        }}
                                      >
                                        {capitalize(userGroup.name)}
                                      </Typography>

                                      <Stack
                                        direction="row"
                                        columnGap={2}
                                        alignItems="center"
                                      >
                                        <MultiSelect
                                          name={`coffeeMenus[${userGroup.id}]`}
                                          options={coffeeMenuOptions}
                                          helperText={t("required", {
                                            field: "coffeeMenu",
                                          })}
                                          sx={{ mt: 0 }}
                                          formControlProps={{
                                            sx: { margin: 0 },
                                          }}
                                        />

                                        {hasWarning(values, userGroup) && (
                                          <Tooltip
                                            arrow
                                            title={t(
                                              "coffeeMachines.userGroupAssignmentOpenDefaultWarning"
                                            )}
                                          >
                                            <WarningAmber
                                              sx={{ color: amber[500] }}
                                            />
                                          </Tooltip>
                                        )}
                                      </Stack>
                                    </Fragment>
                                  ))}
                                </Box>
                              </>
                            )}
                          </>
                        )}

                        <Divider sx={{ gridColumn: "1  /  -1", my: 2 }} />

                        <Typography sx={{ fontWeight: "bold" }}>
                          {capitalize(t("location"))}
                        </Typography>

                        <MultiSelect
                          name="location"
                          options={locationOptions}
                          sx={{ mt: 0 }}
                          helperText={t("required", { field: "location" })}
                        />

                        <Divider sx={{ gridColumn: "1  /  -1", my: 2 }} />
                      </Box>

                      {isOwner && (
                        <Button
                          sx={{ mt: 4, mr: 1 }}
                          startIcon={<CompareArrows />}
                          onClick={transferOwnership(machineId)}
                        >
                          {isSaving ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            t("coffeeMachines.transferOwnership")
                          )}
                        </Button>
                      )}

                      <Box
                        display="grid"
                        sx={{
                          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                          gridGap: { xs: 0, sm: "10px" },
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{ mt: 4, mr: 1 }}
                          startIcon={<DeleteForever />}
                          onClick={deleteMachine(machineId)}
                        >
                          {isSaving ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            t("coffeeMachines.removeMachineButtonText")
                          )}
                        </Button>

                        <Button
                          variant="contained"
                          startIcon={<Save />}
                          onClick={() => handleSubmit()}
                          sx={{ mt: { xs: 2, sm: 4 }, padding: "6px 16px" }}
                        >
                          {isSaving ? (
                            <CircularProgress size={20} color="white" />
                          ) : (
                            t("save")
                          )}
                        </Button>
                      </Box>
                    </Form>
                  </Box>
                )}
              </Formik>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Configure;

import { ButtonGroup, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthorizedRoute from "../../components/AuthorizedRoute";
import { NavButton } from "../../components/NavButton";
import { Role } from "../../state/users";
import Locations from "./Locations";
import Payments from "./Payments";
import Company from "./Company";
import UserManagement from "./UserManagement";
import CreditTopUps from "./CreditTopUps";
import PageHeading from "../../components/PageHeading";

const Setup = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeading />
        <ButtonGroup
          sx={{
            flexFlow: "row wrap",
            "> *": {
              width: "min-content",
              minWidth: "100px!important",
              whiteSpace: "nowrap",
            },
          }}
        >
          <NavButton to="/setup/locations">
            {t("setup.tabs.locations")}
          </NavButton>
          <NavButton
            to="/setup/payments"
            onlyRoles={[Role.Admin]}
            className="link-payments"
          >
            {t("setup.tabs.payment")}
          </NavButton>
          <NavButton
            to="/setup/operators"
            onlyRoles={[Role.Admin]}
            className="link-operators"
          >
            {t("setup.tabs.operators")}
          </NavButton>
          <NavButton to="/setup/credit-top-ups" onlyRoles={[Role.Admin]}>
            {t("setup.tabs.creditTopUps")}
          </NavButton>
          <NavButton to="/setup/company" onlyRoles={[Role.Admin]}>
            {t("setup.tabs.company")}
          </NavButton>
        </ButtonGroup>
      </Grid>
      <Switch>
        <Route path="/setup/locations">
          <Locations />
        </Route>
        <AuthorizedRoute path="/setup/operators" only={[Role.Admin]}>
          <UserManagement />
        </AuthorizedRoute>
        <AuthorizedRoute path="/setup/payments" only={[Role.Admin]}>
          <Payments />
        </AuthorizedRoute>
        <AuthorizedRoute path="/setup/company" only={[Role.Admin]}>
          <Company />
        </AuthorizedRoute>
        <AuthorizedRoute path="/setup/credit-top-ups" only={[Role.Admin]}>
          <CreditTopUps />
        </AuthorizedRoute>
        <Route path="/setup">
          <Redirect from="/setup" to="/setup/locations" />
        </Route>
      </Switch>
    </Grid>
  );
};

export default Setup;

import { CompareArrows, DeleteForever, Save } from "@mui/icons-material";
import {
  Grid,
  Button,
  capitalize,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  Company as CompanyInterface,
  selectCompany,
  selectCurrentUser,
} from "../../state/auth";
import {
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useGetCurrenciesQuery,
} from "../../state/services/api";

import { TemporaryAlert } from "../../components/Alert";
import TextInput from "../../components/form/TextInput";
import TransferCompanyModal, {
  TRANSFER_COMPANY_MODAL,
} from "./TransferCompany";
import { useEntityDialog } from "../../hooks/dialog";
import DeleteDialog from "../../components/DeleteDialog";
import MultiSelect from "../../components/form/MultiSelect";
import { usePageInfo } from "../../hooks/page";
import { TFunction } from "i18next";
import { regionOptions } from "../../state/company/company";

const CompanySchema = (t: TFunction) => Yup.object().shape({
  name: Yup.string().max(255, t('validation.maxLength')).required(),
  region: Yup.string().oneOf(regionOptions(t).map((option) => option.value)),
  currency: Yup.string().required(),
});

const DELETE_COMPANY_DIALOG_NAME = "company.delete";

const createCurrencyOptions = (currencies: string[]) =>
  currencies.map((currency) => ({ label: currency, value: currency }));

const Company = () => {
  const { t } = useTranslation();
  
  usePageInfo({
    title: t('setup.title'),
    subTitle: t('setup.company.subTitle'),
  })

  const [editCompany, { isLoading: isSaving, isError, isSuccess }] =
    useEditCompanyMutation();

  const [deleteCompanyById, { isLoading: isDeleting }] =
    useDeleteCompanyMutation();

  const { edit: transferOwnership } = useEntityDialog({
    name: TRANSFER_COMPANY_MODAL,
  });

  const { edit: deleteCompany } = useEntityDialog({
    name: DELETE_COMPANY_DIALOG_NAME,
  });

  const company = useSelector(selectCompany);

  const { data: currencies, isSuccess: currenciesLoaded } =
    useGetCurrenciesQuery();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      await editCompany({
        ...values,
        region: values.region || null
      }).unwrap();
    },
    [editCompany]
  );

  const initialValues = {
    name: company?.name ?? "",
    currency: company?.currency ?? "",
    region: company?.region ?? "other"
  };

  const user = useSelector(selectCurrentUser);

  const isOwner = useMemo(
    () => company && user && company.ownerId === user.id,
    [company, user]
  );

  if (!currenciesLoaded) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <Grid item>
      <DeleteDialog
        name={DELETE_COMPANY_DIALOG_NAME}
        entityName={t("company")}
        displayName={(entity: any) => entity.name}
        onDelete={(id: string) => deleteCompanyById(id).unwrap()}
        busy={isDeleting}
        selector={(_) => selectCompany as (state: any) => CompanyInterface}
      />
      <TransferCompanyModal />
      <Typography variant="h3" sx={{ mt: 3, mb: 2 }}>
        {capitalize(t("company"))}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={CompanySchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        <Form>
          <TextInput
            name="name"
            type="text"
            title={capitalize(t("name")) + ":"}
          />
          <MultiSelect
            name="currency"
            title={capitalize(t("currency")) + ":"}
            options={createCurrencyOptions(currencies!)}
            helperText={t("required", { field: "currency" })}
            required
          />
          <MultiSelect
            name="region"
            title={capitalize(t("region")) + ":"}
            options={regionOptions(t)}
          />

          <Button
            type="submit"
            variant="contained"
            startIcon={<Save />}
            sx={{ mt: 2 }}
          >
            {isSaving ? (
              <CircularProgress size={20} color="white" />
            ) : (
              t("save")
            )}
          </Button>

          {isOwner && (
            <>
              <Button
                sx={{ mt: 4, mr: 1 }}
                startIcon={<CompareArrows />}
                onClick={transferOwnership(company!.id)}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("setup.company.transferOwnership")
                )}
              </Button>
              <Button
                sx={{ mt: 4, mr: 1 }}
                startIcon={<DeleteForever />}
                color="error"
                variant="contained"
                onClick={deleteCompany(company!.id)}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("setup.company.deleteCompany")
                )}
              </Button>
            </>
          )}

          <TemporaryAlert open={isError} severity="error">
            {t("setup.company.saveFailure")}
          </TemporaryAlert>
          <TemporaryAlert open={isSuccess}>
            {t("setup.company.saveSuccess")}
          </TemporaryAlert>
        </Form>
      </Formik>
    </Grid>
  );
};

export default Company;

import { useTranslation } from "react-i18next";
import { useEntityDialog } from "../../hooks/dialog";
import {
  useCreateCoffeeMenuMutation,
  useGetCoffeeMachinesQuery,
} from "../../state/services/api";
import React, { useCallback, useMemo } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import EntityDialog from "../../components/EntityDialog";
import { Save } from "@mui/icons-material";
import TextInput from "../../components/form/TextInput";
import { TemporaryAlert } from "../../components/Alert";
import * as Yup from "yup";
import MultiSelect from "../../components/form/MultiSelect";
import { getAll } from "../../utils/api";

export const ADD_MENU_DIALOG_NAME = "add-menu";

const coffeeMenuSchema = Yup.object().shape({
  name: Yup.string().required(),
  articleNumber: Yup.string().required(),
});

const createCoffeeMachineModelOptions = (
  models: Array<{
    model: string;
    articleNumber: string;
  }>
) =>
  models.map((model: any) => ({
    label: `${model.model} (${model.articleNumber})`,
    value: model.articleNumber,
  }));

const AddCoffeeMenuDialog = ({
  initialValues = { articleNumber: "", name: "" },
}) => {
  const { t } = useTranslation();

  const { close } = useEntityDialog({
    name: ADD_MENU_DIALOG_NAME,
  });

  const [createCoffeeMenu, { isLoading: isSaving, isSuccess, isError }] =
    useCreateCoffeeMenuMutation();
  const { data: coffeeMachinesState, isSuccess: modelsLoaded } =
    useGetCoffeeMachinesQuery({});

  const coffeeMachines = getAll(coffeeMachinesState);

  const models = useMemo(() => {
    const models = coffeeMachines.map(({ model, articleNumber }) => ({
      model,
      articleNumber,
    }));

    return models.filter(
      ({ model, articleNumber }, i) =>
        models.findIndex(
          (m) => m.model === model && m.articleNumber === articleNumber
        ) === i
    );
  }, [coffeeMachines]);

  const submit = useCallback(
    async (values: typeof initialValues) => {
      await createCoffeeMenu(values);
      close();
    },
    [createCoffeeMenu, close]
  );

  if (!modelsLoaded) {
    return <CircularProgress size={20} color="white" />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={coffeeMenuSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) => (
          <EntityDialog
            name={ADD_MENU_DIALOG_NAME}
            title={t("coffeeMenus.addMenuDialog.title")}
            subTitle={t("coffeeMenus.addMenuDialog.subTitle")}
            okButton={
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box sx={{ mt: 4 }}>
              <Form>
                <Box sx={{ mt: 2 }}>
                  <MultiSelect
                    name="articleNumber"
                    title={t("coffeeMenus.addMenuDialog.articleNumber") + ":"}
                    options={createCoffeeMachineModelOptions(models)}
                    helperText={t("required", { field: "model" })}
                    required
                  />
                  <TextInput
                    name="name"
                    title={t("coffeeMenus.addMenuDialog.name") + ":"}
                  />
                </Box>
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
      <TemporaryAlert open={isError} severity="error">
        {t("coffeeMenus.addMenuDialog.saveFailure")}
      </TemporaryAlert>
      <TemporaryAlert open={isSuccess}>
        {t("coffeeMenus.addMenuDialog.saveSuccess")}
      </TemporaryAlert>
    </>
  );
};

export default AddCoffeeMenuDialog;

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  Redirect,
  useParams,
  useHistory,
} from "react-router-dom";
import { useGetCoffeeMachineQuery } from "../../../state/services/api";
import { NavButton } from "../../../components/NavButton";
import muiTheme from "../../../theme";
import Overview from "./Overview";
import Configure from "./Configure";
import { useEffect } from "react";
import { ChevronLeft, Person } from "@mui/icons-material";
import { useIsCoffeeMachineOwner } from "../../../hooks/user";
import MachineConsumption from "./MachineConsumption";

interface MatchParams {
  machineId: string;
}

const BackButton = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const goToCoffeMachines = () => {
    history.push("/coffee-machines");
  };

  return (
    <Button
      sx={{
        px: 13,
        py: 1,
        my: { xs: 4, md: 0 },
        width: { sm: "auto" },
      }}
      startIcon={<ChevronLeft sx={{ fontSize: "30px!important" }} />}
      onClick={goToCoffeMachines}
    >
      {t("back")}
    </Button>
  );
};

const CoffeeMachine = () => {
  const { t } = useTranslation();

  const { machineId } = useParams<MatchParams>();

  const { data: machine, isError } = useGetCoffeeMachineQuery(machineId);

  const smScreen = useMediaQuery<typeof muiTheme>((theme) =>
    theme.breakpoints.up("sm")
  );

  const history = useHistory();

  useEffect(() => {
    if (isError) {
      history.push("/coffee-machines");
    }
  }, [history, isError]);

  const isOwner = useIsCoffeeMachineOwner(machine);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mb={4}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ md: "space-between" }}
          alignItems="flex-start"
        >
          <Stack
            direction="row"
            alignItems="center"
            columnGap={2}
            marginBottom={2}
          >
            {isOwner ? <Person fontSize="large" /> : null}

            <Box>
              <Typography variant="h2">
                {t("coffeeMachine.title")} {machine?.nameHuman}
              </Typography>
              <Typography marginBottom={0} paragraph>
                {t("coffeeMachine.subTitle")}
              </Typography>
            </Box>
          </Stack>

          <BackButton />
        </Box>

        <ButtonGroup
          orientation={smScreen ? "horizontal" : "vertical"}
          fullWidth={!smScreen}
        >
          <NavButton
            to={`/coffee-machines/${machineId}/overview`}
            className="link-to-overview"
          >
            {t("coffeeMachine.nav.overview")}
          </NavButton>
          <NavButton
            to={`/coffee-machines/${machineId}/configure`}
            className="link-to-configure"
          >
            {t("coffeeMachine.nav.configure")}
          </NavButton>
          <NavButton
            to={`/coffee-machines/${machineId}/consumption`}
            className="link-to-consumption"
          >
            {t("coffeeMachine.nav.consumption")}
          </NavButton>
        </ButtonGroup>
      </Grid>

      <Switch>
        <Route path="/coffee-machines/:machineId/overview">
          <Overview />
        </Route>
        <Route path="/coffee-machines/:machineId/configure">
          <Configure />
        </Route>
        <Route path="/coffee-machines/:machineId/consumption">
          <MachineConsumption />
        </Route>
        <Route path="/coffee-machines/:machineId">
          <Redirect
            from="/coffee-machines/:machineId"
            to={`/coffee-machines/${machineId}/overview`}
          />
        </Route>
      </Switch>
    </Grid>
  );
};

export default CoffeeMachine;

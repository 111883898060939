import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import EntityDialog from "../../components/EntityDialog";
import { useEntityDialog } from "../../hooks/dialog";
import { useCloneCoffeeMenuMutation } from "../../state/services/api";
import { useAlert } from "../../components/alerts/AlertContext";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import TextInput from "../../components/form/TextInput";
import { Formik } from "formik";
import * as yup from "yup";
import { TFunction } from "i18next";

export const CLONE_MENU_DIALOG_NAME = "clone-menu";

interface MatchParams {
  shareKey: string;
}

const CloneMenuSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t("required", { field: t("name") })),
  });

const CloneDialog = () => {
  const [cloneMenu] = useCloneCoffeeMenuMutation();

  const { t } = useTranslation();

  const history = useHistory();

  const { shareKey } = useParams<MatchParams>();

  const alert = useAlert();

  const initialValues = {
    name: "",
  };

  const onSubmit = useCallback(
    async (values: typeof initialValues) => {
      try {
        const menu = await cloneMenu({ shareKey, name: values.name }).unwrap();
        history.push(`/coffee-menus/${menu.id}`);
      } catch (e) {
        alert({
          severity: "error",
          autoHideDuration: 3000,
          content: t("coffeeMenus.cloneMenuDialog.cloneError"),
        });
      }
    },
    [alert, cloneMenu, history, shareKey, t]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CloneMenuSchema(t)}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => (
        <EntityDialog
          name={CLONE_MENU_DIALOG_NAME}
          title={t("coffeeMenus.cloneMenuDialog.title")}
          subTitle={t("coffeeMenus.cloneMenuDialog.subTitle")}
          okButton={
            <Button type="submit" variant="contained" onClick={submitForm}>
              {t("coffeeMenus.cloneMenuDialog.cloneButtonText")}
            </Button>
          }
        >
          <TextInput name="name" sx={{ width: "100%" }} />
        </EntityDialog>
      )}
    </Formik>
  );
};

const Clone = () => {
  const { open: openDialog } = useEntityDialog({
    name: CLONE_MENU_DIALOG_NAME,
  });

  useEffect(() => {
    openDialog();
  }, [openDialog]);

  return <CloneDialog />;
};

export default Clone;

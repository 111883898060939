import { AddLocation, DeleteForever, Edit, Save } from "@mui/icons-material";
import {
  Box,
  Grid,
  Button,
  Typography,
  capitalize,
  CircularProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  selectLocation,
  useDeleteLocationMutation,
  useGetLocationsQuery,
  useEditLocationMutation,
  useCreateLocationMutation,
} from "../../state/services/api";
import { Location } from "../../state/locations";
import { TemporaryAlert } from "../../components/Alert";
import TileGrid from "../../components/TileGrid";
import Tile from "../../components/Tile";
import TileMenuButton from "../../components/TileMenuButton";
import Amount from "../../components/Amount";
import { useEntityDialog } from "../../hooks/dialog";
import DeleteDialog from "../../components/DeleteDialog";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import { getAll } from "../../utils/api";
import { usePageInfo } from "../../hooks/page";
import { TFunction } from "i18next";
import { ellipsisOverflow } from "../../utils/string";

const LOCATION_EDIT_DIALOG_NAME = "setup.location.edit";
const LOCATION_DELETE_DIALOG_NAME = "setup.location.delete";

const LocationSchema = (t: TFunction) => Yup.object().shape({
  name: Yup.string().max(255, t('validation.maxLength')).required(),
  address: Yup.string().required(),
});

const LocationDialog = () => {
  const { t } = useTranslation();

  const { entity: location, close } = useEntityDialog({
    name: LOCATION_EDIT_DIALOG_NAME,
    selector: selectLocation,
  });

  const [
    createLocation,
    {
      isLoading: isSavingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
    },
  ] = useCreateLocationMutation();

  const [
    editLocation,
    { isLoading: isSavingEdit, isSuccess: isSuccessEdit, isError: isErrorEdit },
  ] = useEditLocationMutation();

  const [isSaving, isError, isSuccess] = [
    isSavingCreate || isSavingEdit,
    isErrorCreate || isErrorEdit,
    isSuccessCreate || isSuccessEdit,
  ];

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (values.id) {
        await editLocation(values).unwrap();
      } else {
        await createLocation(values).unwrap();
      }
      close();
    },
    [editLocation, createLocation, close]
  );

  const initialValues = {
    id: location?.id,
    name: location?.name ?? "",
    address: location?.address ?? "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={LocationSchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit }) => (
          <EntityDialog
            name={LOCATION_EDIT_DIALOG_NAME}
            title={location?.name || t("setup.addLocationModal.title")}
            subTitle={
              location
                ? t("setup.editLocation")
                : t("setup.addLocationModal.subTitle")
            }
            okButton={
              <Button
                variant="contained"
                startIcon={!isSaving && <Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <TextInput
                  name="name"
                  title={t("setup.addLocationModal.locationName") + ":"}
                />
                <TextInput
                  name="address"
                  title={t("setup.addLocationModal.locationAddress") + ":"}
                  rows={4}
                  multiline
                />
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
      <TemporaryAlert open={isSuccess}>
        {t("setup.addLocationModal.saveSuccess")}
      </TemporaryAlert>
      <TemporaryAlert open={isError} severity="error">
        {t("setup.addLocationModal.saveFailure")}
      </TemporaryAlert>
    </>
  );
};

const Locations = () => {
  const { t } = useTranslation();
  const { data: locationState, isLoading } = useGetLocationsQuery();
  
  usePageInfo({
    title: t('setup.title'),
    subTitle: t('setup.location.subTitle'),
  })

  const locations = getAll(locationState);

  const [deleteLocationById, { isLoading: isDeleting }] =
    useDeleteLocationMutation();

  const { open, edit } = useEntityDialog<Location>({
    name: LOCATION_EDIT_DIALOG_NAME,
    selector: selectLocation,
  });

  const { edit: promptDelete } = useEntityDialog<Location>({
    name: LOCATION_DELETE_DIALOG_NAME,
  });

  return (
    <>
      <DeleteDialog
        name={LOCATION_DELETE_DIALOG_NAME}
        entityName={t("location")}
        displayName={(entity: Location) => entity.name}
        onDelete={(id: string) => deleteLocationById(id).unwrap()}
        busy={isDeleting}
        selector={selectLocation}
      />
      <Grid item xs>
        <Amount
          sx={{ my: 2 }}
          amount={locations?.length ?? 0}
          text={t("setup.numberOfLocations")}
        />
        <Button
          sx={{ mt: 4, width: { sm: "auto" } }}
          fullWidth
          startIcon={<AddLocation />}
          onClick={open}
        >
          {t("setup.addLocationButton")}
        </Button>
        <LocationDialog />
      </Grid>
      <TileGrid item busy={isLoading} ids={locationState?.ids}>
        {locations?.map((location: Location) => (
          <Tile
            id={location.id.toString()}
            title={ellipsisOverflow(location.name, 40)}
            key={location.id}
            menu={[
              <TileMenuButton
                key="edit"
                icon={<Edit />}
                onClick={edit(location.id.toString())}
              >
                {capitalize(t("edit"))}
              </TileMenuButton>,
              <TileMenuButton
                key="remove"
                icon={
                  isDeleting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <DeleteForever />
                  )
                }
                onClick={promptDelete(location.id.toString())}
              >
                {capitalize(t("remove"))}
              </TileMenuButton>,
            ]}
          >
            <Typography fontWeight="bold" fontSize={10}>
              {capitalize(t("machines"))}
            </Typography>
            <Typography>{location.coffeeMachines.length}</Typography>
          </Tile>
        ))}
      </TileGrid>
    </>
  );
};

export default Locations;

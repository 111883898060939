/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormControlProps,
  InputLabel,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  styled,
  Typography,
} from "@mui/material";
import {
  FieldHookConfig,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from "formik";
import { memo, useMemo } from "react";

interface SliderProps extends MuiSliderProps {
  helperText?: string;
  formControlProps?: FormControlProps;
  unit?: string;
}

const SliderFormControl = styled(FormControl)`
  width: 100%;

  .MuiSlider-root {
    display: block;
  }

  .MuiInputLabel-root {
    display: block;
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing(3.5)};
  }
`;

const SliderContainer = styled(Box)`
display: flex;
align-items: center;
white-space: nowrap;

& > :first-of-type {
  margin-right: ${({ theme }) => theme.spacing(1)};
}
& > :last-child {
  margin-left: ${({ theme }) => theme.spacing(1)};
}
`;

const SimpleSlider = memo(
  ({
    name,
    title,
    meta,
    formControlProps,
    onChange,
    field,
    unit,
    ...props
  }: SliderProps & {
    field: FieldInputProps<number>;
    meta: FieldMetaProps<number>;
  }) => (
    <SliderFormControl
      {...formControlProps}
      error={meta.touched && !!meta.error}
      margin="normal"
    >
      <InputLabel shrink htmlFor={name}>
        {title}
      </InputLabel>
      <SliderContainer>
        <Typography variant="body1" fontWeight="bold">
          {props.min}
        </Typography>
        <MuiSlider id={name} {...field} {...props} onChange={onChange} />
        <Typography variant="body1" fontWeight="bold">
          {`${props.max} ${unit}`}
        </Typography>
      </SliderContainer>
    </SliderFormControl>
  )
);

const Slider = ({
  name,
  title,
  helperText,
  formControlProps,
  ...props
}: SliderProps & FieldHookConfig<number>) => {
  const [field, meta, helpers] = useField({ ...props, name, type: "range" });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoField = useMemo(() => field, Object.values(field));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoMeta = useMemo(() => meta, Object.values(meta));

  const onChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    if (typeof value === "number" && field.value !== value) {
      helpers.setValue(value, false);
    }
  };

  return (
    <SimpleSlider
      title={title}
      meta={memoMeta}
      field={memoField}
      onChange={onChange}
      name={name}
      formControlProps={formControlProps}
      {...props}
    />
  );
};

export default Slider;

import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledDiv = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  width: 100%;
  cursor: grab;
  align-items: center;
  z-index: 50;
  justify-content: center;
  background: rgba(255,255,255,0.8);
`;

const DragText = styled(Box)`
  opacity: 1;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  background: white;
`;

const TileDrag = () => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <DragText>{t("widgets.dragText")}</DragText>
    </StyledDiv>
  );
};

export default TileDrag;

import { Typography } from "@mui/material";
import { useSelector } from "../state/hooks";
import { selectPage } from "../state/page";

const PageHeading = () => {
  const { title, subTitle } = useSelector(selectPage);
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Typography paragraph>{subTitle}</Typography>
    </>
  );
};

export default PageHeading;

import { Box, Button, Dialog, Stack, Typography, styled } from "@mui/material";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import {
  Version,
  getCurrentVersion,
  getRecommendedVersion,
  needsRedirect,
  redirectToVersion,
} from "../utils/redirect";
import logo from "../assets/logo.svg";
import { useTranslation } from "react-i18next";

export const ASIA_REDIRECT_DIALOG_NAME = "asia-redirect";

const AsiaDialogBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

const Logo = styled("img")`
  width: 8rem;
`;

function useSessionStorage<T>(key: string, initialValue: T) {
  const getSessionStorageValue = () => {
    const item = sessionStorage.getItem(key);
    return item && JSON.parse(item);
  };
  const setSessionStorageValue = (value?: T) => {
    if (value !== undefined) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.removeItem(key);
    }
  };

  const [state, setState] = useState<T>(
    getSessionStorageValue() ?? initialValue
  );

  return [
    state,
    (setter: SetStateAction<T>) => {
      setState((prevValue) => {
        // @ts-ignore
        const toSet = typeof setter === "function" ? setter(prevValue) : setter;
        setSessionStorageValue(toSet);
        return toSet;
      });
    },
  ] as [typeof state, typeof setState];
}

const AsiaRedirect = () => {
  const [version, setVersion] = useSessionStorage<Version | undefined>(
    "app-version",
    undefined
  );
  const [open, setOpen] = useState(needsRedirect() && version === undefined);

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setOpen(false);
    setVersion(getCurrentVersion());
  }, [setVersion]);

  const handleVersionSelection = useCallback(() => {
    setOpen(false);
    const targetVersion = getRecommendedVersion();
    setVersion(targetVersion);
    redirectToVersion(targetVersion);
  }, [setVersion]);

  useEffect(() => {
    if (version) {
      redirectToVersion(version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <AsiaDialogBox>
        <Stack direction="column" alignItems="center" rowGap={2}>
          <Box>
            <Logo className="logo" src={logo} alt="Jura" />
          </Box>
          <Typography variant="body1" fontWeight="bold">
            {t("serverSwitchPrompt", {
              origin: t(`servers.${getCurrentVersion()}`),
              target: t(`servers.${getRecommendedVersion()}`),
            })}
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap={2}>
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                width: "100%",
              }}
              onClick={handleClose}
            >
              {t("serverStayButtonText")}
            </Button>
            <Button
              variant="contained"
              sx={{
                whiteSpace: "nowrap",
                width: "100%",
              }}
              onClick={handleVersionSelection}
            >
              {t("serverSwitchButtonText", {
                target: t(`servers.${getRecommendedVersion()}`),
              })}
            </Button>
          </Box>
        </Stack>
      </AsiaDialogBox>
    </Dialog>
  );
};

export default AsiaRedirect;

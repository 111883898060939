import { useEffect } from "react";
import { useDispatch } from "../state/hooks";
import { setPage } from "../state/page";

interface PageInfo {
  title?: string;
  subTitle?: string;
}

export function usePageInfo(pageInfo: PageInfo) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(pageInfo))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
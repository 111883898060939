import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import languages from "./utils/supportedLanguages";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    backend: {
      loadPath: `${process.env.REACT_APP_API_BASE_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    supportedLngs: Object.keys(languages),
    interpolation: {
      formatSeparator: ",",
      format(value, format) {
        if (format === "uppercase") return value.toUpperCase();
        return value;
      },
    },
    react: {
      defaultTransParent: "div",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  });

export default i18n;

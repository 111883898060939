import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface State {
  title?: string;
  subTitle?: string;
}

const slice = createSlice({
  name: "page",
  initialState: {
    title: undefined,
    subTitle: undefined,
  } as State,
  reducers: {
    setPage: (state, action: PayloadAction<State>) => {
      return {
        ...state,
        ...action.payload
      };
    },
  },
});

export default slice.reducer;

export const selectPage = (state: RootState) => state.page;

export const { setPage } = slice.actions;
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h1">{t("unauthorized.title")}</Typography>
      <Typography paragraph>{t("unauthorized.subTitle")}</Typography>
    </>
  );
};

export default Unauthorized;

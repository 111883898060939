import { Grid, GridProps, Skeleton } from "@mui/material";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useCallback } from "react";
import { removeProps } from "../utils/object";

export type TileGridProps = (
  | {
      sortable: true;
      sort: (ids: Array<number | string>) => void;
      ids: Array<number | string>;
    }
  | {
      sortable?: false;
    }
) & { busy?: boolean } & GridProps;

const Skeletons = ({ amount }: { amount: number }) => (
  <>
    {Array.from({ length: amount }, () => null).map((_, i) => (
      <Grid item xs={2} key={i}>
        <Skeleton variant="rectangular" width="100%" height={300} />
      </Grid>
    ))}
  </>
);

const TileGrid = (props: TileGridProps) => {
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (!props.sortable) return;
      const { ids, sort } = props;
      const { active, over } = event;
      if (over && (active.id !== over?.id)) {
        const oldIndex = ids.indexOf(active.id);
        const newIndex = ids.indexOf(over.id);
        sort(arrayMove(ids, oldIndex, newIndex));
      }
    },
    [props]
  );

  const { busy, children, sortable } = props;

  const content = (
    <Grid
      {...removeProps(
        props as Record<string, unknown>,
        "busy",
        "sort",
        "ids",
        "children",
        "sortable"
      )}
      container
      sx={{ mt: 2 }}
    >
      {busy ? <Skeletons amount={4} /> : children}
    </Grid>
  );

  if (!sortable) return content;
  const { ids } = props;

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={ids.map((id) => id.toString())}>
        {content}
      </SortableContext>
    </DndContext>
  );
};

export default TileGrid;

import { capitalize, CircularProgress, styled } from "@mui/material";
import Tile from "./Tile";
import TileMenuButton from "./TileMenuButton";
import counterWidgetImage from "../assets/widget_icon.png";

import { Section, Widget } from "../state/widgets/widgetTypes";

import { DeleteForever, Edit } from "@mui/icons-material";
import DeleteDialog from "./DeleteDialog";
import { useEntityDialog } from "../hooks/dialog";
import WidgetDialog, { EDIT_WIDGET_DIALOG_NAME } from "./widget/WidgetDialog";
import TileGrid from "./TileGrid";
import WidgetBody from "./WidgetBody";
import { useSelector } from "react-redux";
import { useDispatch } from "../state/hooks";
import { selectWidgetIds, setWidgetIds } from "../state/widgets";
import { TemporaryAlert } from "./Alert";
import {
  selectWidget,
  useDeleteWidgetMutation,
  useGetWidgetsQuery,
} from "../state/services/api";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const CounterTile = styled(Tile)`
  .MuiPaper-root {
    background-image: url(${counterWidgetImage});
    background-repeat: no-repeat;
    background-position: bottom;
    background-origin: content-box;
  }
`;

interface WidgetGridProps {
  filter?: Section;
  editMode: boolean;
}

const DELETE_WIDGET_NAME = "widget.delete";

const WidgetGrid = (props: WidgetGridProps) => {
  const { editMode } = props;
  
  const { t } = useTranslation();

  const [deleteWidgetById, { isLoading: isDeleting, isSuccess: deleteSuccess, isError: deleteError }] =
    useDeleteWidgetMutation();

  const { edit: editWidget } = useEntityDialog({
    name: EDIT_WIDGET_DIALOG_NAME,
  });

  const { edit: deleteWidget } = useEntityDialog({
    name: DELETE_WIDGET_NAME,
  });

  const { data: widgetEntity, refetch } = useGetWidgetsQuery();
  
  useEffect(() => {
    refetch();
  }, [refetch]);

  let widgets: Widget[] = [];

  const widgetIds = useSelector(selectWidgetIds);

  if (widgetEntity) {
    widgets = widgetIds.map((id) => {
      return widgetEntity.entities[id]!;
    });
  }

  const dispatch = useDispatch();

  return (
    <>
      <DeleteDialog
        name={DELETE_WIDGET_NAME}
        key={`del-2`}
        displayName={(entity: Widget) => entity.title ?? ""}
        entityName={"widget.name"}
        onDelete={(id: string) => deleteWidgetById(id).unwrap()}
        busy={isDeleting}
        selector={selectWidget}
      />

      <WidgetDialog />

      <TileGrid
        item
        sortable={editMode}
        sort={(ids) => {
          const newIds = ids as string[];
          dispatch(setWidgetIds([...newIds]));
        }}
        ids={widgetIds}
      >
        {widgets?.map((widget: any) => (
          <CounterTile
            title={widget.title}
            subTitle={widget.subTitle}
            id={widget.id}
            key={widget.id}
            editMode={editMode}
            menu={[
              <TileMenuButton
                key="edit"
                icon={<Edit />}
                onClick={editWidget(widget.id)}
              >
                {capitalize(t("edit"))}
              </TileMenuButton>,
              <TileMenuButton
                key="remove"
                icon={
                  false ? <CircularProgress size={20} /> : <DeleteForever />
                }
                onClick={deleteWidget(widget.id)}
              >
                {capitalize(t("remove"))}
              </TileMenuButton>,
            ]}
          >
            <WidgetBody widget={widget} />
          </CounterTile>
        ))}
      </TileGrid>

      <TemporaryAlert open={deleteSuccess}>
        {t("widget.remove.success")}
      </TemporaryAlert>

      <TemporaryAlert open={deleteError} severity="error">
        {t("widget.remove.failure")}
      </TemporaryAlert>
    </>
  );
};

export default WidgetGrid;

import {
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { string, object, boolean } from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useLoginMutation } from "../../state/services/api";
import { useCallback } from "react";
import { useLocalStorage } from "../../hooks/storage";
import { User } from "../../state/users";
import { useKeycloak } from "../../components/auth";
import { TFunction } from "i18next";
import AsiaRedirect from "../../structure/AsiaRedirect";

const loginSchema = (t: TFunction) =>
  object().shape({
    email: string().required(t("required", { field: t("email") })),
    password: string().required(t("required", { field: t("password") })),
    rememberMe: boolean(),
  });

const initialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

const Login = () => {
  const { t, i18n } = useTranslation();
  const [login] = useLoginMutation();
  const history = useHistory();
  const [rememberedEmail, setRememberedMail] =
    useLocalStorage<string>("remembered-mail");

  const enter = useCallback(
    (user: User) => {
      if (user?.preferredLanguage) {
        i18n.changeLanguage(user.preferredLanguage);
      }

      history.push("/");
    },
    [history, i18n]
  );

  const { keycloak } = useKeycloak();

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (values.rememberMe) {
        setRememberedMail(values.email);
      }

      const { user } = await login({
        email: values.email,
        password: values.password,
      }).unwrap();

      enter(user);
    },
    [login, setRememberedMail, enter]
  );

  return (
    <Box>
      <AsiaRedirect />
      <Formik
        initialValues={{ ...initialValues, email: rememberedEmail ?? "" }}
        validationSchema={loginSchema(t)}
        onSubmit={submit}
      >
        {({ values }) => (
          <Form>
            <Box width="100%" alignItems="center">
              <Typography variant="h3" sx={{ mb: 4 }}>
                {t("auth.login.title")}
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 4 }}
                fullWidth
                onClick={() => keycloak.login({ redirectUri: `${window.location.origin}/`, locale: i18n.language })}
              >
                {t("auth.login.oauth2KeycloakButton")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;

import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthorized } from "../hooks/auth";
import Unauthorized from "../page/Unauthorized";
import { selectCompany } from "../state/auth";
import { Role } from "../state/users";

interface AuthorizedRouteProps extends RouteProps {
  only: Role[] | Role;
}

const redirects = {
  [Role.UserManager]: "/user-management"
}

const isRedirectable = (role: Role): role is keyof (typeof redirects) => role in redirects;

const AuthorizedRoute = ({ only, ...props }: AuthorizedRouteProps) => {
  const authorized = useAuthorized(only);
  const company = useSelector(selectCompany);
  if (!authorized && company?.role && isRedirectable(company?.role)) {
    return <Redirect to={redirects[company.role]} />
  }
  return authorized ? <Route {...props} /> : <Unauthorized />;
};

export default AuthorizedRoute;
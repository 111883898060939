import { Box, Chip, FormControl, Stack, Typography } from "@mui/material";
import MultiSelect from "../../../components/MultiSelect";

interface Option {
  label: string;
  value: string;
}

interface MultiselectFilterProps {
  options: Option[];
  values: string[];
  label: string;
  setValues: (ids?: string[]) => void;
}

function MultiselectFilter({
  options,
  values,
  setValues,
  label,
}: MultiselectFilterProps) {
  return (
    <Stack>
      <Typography variant="h4">{label}</Typography>
      <FormControl margin="normal" fullWidth>
        <MultiSelect
          id="menu"
          name="menu"
          value={values}
          includeAllOption
          onChange={(v) => {
            setValues(v as string[]);
          }}
          renderValue={(options) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {options.map((option) => (
                <Chip key={option.value} label={option.label} />
              ))}
            </Box>
          )}
          sx={{
            margin: 0,
            maxWidth: "250px",
            "& .MuiSelect-select": { display: "flex" },
          }}
          options={options}
          multiple
        />
      </FormControl>
    </Stack>
  );
}

export default MultiselectFilter;

import React, { ReactNode } from "react";
import { store } from "./state/store";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "./theme";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BrowserRouter as Router } from "react-router-dom";

function Export({ children }: { children: ReactNode }) {
  return (
    <React.StrictMode>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>{children}</ReduxProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </Router>
    </React.StrictMode>
  );
}

export default Export;

import { store } from "./state/store";
import { styled, ThemeProvider } from "@mui/material";
import theme from "./theme";
import MuiContainer from "@mui/material/Container";
import Layout from "./structure/Layout";
import Dashboard from "./page/dashboard/Dashboard";
import UserManagement from "./page/usermanagement";
import CoffeeMachines from "./page/coffee-machines";
import Setup from "./page/setup";
import { Provider as ReduxProvider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";

import { useGetCompaniesQuery, useMeQuery } from "./state/services/api";
import { useIsAuthenticated, useLogin } from "./hooks/auth";
import AuthLayout from "./structure/AuthLayout";
import Login from "./page/auth/Login";
import Signup from "./page/auth/Signup";
import Logout from "./page/auth/Logout";
import Loading from "./page/Loading";
import { routeRoles } from "./state/auth";
import AuthorizedRoute from "./components/AuthorizedRoute";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TermsOfUse from "./structure/TermsOfUse";
import Privacy from "./structure/Privacy";
import UserProfile from "./page/user-profile";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import keycloak from "./keycloak";
import ReactKeycloakAuthProvider from "./components/auth/keycloakProvider";
import { AuthClientEvent } from "./components/auth";
import NotFound from "./page/NotFound";
import CreateCompany from "./page/setup/CreateCompany";
import CoffeeMenus from "./page/coffee-menus";
import Joyride from "./joyride";
import { useExtendedUserEdit } from "./hooks/user";
import ReverseInvite from "./page/auth/ReverseInvite";
import { parse } from "qs";
import Help from "./page/help/Help";
import AlertProvider from "./components/alerts/AlertProvider";
import HelpSection from "./page/help";

const Container = styled(MuiContainer)<{ component: string }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
  flex-flow: column;
  flex-grow: 1;
  box-sizing: content-box;

  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: ${({ theme }) => theme.spacing(4)}
      ${({ theme }) => theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(4)}
      ${({ theme }) => theme.spacing(2.5)};
  }

  ${(props) => props.theme.breakpoints.up("lg")} {
    padding: ${({ theme }) => theme.spacing(4)}
      ${({ theme }) => theme.spacing(4.375)};
  }

  ${(props) => props.theme.breakpoints.up("xl")} {
    padding: ${({ theme }) => theme.spacing(4)}
      ${({ theme }) => theme.spacing(13)};
  }
`;

const LoggedInRoutes = () => {
  const { data: me, isLoading: loadingMe } = useMeQuery();
  const { i18n } = useTranslation();
  const { data: companies, isLoading: loadingCompanies } =
    useGetCompaniesQuery();

  useEffect(() => {
    if (me?.preferredLanguage) {
      i18n.changeLanguage(me.preferredLanguage);
    }
  }, [me, i18n]);

  const isUserRegistrationComplete = useMemo(() => {
    return companies !== undefined && companies.length > 0;
  }, [companies]);

  const [editUser] = useExtendedUserEdit();

  const onCompleted = useCallback(() => {
    if (me) editUser({ id: me?.id, body: { tutorialCompleted: true } });
  }, [editUser, me]);

  if (loadingMe || loadingCompanies) return <Loading />;

  return (
    <Layout isUserRegistrationComplete={isUserRegistrationComplete}>
      <Container component="main" maxWidth="xl">
        {isUserRegistrationComplete ? (
          <>
            {!me?.tutorialCompleted && <Joyride onCompleted={onCompleted} />}
            <Switch>
              <AuthorizedRoute exact path="/" only={routeRoles.dashboard}>
                <Dashboard />
              </AuthorizedRoute>
              <AuthorizedRoute
                path="/coffee-machines"
                only={routeRoles.coffeeMachines}
              >
                <CoffeeMachines />
              </AuthorizedRoute>
              <AuthorizedRoute
                path="/coffee-menus"
                only={routeRoles.coffeeMenus}
              >
                <CoffeeMenus />
              </AuthorizedRoute>
              <AuthorizedRoute path="/setup" only={routeRoles.setup}>
                <Setup />
              </AuthorizedRoute>
              <AuthorizedRoute
                path="/user-management"
                only={routeRoles.userManagement}
              >
                <UserManagement />
              </AuthorizedRoute>
              <Route path="/help">
                <HelpSection />
              </Route>
              <Route path="/reverse-invite">
                <ReverseInvite />
              </Route>
              <Route path="/legal/terms">
                <TermsOfUse />
              </Route>
              <Route path="/legal/privacy">
                <Privacy />
              </Route>
              <Route path="/profile">
                <UserProfile />
              </Route>
              <Route path="/signup">
                <Redirect to="/" />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </>
        ) : (
          <Switch>
            <Route path="/profile">
              <UserProfile showBackButton />
            </Route>
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/reverse-invite">
              <ReverseInvite />
            </Route>
            <Route path="/legal/terms">
              <TermsOfUse />
            </Route>
            <Route path="/legal/privacy">
              <Privacy />
            </Route>
            <Route path="/signup">
              <Redirect to="/" />
            </Route>
            <Route path="/">
              <CreateCompany />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        )}
      </Container>
    </Layout>
  );
};
const LoggedOutRoutes = () => (
  <AuthLayout>
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route path="/reverse-invite">
        <ReverseInvite />
      </Route>
      <Route path="/legal/terms">
        <TermsOfUse />
      </Route>
      <Route path="/legal/privacy">
        <Privacy />
      </Route>
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  </AuthLayout>
);

const LoginSwitch = () => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <LoggedInRoutes /> : <LoggedOutRoutes />;
};

const KeycloakProvider = ({ children }: { children: React.ReactNode }) => {
  const [login, errorAlert] = useLogin();
  const location = useLocation();
  const router = useHistory();

  const params = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const onAuth = useCallback(
    async (event: AuthClientEvent) => {
      if (event !== "onAuthSuccess") return;
      if (params.invited) return;
      if (params.signup) return;
      if (await login()) {
        router.push("/");
      }
    },
    [login, params.invited, params.signup, router]
  );

  return (
    <>
      {errorAlert}
      <ReactKeycloakAuthProvider
        authClient={keycloak}
        LoadingComponent={<Loading />}
        onEvent={onAuth}
        initOptions={{
          onLoad: "check-sso",
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.html",
          pkceMethod: "S256",
          scope: ["openid", "email", "profile"],
        }}
      >
        {children}
      </ReactKeycloakAuthProvider>
    </>
  );
};

function AppBase() {
  return (
    <>
      <LoginSwitch />
      <Logout />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <Router>
            <KeycloakProvider>
              <AlertProvider>
                <AppBase />
              </AlertProvider>
            </KeycloakProvider>
          </Router>
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormGroup,
  FormHelperText,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import { FieldHookConfig, useField } from "formik";
import { reactNodeToLabel } from "../../utils/react";

interface CheckboxProps {
  helperText?: string;
  formControlProps?: FormControlProps;
  checkboxProps?: MuiCheckboxProps;
  children: React.ReactNode;
}

const Checkbox = <T extends {}>({
  name,
  helperText,
  formControlProps,
  checkboxProps,
  children,
  ...props
}: CheckboxProps & FieldHookConfig<T>) => {
  const [field, meta] = useField({ ...props, name, type: "checkbox" });
  const showError = meta.touched && !!meta.error;
  return (
    <FormControl {...formControlProps} error={meta.touched && !!meta.error}>
      <FormGroup>
        <FormControlLabel
          control={<MuiCheckbox id={name} {...field} {...checkboxProps} />}
          label={reactNodeToLabel(children)}
        />
      </FormGroup>
      <FormHelperText>{showError ? meta.error : helperText}</FormHelperText>
    </FormControl>
  );
};

export default Checkbox;

import {
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  createSlice,
  PayloadAction,
  ActionCreatorWithPayload,
} from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface Filter {
  locations?: string[];
  machines?: string[];
  coffeeMenus?: string[];
}

interface FilterState {
  filter: Filter;
}

const createFilterSlice = <Reducers extends SliceCaseReducers<FilterState>>({
  name = "",
  initialState,
  reducers,
}: {
  name: string;
  initialState: FilterState;
  reducers: ValidateSliceCaseReducers<FilterState, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      reset: (_: FilterState) => ({ filter: { locations: [], machines: [] } }),
      setFilter: (
        state: FilterState,
        { payload: filter }: PayloadAction<FilterState["filter"]>
      ) => ({
        ...state,
        filter,
      }),
      ...reducers,
    },
  });
};

export type setFilterFunc = ActionCreatorWithPayload<
  {
    locations?: string[] | undefined;
    machines?: string[] | undefined;
  },
  string
>;

export type selectFilterFunc = (state: RootState) => {
  locations?: string[] | undefined;
  machines?: string[] | undefined;
};

export default createFilterSlice;

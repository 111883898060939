import { useTranslation } from "react-i18next";
import {
  useGetCreditTopUpsQuery,
  useDeleteCreditTopUpMutation,
  selectCreditTopUp,
  useCreateCreditTopUpMutation,
  useEditCreditTopUpMutation,
} from "../../state/services/api";
import { useEntityDialog } from "../../hooks/dialog";
import DeleteDialog from "../../components/DeleteDialog";
import { getAll } from "../../utils/api";
import { CreditTopUp } from "../../state/credit-top-ups";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  capitalize,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { DeleteForever, GroupAdd, Save } from "@mui/icons-material";
import * as Yup from "yup";
import Amount from "../../components/Amount";
import { useCallback } from "react";
import { TemporaryAlert } from "../../components/Alert";
import Tile from "../../components/Tile";
import TileGrid from "../../components/TileGrid";
import { Formik, Form } from "formik";
import EntityDialog from "../../components/EntityDialog";
import TextInput from "../../components/form/TextInput";
import ReactMarkdown from "react-markdown";
import { useSelector } from "../../state/hooks";
import { selectCompany } from "../../state/auth";
import { usePageInfo } from "../../hooks/page";

const CREDIT_TOP_UP_EDIT_DIALOG_NAME = "setup.creditTopUp.edit";
const CREDIT_TOP_UP_DELETE_DIALOG_NAME = "setup.creditTopUp.delete";

const CreditTopUpSchema = Yup.object().shape({
  value: Yup.number().positive(),
});

const CreditTopUpDialog = () => {
  const { t } = useTranslation();

  const { entity: creditTopUp, close } = useEntityDialog({
    name: CREDIT_TOP_UP_EDIT_DIALOG_NAME,
    selector: selectCreditTopUp,
  });

  const [
    createCreditTopUp,
    {
      isLoading: isSavingCreate,
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
    },
  ] = useCreateCreditTopUpMutation();

  const [
    editCreditTopUp,
    { isLoading: isSavingEdit, isSuccess: isSuccessEdit, isError: isErrorEdit },
  ] = useEditCreditTopUpMutation();

  const [isSaving, isError, isSuccess] = [
    isSavingCreate || isSavingEdit,
    isErrorCreate || isErrorEdit,
    isSuccessCreate || isSuccessEdit,
  ];

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (values.id) {
        await editCreditTopUp({ id: values.id!, value: values.value }).unwrap();
      } else {
        await createCreditTopUp(values).unwrap();
      }
      close();
    },
    [close, editCreditTopUp, createCreditTopUp]
  );

  const initialValues = {
    id: creditTopUp?.id ?? undefined,
    value: creditTopUp?.value ?? 5,
  };

  const [deleteCreditTopUp, { isLoading: isDeleting }] =
    useDeleteCreditTopUpMutation();

  const { edit: promptDelete } = useEntityDialog<CreditTopUp>({
    name: CREDIT_TOP_UP_DELETE_DIALOG_NAME,
  });

  return (
    <>
      <DeleteDialog
        name={CREDIT_TOP_UP_DELETE_DIALOG_NAME}
        entityName={t("creditTopUp")}
        displayName={(entity) => entity.value.toString()}
        onDelete={(id: string) => deleteCreditTopUp(id).unwrap()}
        busy={isDeleting}
        selector={selectCreditTopUp}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={CreditTopUpSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit }) => (
          <EntityDialog
            name={CREDIT_TOP_UP_EDIT_DIALOG_NAME}
            title={t("setup.addCreditTopUpModal.title")}
            removeButton={
              creditTopUp && (
                <Button
                  color="error"
                  variant="contained"
                  startIcon={
                    isDeleting ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DeleteForever />
                    )
                  }
                  onClick={
                    creditTopUp &&
                    (() => {
                      close();
                      promptDelete(creditTopUp.id.toString())();
                    })
                  }
                >
                  {isSaving ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    capitalize(t("delete"))
                  )}
                </Button>
              )
            }
            okButton={
              <Button
                variant="contained"
                startIcon={!isSaving && <Save />}
                onClick={() => handleSubmit()}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  capitalize(t("save"))
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <TextInput
                  name="value"
                  type="number"
                  title={t("setup.addCreditTopUpModal.value") + ":"}
                />
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>
      <TemporaryAlert open={!isSaving && isSuccess}>
        {t("setup.addCreditTopUpModal.saveSuccess")}
      </TemporaryAlert>
      <TemporaryAlert open={!isSaving && isError} severity="error">
        {t("setup.addCreditTopUpModal.saveFailure")}
      </TemporaryAlert>
    </>
  );
};

const CreditTopUps = () => {
  const { t } = useTranslation();

  usePageInfo({
    title: t("setup.title"),
    subTitle: t("setup.creditTopUp.subTitle"),
  });

  const { data: creditTopUpsState, isLoading } = useGetCreditTopUpsQuery();

  const company = useSelector(selectCompany);

  const creditTopUps = getAll(creditTopUpsState);

  const { open, edit } = useEntityDialog<CreditTopUp>({
    name: CREDIT_TOP_UP_EDIT_DIALOG_NAME,
    selector: selectCreditTopUp,
  });

  return (
    <>
      <Grid item xs>
        <Amount
          sx={{ my: 2 }}
          amount={creditTopUps?.length ?? 0}
          text={t("setup.numberOfCreditTopUps")}
        />
        <Alert severity="info" sx={{ mt: 4, width: "fit-content" }}>
          <AlertTitle>{t("setup.editCreditTopUpNoteTitle")}</AlertTitle>
          <ReactMarkdown>
            {t("setup.editCreditTopUpNote", {
              joinArrays: "  \n",
            })}
          </ReactMarkdown>
        </Alert>
        {!company?.defaultPaymentId && (
          <Alert
            severity="warning"
            sx={{
              my: 4,
              gridColumn: "1 / -1",
              "& a": {
                color: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <AlertTitle>{t("setup.creditTopUp.noPaymentWarningTitle")}</AlertTitle>
            {t("setup.creditTopUp.noPaymentWarning")}
          </Alert>
        )}
        <Button
          sx={{ mt: 4, width: { sm: "auto" } }}
          fullWidth
          startIcon={<GroupAdd />}
          onClick={open}
        >
          {t("setup.addCreditTopUpButton")}
        </Button>

        <CreditTopUpDialog />
      </Grid>
      <TileGrid item busy={isLoading} ids={creditTopUpsState?.ids}>
        {creditTopUps?.map((creditTopUp: CreditTopUp) => (
          <Tile
            id={creditTopUp.id.toString()}
            key={creditTopUp.id}
            onClick={edit(creditTopUp.id.toString())}
          >
            <Box
              pb={15}
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Typography
                fontWeight="bold"
                fontSize={30}
                whiteSpace="nowrap"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {creditTopUp.value} {company?.currency}
              </Typography>
            </Box>
          </Tile>
        ))}
      </TileGrid>
    </>
  );
};

export default CreditTopUps;

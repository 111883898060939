import { useExportUsersMutation } from "../state/services/api";
import { ConsumptionFilter, UserFilter } from "../state/users";
import { ExportType, ExportData, useEntityExport } from "./export";

interface UserExportComponentProps {
  filter: UserFilter & ConsumptionFilter;
}

export function useUsersExport(
  type: ExportType,
  exportData: ExportData<
    { filter: UserFilter & ConsumptionFilter },
    UserExportComponentProps
  >
) {
  const [exportUsers] = useExportUsersMutation();

  return useEntityExport(type, exportData, exportUsers, "Users");
}

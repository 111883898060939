import { Link, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { HeadingTree } from "./Header";

const StyledContents = styled(Box)`
  a {
    color: currentColor;
    
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main}
    }
  }
`;

const Contents = ({ headings }: { headings: HeadingTree }) => {
  return (
    <Box>
      {headings.map((heading) => (
        <Box key={heading.id}>
          <Link href={`#${heading.ref}`}>
            <Typography
              variant={
                //`h${heading?.location.length}` as "h1" | "h2" | "h3" | "h4"
                "body1"
              }
              sx={{ pl: (heading.level - 1) * 2 }}
            >
              {heading?.location.join(".")}. {heading.title}
            </Typography>
          </Link>
          <Contents headings={heading.children} />
        </Box>
      ))}
    </Box>
  );
};

const TableOfContents = ({ headings }: { headings: HeadingTree }) => {
  const { t } = useTranslation();

  return (
    <StyledContents display="flex" flexDirection="column" rowGap={3}>
      <Typography variant="h1">{t("help.tableOfContentsTitle")}</Typography>
      <Contents headings={headings} />
    </StyledContents>
  );
};

export default TableOfContents;

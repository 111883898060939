import { Box } from "@mui/material";
import Dialog, { DialogProps } from "../components/Dialog";
import { useEntityDialog } from "../hooks/dialog";
import { useCallback } from "react";

export interface EntityDialogProps
  extends Omit<DialogProps, "open" | "onClose" | "children"> {
  children?: React.ReactNode;
  onClose?: () => void;
  name: string;
}

const EntityDialog = ({
  name,
  children,
  onClose,
  ...props
}: EntityDialogProps) => {
  const { close, isOpen } = useEntityDialog({ name });

  const handleClose = useCallback(() => {
    close();
    onClose?.();
  }, [close, onClose]);

  return (
    <Dialog open={isOpen} onClose={handleClose} {...props}>
      {children ? <Box>{children}</Box> : <div />}
    </Dialog>
  );
};

export default EntityDialog;

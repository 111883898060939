import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TemporaryAlert } from "./Alert";


function LinkRenderer(props: any) {
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <TemporaryAlert severity="success" open={copied}>
        {t("setup.payment.stripe.webhookCopiedToClipboard")}
      </TemporaryAlert>
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => {
          if (props["data-copy"]) {
            navigator.clipboard.writeText(props.href);
            setCopied(true);
            e.preventDefault();
          }
        }}
      >
        {props.children}
      </a>
    </>
  );
}

export default LinkRenderer;
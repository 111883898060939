import { Alert, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TextInput from "../../../components/form/TextInput";
import { selectCompany } from "../../../state/auth";
import { AlipayPayment } from "../../../state/payments";

const AlipayFields = ({ values }: { values: Omit<AlipayPayment, "id"> }) => {
  const { t } = useTranslation();
  const company = useSelector(selectCompany);
  return (
    <>
      {company?.currency !== "CNY" && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {t("setup.payment.alipay.currencyWarning")}
        </Alert>
      )}
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.alipay.generalTitle")}
        </Typography>
        <TextInput
          key="options.appId"
          name="options.appId"
          type="password"
          title={t("setup.payment.alipay.appId") + ":"}
        />
        <TextInput
          key="options.gatewayUrl"
          name="options.gatewayUrl"
          type="password"
          title={t("setup.payment.alipay.gatewayUrl") + ":"}
        />
      </Paper>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.alipay.keysTitle")}
        </Typography>
        <TextInput
          key="options.privateKey"
          name="options.privateKey"
          type="password"
          title={t("setup.payment.alipay.privateKey") + ":"}
        />
        <TextInput
          key="options.publicKey"
          name="options.publicKey"
          type="password"
          title={t("setup.payment.alipay.publicKey") + ":"}
        />
        <TextInput
          key="options.alipayPublicKey"
          name="options.alipayPublicKey"
          type="password"
          title={t("setup.payment.alipay.alipayPublicKey") + ":"}
        />
      </Paper>
    </>
  );
};

export default AlipayFields;

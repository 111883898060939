import {
  Grid,
  GridProps,
  IconButton,
  Stack,
  Menu,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { createContext, useCallback, useMemo, useState } from "react";
import DragHandle from "@mui/icons-material/DragHandle";
import TileDrag from "./TileDrag";

const TileMenu = styled(Menu)`
  .MuiBackdrop-root {
    background: transparent;
  }
`;

const TileIconBar = styled(Stack)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
  z-index: 100;
`;

const DragHandleIcon = styled(DragHandle)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};
  z-index: 100;
`;

const SubTitle = styled(Typography)`
  color: #9a7348;
  font-size: 1rem;
`;

export interface TileProps extends GridProps {
  id: string;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
  menu?: React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  editMode?: boolean;
  onClick?: () => void;
}

export interface TileContextContent {
  menuOpen: boolean;
  closeMenu: () => void;
}

export const TileContext = createContext<TileContextContent>({
  menuOpen: false,
  closeMenu: () => {},
});

const Tile = ({
  id,
  title,
  subTitle,
  children,
  icon,
  header,
  menu,
  editMode,
  onClick,
  className,
  ...gridProps
}: TileProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id });

  const style: React.CSSProperties = {
    touchAction: editMode ? "none" : undefined,
    userSelect: "none",
    outline: "none",
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : undefined,
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const showMenu = useMemo(() => menu && !editMode, [menu, editMode]);

  return (
    <Grid
      item
      xs={12}
      sm={4}
      md={3}
      lg={3}
      ref={setNodeRef}
      sx={style}
      {...attributes}
      {...listeners}
      {...gridProps}
    >
      <TileContext.Provider value={{ menuOpen, closeMenu: handleClose }}>
        <Paper
          className={className}
          sx={{
            p: 2,
            pt: showMenu || header ? 5 : 2,
            height: "100%",
            position: "relative",
            cursor: onClick ? "pointer" : "default",
            display: "flex",
            flexFlow: "column",
            "&:hover": {
              backgroundColor: onClick ? "rgba(154, 115, 72, 0.3)" : "white", // Beige but with alpha
            },
          }}
          onClick={() => {
            if (!isDragging) onClick?.();
          }}
        >
          <TileIconBar direction="row" columnGap={1}>
            {icon}
            {showMenu && (
              <>
                <IconButton
                  aria-label="more"
                  id={`menu-button-${id}`}
                  aria-controls="menu"
                  aria-expanded={menuOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreHoriz />
                </IconButton>
                <TileMenu
                  id={`menu-${id}`}
                  MenuListProps={{
                    "aria-labelledby": `menu-button-${id}`,
                  }}
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                  disableScrollLock={true}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                  }}
                >
                  {menu}
                </TileMenu>
              </>
            )}
          </TileIconBar>
          {editMode && <DragHandleIcon />}
          {header}
          {title && (
            <Typography variant="h2" sx={{ wordWrap: "break-word" }}>
              {title}
            </Typography>
          )}
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          {children}
          {editMode && !isDragging && <TileDrag />}
        </Paper>
      </TileContext.Provider>
    </Grid>
  );
};

export default Tile;

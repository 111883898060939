import { Notifications } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Card,
  ClickAwayListener,
  Stack,
  styled,
} from "@mui/material";
import {
  useGetNotificationsQuery,
  useMarkNotificationsReadMutation,
} from "../state/services/api";
import { motion } from "framer-motion";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useCallback, useState } from "react";
import { useAlert } from "../components/alerts/AlertContext";
import { useTranslation } from "react-i18next";
import { useKey } from "react-use";

const NotificationIcon = styled(Notifications, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>`
  cursor: pointer;
  transition: color 0.2s ease;

  ${({ theme, isOpen }) => isOpen && `color: ${theme.palette.primary.main};`}

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasUnread",
})<{ hasUnread: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(20%, -20%);
    border-radius: 9999px;
    width: 0.6rem;
    height: 0.6rem;
    background: ${({ theme }) => theme.palette.error.main};
    transition: opacity 0.3s ease;
    opacity: ${(props) => (props.hasUnread ? "1" : "0")};
  }
`;

const NotificationCard = styled(motion(Card))`
  padding: 1rem 1.5rem;
  flex-shrink: 0;
`;

const NotificationStack = styled(motion(Stack))`
  position: fixed;
  left: 50%;
  top: 5rem;
  transform: translateX(-50%);
  width: max-content;
  max-width: min(calc(100vw - 1rem), 25rem);
  opacity: 0;
  overflow-y: auto;
  max-height: calc(100vh - 6rem);
  max-height: calc(100dvh - 6rem);
  padding-bottom: 20px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    top: 100%;
    margin-top: 2rem;
  }
`;

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.2 },
  },
};

const stackVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
    transition: {
      staggerDirection: -1,
    },
  },
};

const UserNotifications = (props: BoxProps) => {
  const { data: notifications } = useGetNotificationsQuery();
  const [markRead] = useMarkNotificationsReadMutation();
  const [isOpen, setOpen] = useState(false);

  const unread = notifications?.filter((notification) => !notification.read);

  const alert = useAlert();
  const { t } = useTranslation();

  const markCurrentAsRead = useCallback(async () => {
    if (!unread?.length) return;
    try {
      await markRead(unread.map(({ id, type }) => ({ id, type }))).unwrap();
    } catch {
      alert({
        severity: "error",
        autoHideDuration: 3000,
        content: t("notifications.markReadFailure"),
      });
    }
  }, [unread, markRead, alert, t]);

  const toggle = useCallback(() => {
    if (!notifications?.length) return
    if (!isOpen) {
      markCurrentAsRead();
    }
    setOpen((open) => !open);
  }, [isOpen, markCurrentAsRead, notifications?.length]);

  useKey("Escape", () => setOpen(false));

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <IconContainer
        hasUnread={!!unread?.length}
        sx={{ display: "flex" }}
        {...props}
      >
        <NotificationIcon onClick={toggle} isOpen={isOpen} />

        <NotificationStack
          variants={stackVariants}
          animate={isOpen ? "open" : "closed"}
          sx={{ pointerEvents: isOpen ? "all" : "none" }}
          transition={{ staggerChildren: 0.1, duration: 0.7 }}
        >
          {notifications?.map((notification) => (
            <NotificationCard key={notification.id} variants={itemVariants}>
              <ReactMarkdown>{notification.text}</ReactMarkdown>
            </NotificationCard>
          ))}
        </NotificationStack>
      </IconContainer>
    </ClickAwayListener>
  );
};

export default UserNotifications;

import { Save } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { TemporaryAlert } from "../../../components/Alert";
import EntityDialog from "../../../components/EntityDialog";
import MultiSelect from "../../../components/form/MultiSelect";
import { useEntityDialog } from "../../../hooks/dialog";
import { selectCurrentUser } from "../../../state/auth";
import { useSelector } from "../../../state/hooks";
import {
  useGetUsersQuery,
  useTransferCoffeeMachineMutation,
} from "../../../state/services/api";
import { Role, Status, User } from "../../../state/users";
import { getAll } from "../../../utils/api";

const TransferMachineSchema = (t: TFunction) =>
  object().shape({
    userId: string().uuid().required(),
  });

export const TRANSFER_MACHINE_MODAL =
  "coffeeMachine.configuration.transferOwnership";
export const TRANSFER_MACHINE_MODAL_PROMPT =
  "coffeeMachine.configuration.transferOwnership.prompt";

const TransferMachineModal = () => {
  const { t } = useTranslation();

  const currentUser = useSelector(selectCurrentUser);

  const { entityId: machineId, close } = useEntityDialog({
    name: TRANSFER_MACHINE_MODAL,
  });
  const { close: closePrompt, open: showPrompt } = useEntityDialog({
    name: TRANSFER_MACHINE_MODAL_PROMPT,
  });

  const [transferMachine, { isLoading: isSaving, isSuccess, isError }] =
    useTransferCoffeeMachineMutation();

  const { data: usersState } = useGetUsersQuery({
    status: Status.Active,
    roles: [Role.Admin, Role.ProductManager, Role.CoffeeMachineOwner],
  });
  const users = getAll(usersState);

  const initialValues: {
    userId?: string;
  } = {
    userId: undefined,
  };

  const submit = useCallback(
    async (values: typeof initialValues) => {
      if (machineId && values.userId) {
        await transferMachine({
          id: machineId,
          userId: values.userId,
        }).unwrap();
        closePrompt();
        close();
      }
    },
    [machineId, transferMachine, close, closePrompt]
  );

  const getUserLabel = (user?: User) => {
    if (!user) return "";
    return user.firstName ? `${user.firstName} ${user.lastName}` : user.email;
  };

  const usersOptions = users
    .filter((user) => user.id !== currentUser?.id)
    .map((user) => ({
      label: getUserLabel(user),
      value: user.id,
    }));
    
  const noOptionsOption = {
    label: t('coffeeMachine.transferMachineModal.noOption'),
    value: "__default",
    disabled: true
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={TransferMachineSchema(t)}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, validateForm, values, isValid }) => (
          <>
            <EntityDialog
              name={TRANSFER_MACHINE_MODAL_PROMPT}
              title={t("coffeeMachine.transferMachineModal.promptTitle")}
              subTitle={t("coffeeMachine.transferMachineModal.promptSubTitle", {
                user: getUserLabel(
                  users.find((user) => user.id === values.userId)
                ),
              })}
              okButton={
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  onClick={() => handleSubmit()}
                >
                  {isSaving ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    t("save")
                  )}
                </Button>
              }
            >
              <Box></Box>
            </EntityDialog>
            <EntityDialog
              name={TRANSFER_MACHINE_MODAL}
              title={t("coffeeMachine.transferMachineModal.title")}
              subTitle={t("coffeeMachine.transferMachineModal.subTitle")}
              okButton={
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  onClick={() => {
                    validateForm(values);
                    if (isValid) {
                      showPrompt();
                    }
                  }}
                >
                  {isSaving ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    t("save")
                  )}
                </Button>
              }
            >
              <Form>
                <MultiSelect
                  name="userId"
                  options={usersOptions.length ? usersOptions : [noOptionsOption]}
                  title={t("coffeeMachine.transferMachineModal.selectUser")}
                />
              </Form>
            </EntityDialog>
          </>
        )}
      </Formik>

      <TemporaryAlert open={isError} severity="error">
        {t("coffeeMachine.transferMachineModal.saveFailure")}
      </TemporaryAlert>
      <TemporaryAlert open={isSuccess}>
        {t("coffeeMachine.transferMachineModal.saveSuccess")}
      </TemporaryAlert>
    </>
  );
};

export default TransferMachineModal;

import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  selectWidget,
  useCreateWidgetMutation,
  useEditWidgetMutation,
  useGetCoffeeMachinesQuery,
} from "../../state/services/api";

import { TemporaryAlert } from "../Alert";

import EntityDialog from "../EntityDialog";
import TextInput from "../form/TextInput";
import {
  Metric,
  MetricPeriod,
  ValueInfo,
  Widget,
} from "../../state/widgets/widgetTypes";
import CoffeeMachineTreeSelect from "../CoffeeMachineTreeSelect";
import { useEntityDialog } from "../../hooks/dialog";

import { Option } from "../../utils/types";
import { Product, ProductCategory } from "../../state/products";
import { getAll } from "../../utils/api";
import { CoffeeMachine } from "../../state/coffe-machines";
import MultiSelect from "../form/MultiSelect";
import { TFunction } from "i18next";

export const EDIT_WIDGET_DIALOG_NAME = "widget.edit";

const WidgetSchema = Yup.object().shape({
  title: Yup.string().required(),
  subTitle: Yup.string(),
  metric: Yup.string().required(),
  metricPeriod: Yup.string().required(),
  valueInfo: Yup.string().required(),
  metricOption: Yup.string().when("metric", (metric: Metric) => {
    if (
      metric === Metric.ProductConsumptionProduct ||
      metric === Metric.ProductConsumptionProductCategory
    ) {
      return Yup.string().required();
    }
    return Yup.string();
  }),
});

const getMetricOptions = (t: TFunction) => {
  return Object.values(Metric).map((k) => {
    return { value: k, label: capitalize(t(`widget.metrics.${k}`)) } as Option;
  });
};

const getMetricPeriodOptions = (t: TFunction) => {
  return Object.values(MetricPeriod).map((k) => {
    return {
      value: k,
      label: capitalize(t(`widget.metricPeriods.${k}`)),
    } as Option;
  });
};

const getCategoryOptions = (t: TFunction) => {
  return Object.values(ProductCategory).map((k) => {
    return {
      value: k,
      label: capitalize(t(`product.category.${k}`)),
    } as Option;
  });
};

const getProducts = (machines: CoffeeMachine[] | undefined) => {
  const allProducts: Product[] = [];

  if (!machines) {
    return allProducts;
  }

  machines?.forEach((machine) => {
    if (machine.coffeeMenu?.products) {
      allProducts.push(...machine.coffeeMenu.products);
    }
  });

  const productsByCode =
    allProducts.reduce((all, product) => {
      all[product.code] = product;
      return all;
    }, {} as Record<string, Product>) ?? {};

  const uniqueCodes = Array.from(new Set(Object.keys(productsByCode)));

  return uniqueCodes.map((id: string) => productsByCode[id]);
};

const WidgetDialog = () => {
  const { t } = useTranslation();

  const { t: mt } = useTranslation("machine");

  const { entity: widget, close } = useEntityDialog({
    name: EDIT_WIDGET_DIALOG_NAME,
    selector: selectWidget,
  });

  const [machineFilter, setMachineFilter] = useState({
    locations: [] as string[] | undefined,
    machines: [] as string[] | undefined,
  });

  const { data: machinesState } = useGetCoffeeMachinesQuery(machineFilter);

  const machines = useMemo(() => {
    if (machinesState) {
      return getAll(machinesState);
    }
    return [];
  }, [machinesState]);

  const products = useMemo(() => getProducts(machines) ?? [], [machines]);

  const getProductCodes = useCallback((): Option[] | undefined => {
    return products?.map(
      (product: Product) =>
        ({ value: product.code, label: mt(product.name) } as Option)
    );
  }, [products, mt]);

  const metricOptions = getMetricOptions(t);

  const metricPeriodOptions = getMetricPeriodOptions(t);

  const [additionalMetricOptions, setAdditionalMetricOptions] = useState(
    [] as Option[]
  );

  const [selectedMetric, setCurrentMetric] = useState("" as Metric);

  const formikRef = useRef<any>();

  const [createWidget, { isSuccess: createSuccess, isError: createError }] =
    useCreateWidgetMutation();

  const [
    editWidget,
    { isSuccess: editSuccess, isLoading: isSavingEdit, isError: editError },
  ] = useEditWidgetMutation();

  const submit = useCallback(
    async (
      values: typeof initialValues,
      { resetForm }: FormikHelpers<typeof initialValues>
    ) => {
      if (values.id) {
        await editWidget(values).unwrap();
      } else {
        await createWidget({
          widget: {
            title: values.title,
            metric: values.metric,
            metricPeriod: values.metricPeriod,
            filter: values.filter,
            valueInfo: values.valueInfo,
            metricOption: values.metricOption,
          },
        }).unwrap();
      }
      close();
      resetForm();
    },
    [editWidget, createWidget, close]
  );

  const handleFilterSelect = (filter: {
    locations: string[] | undefined;
    machines: string[] | undefined;
  }) => {
    formikRef?.current?.setFieldValue("filter", filter);
    setMachineFilter(filter);
  };

  const setAdditionalOptions = useCallback(
    (metric: Metric) => {
      if (metric === Metric.ProductConsumptionProductCategory) {
        setAdditionalMetricOptions(getCategoryOptions(t));
      } else if (metric === Metric.ProductConsumptionProduct) {
        const opts = getProductCodes();

        if (opts) {
          setAdditionalMetricOptions(opts);
        }
      } else {
        setAdditionalMetricOptions([]);
      }
    },
    [setAdditionalMetricOptions, t, getProductCodes]
  );

  const onMetricChange = (value: unknown) => {
    setCurrentMetric(value as Metric);
  };

  const emptySetFilter = () => {
    return {};
  };

  useEffect(() => {
    setCurrentMetric(widget?.metric ?? Metric.ProductConsumption);
  }, [widget, setCurrentMetric]);

  useEffect(() => {
    setAdditionalOptions(selectedMetric);
  }, [selectedMetric, setAdditionalOptions]);

  const initialValues: Widget = widget || {
    id: "",
    title: "",
    subTitle: "",
    metric: Metric.ProductConsumption,
    metricPeriod: MetricPeriod.Daily,
    filter: {
      locations: [],
      machines: [],
    },
    valueInfo: ValueInfo.Absolute,
    metricOption: "",
  };

  const valueInfos = Object.keys(ValueInfo);
  type ValueInfoKey = keyof typeof ValueInfo;

  const gridTemplateColumns = {
    xs: "1fr",
    sm: "1fr 1fr",
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={WidgetSchema}
        enableReinitialize={true}
        onSubmit={submit}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <EntityDialog
            name={EDIT_WIDGET_DIALOG_NAME}
            title={widget?.title || t("widget.widgetDialog.title")}
            subTitle={
              widget
                ? t("widget.widgetDialog.editWidget")
                : t("widget.widgetDialog.subTitle")
            }
            okButton={
              <Button
                variant="contained"
                startIcon={!isSavingEdit && <Save />}
                onClick={() => handleSubmit()}
              >
                {isSavingEdit ? (
                  <CircularProgress size={20} color="white" />
                ) : (
                  t("save")
                )}
              </Button>
            }
          >
            <Box>
              <Form>
                <Grid container item xs={12}>
                  <Grid item xs={12} md={8}>
                    <Box
                      display="grid"
                      sx={{ gridTemplateColumns, gridGap: "10px" }}
                    >
                      <CoffeeMachineTreeSelect
                        selectFilter={emptySetFilter}
                        filterFromProps={values.filter}
                        handleOnChange={handleFilterSelect}
                        receiveFromProps
                        title={capitalize(t("machines")) + ":"}
                      />
                    </Box>
                    <Box
                      display="grid"
                      sx={{ gridTemplateColumns, gridGap: "10px" }}
                    >
                      <TextInput
                        name="title"
                        title={t("widget.widgetDialog.fieldTitle") + ":"}
                      />

                      <TextInput
                        name="subTitle"
                        title={t("widget.widgetDialog.fieldSubtitle") + ":"}
                      />
                    </Box>
                    <Box
                      display="grid"
                      sx={{ gridTemplateColumns, gridGap: "10px" }}
                    >
                      <MultiSelect
                        name="metric"
                        title={t("widget.widgetDialog.metric")}
                        options={metricOptions}
                        onChange={onMetricChange}
                        helperText={t("required", { field: "Metric" })}
                      />

                      <MultiSelect
                        name="metricPeriod"
                        title={t("widget.widgetDialog.metricPeriod")}
                        options={metricPeriodOptions}
                        helperText={t("required", { field: "Metric Period" })}
                      />

                      {!!additionalMetricOptions.length && (
                        <MultiSelect
                          id="metricOption"
                          name="metricOption"
                          title={capitalize(t("widget.metricOptions"))}
                          options={additionalMetricOptions}
                          helperText={t("required", {
                            field: "Metric option",
                          })}
                        />
                      )}
                    </Box>

                    <FormControl component="fieldset" sx={{ mt: 2 }}>
                      <FormLabel component="legend">
                        {t("widget.widgetDialog.valueInfoLabel")}
                      </FormLabel>

                      <RadioGroup
                        aria-label="valueInfo"
                        id="valueInfo"
                        name="valueInfo"
                        onChange={handleChange}
                        value={values.valueInfo}
                      >
                        {valueInfos.map((info, index) => {
                          return (
                            <FormControlLabel
                              value={ValueInfo[info as ValueInfoKey]}
                              control={<Radio />}
                              label={t(`widget.type.${ValueInfo[info as ValueInfoKey]}`)}
                              key={index}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            </Box>
          </EntityDialog>
        )}
      </Formik>

      <TemporaryAlert open={editSuccess}>
        {t("widget.edit.saveSuccess")}
      </TemporaryAlert>

      <TemporaryAlert open={editError} severity={"error"}>
        {t("widget.edit.saveFailure")}
      </TemporaryAlert>

      <TemporaryAlert open={createSuccess}>
        {t("widget.create.success")}
      </TemporaryAlert>

      <TemporaryAlert open={createError} severity={"error"}>
        {t("widget.create.failure")}
      </TemporaryAlert>
    </>
  );
};

export default WidgetDialog;

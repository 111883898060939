import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TemporaryAlert } from "../components/Alert";
import {
  selectCompanies,
  selectCompany,
  selectCurrentUser,
  selectIsLoggedIn,
} from "../state/auth";
import { useSelector } from "../state/hooks";
import { useExchangeTokenMutation } from "../state/services/api";
import { hasNecessaryRole, Role } from "../state/users";
import { isFetchBaseQueryErrorWithData } from "../utils/api";
import { parse } from "qs";
import keycloak from "../keycloak";

export const useIsAuthenticated = () => {
  return !!useSelector(selectIsLoggedIn);
};

export const useAuthorized = (roles: Role[] | Role | undefined): boolean => {
  const user = useSelector(selectCurrentUser);
  const company = useSelector(selectCompany);
  const companies = useSelector(selectCompanies);
  return useMemo(() => {
    if (roles === Role.CompanyOwner && company) {
      return company.ownerId === user?.id;
    }
    if (Array.isArray(roles) && roles.indexOf(Role.CompanyOwner) !== -1) {
      return company !== undefined && company?.ownerId === user?.id;
    }
    return roles
      ? !!company && !!companies && hasNecessaryRole(roles, company, companies)
      : true;
  }, [roles, user, company, companies]);
};

export const useLogin = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  const [exchangeToken, { error, isLoading }] = useExchangeTokenMutation();

  const params = useMemo(
    () => parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const login = useCallback(async () => {
    if (!keycloak.token) return;
    if (isAuthenticated) return;
    try {
      return exchangeToken({ token: keycloak.token, language: i18n.language }).unwrap();
    } catch (e: unknown) {
      if (isFetchBaseQueryErrorWithData<{ error: string }>(e)) {
        await keycloak.logout({
          redirectUri: `${window.location.origin}/login?failure=${e.data.error}`,
        });
      }
      throw e;
    }
  }, [exchangeToken, i18n.language, isAuthenticated]);

  const errorMessage = useMemo(() => {
    let errorMsg = params.failure;
    if (error && isFetchBaseQueryErrorWithData<{ error: string }>(error)) {
      errorMsg = error.data.error;
    }

    if (!errorMsg) return;

    if (errorMsg === "auth.login.userInactive") {
      return t("auth.login.userInactive");
    } else if (errorMsg === "auth.login.userNeedsAdminVerification") {
      return t("auth.login.userNeedsAdminVerification");
    } else {
      return t("auth.login.failure");
    }
  }, [error, params.failure, t]);

  const errorAlert = useMemo(
    () => (
      <TemporaryAlert open={!isLoading && !!errorMessage} severity="error">
        {errorMessage}
      </TemporaryAlert>
    ),
    [errorMessage, isLoading]
  );

  return [login, errorAlert] as [typeof login, typeof errorAlert];
};

import { Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import TextInput from "../../components/form/TextInput";
import MultiSelect from "../../components/form/MultiSelect";
import File from "../../components/form/File";
import { useSendFeedbackFormMutation } from "../../state/services/api";
import { useAlert } from "../../components/alerts/AlertContext";

enum Topic {
  Feedback = "feedback",
  Support = "support",
  Bugreport = "bugreport",
}

const FeedbackSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().required(
      t("required", { field: t("help.feedback.fields.name") })
    ),
    email: Yup.string()
      .email()
      .required(t("required", { field: t("help.feedback.fields.email") })),
    topic: Yup.string().required(
      t("required", { field: t("help.feedback.fields.topic") })
    ),
    device: Yup.string().when("topic", (topic, schema) => {
      return [Topic.Support, Topic.Bugreport].includes(topic)
        ? schema.required(
            t("required", { field: "help.feedback.fields.device" })
          )
        : schema;
    }),
    os: Yup.string().when("topic", (topic, schema) => {
      return [Topic.Support, Topic.Bugreport].includes(topic)
        ? schema.required(t("required", { field: "help.feedback.fields.os" }))
        : schema;
    }),
    details: Yup.string().when("topic", (topic, schema) => {
      return [Topic.Support, Topic.Bugreport].includes(topic)
        ? schema.required(
            t("required", { field: "help.feedback.fields.details" })
          )
        : schema;
    }),
    comment: Yup.string(),
  });

const FeedbackForm = () => {
  const { t } = useTranslation();

  const initialValues = {
    name: "",
    email: "",
    topic: Topic.Feedback,
    device: "",
    os: "",
    details: "",
    comment: "",
    files: [],
  };

  const topicOptions = [
    {
      label: t("help.feedback.topic.feedback"),
      value: Topic.Feedback,
    },
    {
      label: t("help.feedback.topic.support"),
      value: Topic.Support,
    },
    {
      label: t("help.feedback.topic.bugreport"),
      value: Topic.Bugreport,
    },
  ];

  const [sendFeedbackForm, { isLoading }] = useSendFeedbackFormMutation();

  const alert = useAlert();

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
      try {
        await sendFeedbackForm(values).unwrap();
        formikHelpers.resetForm();
        alert({
          severity: "success",
          content: t("help.feedback.success"),
          autoHideDuration: 3000,
        });
      } catch (e) {
        alert({
          severity: "error",
          content: t("help.feedback.error"),
          autoHideDuration: 3000,
        });
      }
    },
    [alert, sendFeedbackForm, t]
  );

  return (
    <Grid item xs={12}>
      <Paper>
        <Stack p={2}>
          <Typography variant="h1" mb={2}>
            {t("help.feedback.title")}
          </Typography>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={FeedbackSchema(t)}
            enableReinitialize={true}
          >
            {({ values, resetForm, setFieldValue }) => (
              <Form>
                <TextInput
                  key="name"
                  name="name"
                  title={t("help.feedback.fields.name")}
                />
                <TextInput
                  key="email"
                  name="email"
                  type="email"
                  title={t("help.feedback.fields.email")}
                />
                <MultiSelect
                  options={topicOptions}
                  key="topic"
                  name="topic"
                  title={t("help.feedback.fields.topic")}
                />
                {(values.topic === Topic.Bugreport ||
                  values.topic === Topic.Support) && (
                  <>
                    <TextInput
                      key="device"
                      name="device"
                      title={t("help.feedback.fields.device")}
                    />
                    <TextInput
                      key="os"
                      name="os"
                      title={t("help.feedback.fields.os")}
                    />
                    <TextInput
                      key="details"
                      name="details"
                      title={t("help.feedback.fields.details")}
                      multiline
                      rows={5}
                    />
                  </>
                )}
                <TextInput
                  key="comment"
                  name="comment"
                  title={t("help.feedback.fields.comment")}
                  multiline
                  rows={5}
                />

                <File
                  title={t("help.feedback.fields.images")}
                  key="files"
                  name="files"
                  multiple
                />

                <Stack direction="row" justifyContent="flex-end" mt={2}>
                  <Button
                    type="button"
                    sx={{ width: "auto" }}
                    onClick={() => resetForm()}
                    disabled={isLoading}
                  >
                    {t("help.feedback.cancelButtonText")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "auto", ml: 2 }}
                    disabled={isLoading}
                  >
                    {t("help.feedback.submitButtonText")}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default FeedbackForm;

import { useCallback } from "react";
import ReactDOM from "react-dom";
import Export from "../Export";
import { base64toBlob } from "../utils/base64";
import { useTranslation } from "react-i18next";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { omit } from "lodash";

export enum ExportType {
  PDF = "pdf",
  CSV = "csv",
  XLSX = "xlsx",
}

export const fileEndings = {
  [ExportType.CSV]: "csv",
  [ExportType.PDF]: "pdf",
  [ExportType.XLSX]: "xlsx",
};

export const mimeTypes = {
  [ExportType.CSV]: "text/csv",
  [ExportType.PDF]: "application/pdf",
  [ExportType.XLSX]:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export function getMimeType(type: ExportType) {
  return mimeTypes[type];
}

export function getFileEnding(type: ExportType) {
  return fileEndings[type];
}

export function extractCSS() {
  return [...document.styleSheets]
    .map((sheet) => {
      try {
        const text = [...sheet.cssRules]
          .map((rule) => `${rule.cssText}\n`)
          .join("\n");
        return `<style type="${sheet.type}">${text}</style>`;
      } catch {
        return "";
      }
    })
    .join("\n");
}

export function renderPdfToString<P = {}>(
  Component: string | ((props: any) => JSX.Element),
  componentProps?: P
) {
  const exportNode = document.createElement("div");
  exportNode.id = "export-root";

  const originalBody = document.body;
  originalBody.appendChild(exportNode);

  return new Promise<string>((resolve, reject) => {
    try {
      ReactDOM.render(
        <Export>
          <Component {...componentProps} />
        </Export>,
        exportNode,
        () => {
          // Fake setImmediate
          setTimeout(async () => {
            const rootNode = document.documentElement.cloneNode(
              true
            ) as HTMLHtmlElement;
            const body = rootNode.querySelector("body");

            const contentHtml = exportNode.outerHTML;
            originalBody.removeChild(exportNode);
            body!.innerHTML = contentHtml;
            const html = rootNode.querySelector("body")!.innerHTML;
            const head = extractCSS();

            const docHtml = `
                <html>
                  <head>
                    ${head}
                  </head>
                  <body>
                    ${html}
                  </body>
                </html>
              `;

            resolve(docHtml);
          });
        }
      );
    } catch {
      reject();
    }
  });
}

export function useBlobExport(
  exportFn: () => Promise<string>,
  type: ExportType,
  fileName: string
) {
  return useCallback(async () => {
    const csvBlob = await exportFn();
    const base64 = base64toBlob(csvBlob, getMimeType(type));
    const fileUrl = URL.createObjectURL(base64);

    // We have to be stupid about the downloading
    // because otherwise safari would just open the csv in a new tab
    const el = document.createElement("a");
    el.href = fileUrl;
    el.target = "_blank";
    el.setAttribute(
      "download",
      `${fileName ?? "export"}_${new Date().toISOString()}.${getFileEnding(
        type
      )}`
    );
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }, [exportFn, fileName, type]);
}

export type ExportData<Filter, ComponentProps> = {
  Component?: string | ((props: ComponentProps) => JSX.Element);
  componentProps?: ComponentProps;
} & Filter;

export function useEntityExport<Filter, ComponentProps>(
  type: ExportType,
  exportData: ExportData<Filter, ComponentProps>,
  exportFn: MutationTrigger<MutationDefinition<any, any, any, string>>, //  <T>(exportPayload: T) => Promise<unknown>
  fileName: string
) {
  const { i18n } = useTranslation();

  return useBlobExport(
    async () => {
      let html: string | undefined;
      if (type === ExportType.PDF) {
        if (!exportData.Component) {
          throw new Error("Missing component for pdf export");
        }
        html = await renderPdfToString(
          exportData.Component,
          exportData.componentProps
        );
      }

      return exportFn({
        type,
        html,
        ...omit(exportData, ["Component", "componentProps"]),
        language: i18n.language,
      }).unwrap();
    },
    type,
    fileName
  );
}

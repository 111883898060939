import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch, AppThunk } from "../store";
import { api, setLogoutAction } from "../services/api";
import { AuthState, COMPANY_ID, getSelectedCompanyId, TOKEN_KEY } from "./core";
import keycloak from "../../keycloak";

export * from "./core";

const slice = createSlice({
  name: "auth",
  initialState: {
    logoutDialogOpen: false,
    token: localStorage.getItem(TOKEN_KEY),
    selectedCompanyId: localStorage.getItem(COMPANY_ID),
  } as AuthState,
  reducers: {
    logout: (state, action: PayloadAction<void>) => {
      localStorage.removeItem(TOKEN_KEY);
      return {
        ...state,
        user: undefined,
        token: undefined,
        company: undefined,
        companies: undefined,
        role: undefined,
        logoutDialogOpen: false,
      };
    },
    setLogoutDialogOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        logoutDialogOpen: action.payload,
      };
    },
    setCompany: (state, action: PayloadAction<string>) => {
      const selectedCompanyId = action.payload;
      localStorage.setItem(COMPANY_ID, selectedCompanyId);
      return {
        ...state,
        selectedCompanyId,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.me.matchRejected, (state, action) => {
      if (action.meta.condition) return state;
      localStorage.removeItem(TOKEN_KEY);
      return {
        ...state,
        user: undefined,
        token: undefined,
        company: undefined,
        role: undefined,
        companies: [],
      };
    });
    builder.addMatcher(
      isAnyOf(
        api.endpoints.login.matchFulfilled,
        api.endpoints.exchangeToken.matchFulfilled
      ),
      (state, { payload }) => {
        const selectedCompanyId = getSelectedCompanyId(
          state.selectedCompanyId,
          payload.companies ?? []
        );
        localStorage.setItem(TOKEN_KEY, payload.token);
        localStorage.setItem(COMPANY_ID, selectedCompanyId);
        return {
          ...state,
          user: payload.user,
          token: payload.token,
          companies: payload.companies,
          selectedCompanyId,
        };
      }
    );
    builder.addMatcher(
      api.endpoints.me.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        user: payload,
      })
    );
    builder.addMatcher(api.endpoints.me.matchRejected, (state, _) => ({
      ...state,
      user: undefined,
    }));
    builder.addMatcher(
      api.endpoints.getCompanies.matchFulfilled,
      (state, { payload }) => {
        const selectedCompanyId = getSelectedCompanyId(
          state.selectedCompanyId,
          payload
        );
        localStorage.setItem(COMPANY_ID, selectedCompanyId);
        return {
          ...state,
          companies: payload,
          selectedCompanyId,
        };
      }
    );
  },
});

export default slice.reducer;

export const setCompany = (companyId: string) => (dispatch: AppDispatch) => {
  dispatch(slice.actions.setCompany(companyId));
  dispatch(
    api.util.invalidateTags([
      "Me",
      "Location",
      "User",
      "Company",
      "Payment",
      "CoffeeMachine",
      "AvailableProduct",
      "Product",
      "Currency",
      "Language",
      "Widget",
      "Statistic",
      "CoffeeMenu",
      "Model",
      "UserGroup",
      "UserConsumption",
      "CreditTopUp",
      "Notifications",
    ])
  );
};

export const exchangeToken =
  (token: string, language: string) => async (dispatch: AppDispatch) => {
    return dispatch(
      api.endpoints.exchangeToken.initiate({ token, language }, {})
    ).unwrap();
  };

export const logout = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.logout());
  if (keycloak.token) {
    keycloak.logout();
  }
};

setLogoutAction(logout);

export const { setLogoutDialogOpen } = slice.actions;

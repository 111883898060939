import {
  Box,
  FormControl,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";

interface NumberRangeFilterProps {
  name: string;
  label: string;
  from?: number;
  to?: number;
  setFrom: (input?: number) => void;
  setTo: (input?: number) => void;
}

const Field = styled(TextField)`
  .MuiInputBase-root {
    margin-top: 0;
  }
`;

function NumberRangeFilter({
  name,
  label,
  from,
  to,
  setFrom,
  setTo,
}: NumberRangeFilterProps) {
  return (
    <Stack>
      <Typography variant="h4">{label}</Typography>
      <FormControl margin="normal" fullWidth>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Field
            type="number"
            name={`${name}-from`}
            value={from}
            onChange={(e) =>
              setFrom(
                e.target.value === "" ? undefined : parseInt(e.target.value)
              )
            }
          />
          <Box px={1}>→</Box>
          <Field
            type="number"
            name={`${name}-to`}
            value={to}
            onChange={(e) =>
              setTo(
                e.target.value === "" ? undefined : parseInt(e.target.value)
              )
            }
          />
        </Stack>
      </FormControl>
    </Stack>
  );
}

export default NumberRangeFilter;
